import { useAuthenticator } from '@aws-amplify/ui-react'
import { useContext, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { FilterTypes } from '../../graphQLTypes'
import { ReactComponent as LocationIcon } from '../../icons/deselected/icons-crowdenergy-191.svg'
import { ReactComponent as DashboardIcon } from '../../icons/deselected/icons-crowdenergy-200.svg'
import { ReactComponent as SettingsIcon } from '../../icons/deselected/icons-crowdenergy-202.svg'
import { ReactComponent as BellIcon } from '../../icons/deselected/icons-crowdenergy-203.svg'
import { ReactComponent as DashboardIconDisabled } from '../../icons/icons-crowdenergy-200-200.svg'
import { ReactComponent as LogoIcon } from '../../icons/logo-crowdenergy-s.svg'
import { ReactComponent as LocationIconSelected } from '../../icons/selected/icons-crowdenergy-191-selected.svg'
import { ReactComponent as DashboardIconSelected } from '../../icons/selected/icons-crowdenergy-200-selected.svg'
import { ReactComponent as SettingsIconSelected } from '../../icons/selected/icons-crowdenergy-202-selected.svg'
import { isIOS } from '../../isIOS'
import { UserStateContext } from '../../store/userContext'
import { Dictionary } from '../../types'
import { isAuthenticated } from '../../utils/auth'
import { displayIOSInstallMessage, zipWithObject } from '../../utils/common'
import { getTopMenuIcons } from '../../utils/icons'
import { IOSPopupContent } from './IOSPopupContent'
import { Icon } from './Icon'
import Map from './Map'
import TopMenu from './TopMenu'
import { ActivableMenuIcons, ActivableTopMenuIcons, FilterMenuIcons, TopMenuIcons } from './icons'

interface ServicesDisplayed {
  demand: boolean
  favourites: boolean
  blueSockets: boolean
  greenSockets: boolean
  parking: boolean
  charging: boolean
}

function areSettingsDisplayed(): boolean {
  return window.location.pathname.startsWith('/settings')
}

export function MapView() {
  const navigate = useNavigate()
  const userContext = useContext(UserStateContext)
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  const [servicesDisplayed, setServicesDisplayed] = useState<ServicesDisplayed>({
    favourites: true,
    demand: true,
    greenSockets: true,
    blueSockets: true,
    parking: true,
    charging: true,
  })

  useEffect(() => {
    if (isIOS()) displayIOSInstallMessage(<IOSPopupContent />)
  }, [])

  const [activeTopMenuIconName, setActiveTopMenuIconName] = useState<ActivableTopMenuIcons>()

  const changeActiveTopMenuIcon = (iconName?: ActivableTopMenuIcons) => {
    setActiveTopMenuIconName((prevState: ActivableMenuIcons | undefined) =>
      prevState !== undefined && prevState === iconName ? undefined : iconName
    )
  }

  const generateServiceDisplayStatusAndSetter = (): Dictionary<FilterMenuIcons, [boolean, Function]> => {
    const filterIconNames = Object.values(FilterMenuIcons)
    const serviceDisplayStatusAndSetters = Object.keys(servicesDisplayed).map((k) => {
      const key = k as unknown as keyof ServicesDisplayed
      return [
        servicesDisplayed[key],
        () => {
          setServicesDisplayed((prevState: ServicesDisplayed) => ({ ...prevState, [key]: !prevState[key] }))
        },
      ]
    })
    return zipWithObject(filterIconNames, serviceDisplayStatusAndSetters)
  }

  return (
    <div>
      <TopMenu
        serviceFilters={generateServiceDisplayStatusAndSetter()}
        changeActiveTopMenuIcon={changeActiveTopMenuIcon}
        activeTopMenuIcon={activeTopMenuIconName}
        icons={getTopMenuIcons(areSettingsDisplayed(), isAuthenticated(authStatus))}
        onClickHandlers={{
          [TopMenuIcons.UserLocationIcon]: () => userContext.refreshUserLocation(),
        }}
      />

      <Outlet />

      <Map
        serviceFilters={{
          [FilterTypes.Demand]: servicesDisplayed.demand,
          [FilterTypes.Favourites]: servicesDisplayed.favourites,
          [FilterTypes.GreenSockets]: servicesDisplayed.greenSockets,
          [FilterTypes.BlueSockets]: servicesDisplayed.blueSockets,
          [FilterTypes.Parking]: servicesDisplayed.parking,
          [FilterTypes.Charging]: servicesDisplayed.charging,
        }}
      />

      <div className={`menu menu--horizontal menu--bottom`}>
        <Icon
          key="1"
          className="none"
          selectedIcon={LocationIconSelected}
          deselectedIcon={LocationIcon}
          isSelected={window.location.pathname === '/'}
          onClickHandlers={[
            () => {
              navigate('/')
            },
          ]}
        />
        <Icon
          key="2"
          className="none"
          selectedIcon={DashboardIconSelected}
          deselectedIcon={userContext.state.showActiveSessions ? DashboardIcon : DashboardIconDisabled}
          isSelected={window.location.pathname.startsWith('/sessions')}
          onClickHandlers={
            isAuthenticated(authStatus)
              ? [() => (window.location.pathname.startsWith('/sessions') ? navigate('/') : navigate('/sessions'))]
              : []
          }
        />
        <Icon key="3" className="none" selectedIcon={LogoIcon} deselectedIcon={LogoIcon} isSelected={false} />
        <Icon key="4" className="none" selectedIcon={BellIcon} deselectedIcon={BellIcon} isSelected={false} />
        <Icon
          key="5"
          className="none"
          selectedIcon={SettingsIconSelected}
          deselectedIcon={SettingsIcon}
          isSelected={areSettingsDisplayed()}
          onClickHandlers={[() => (areSettingsDisplayed() ? navigate('/') : navigate('/settings'))]}
        />
      </div>
    </div>
  )
}

export default MapView
