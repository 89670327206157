export enum MenuSections {
  NotDevelopedYet = '',
  Root = 'Settings',
  Balances = 'Settings/Balances',
  BuyBalanceCard = 'Settings/Balances/BuyBalanceCard',
  SetServiceDemand = 'Settings/Balances/SetServiceDemand',
  MyServices = 'Settings/MyServices',
  AddService = 'Settings/MyServices/AddService',
  ModifyService = 'Settings/MyServices/ModifyService',
  About = 'Settings/About',
  Profile = 'Settings/Profile',
}
