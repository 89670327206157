import { BalanceCardData } from '../types'
import { ReactComponent as USDBalanceCardIconSelected } from '../icons/gift-card-icons/icons-crowdenergy-296.svg'
import { ReactComponent as USDBalanceCardIcon } from '../icons/gift-card-icons/icons-crowdenergy-291.svg'
import { ReactComponent as USDBalanceCardIconDisabled } from '../icons/gift-card-icons/icons-crowdenergy-301.svg'
import { ReactComponent as EURBalanceCardIconSelected } from '../icons/gift-card-icons/icons-crowdenergy-293.svg'
import { ReactComponent as EURBalanceCardIcon } from '../icons/gift-card-icons/icons-crowdenergy-288.svg'
import { ReactComponent as EURBalanceCardDisabled } from '../icons/gift-card-icons/icons-crowdenergy-298.svg'
import { ReactComponent as BitcoinBalanceCardIconSelected } from '../icons/gift-card-icons/icons-crowdenergy-295.svg'
import { ReactComponent as BitcoinBalanceCardIcon } from '../icons/gift-card-icons/icons-crowdenergy-290.svg'
import { ReactComponent as BitcoinBalanceCardDisabled } from '../icons/gift-card-icons/icons-crowdenergy-300.svg'
import { ReactComponent as GBPIconBalanceCardSelected } from '../icons/gift-card-icons/icons-crowdenergy-294.svg'
import { ReactComponent as GBPBalanceCardIcon } from '../icons/gift-card-icons/icons-crowdenergy-289.svg'
import { ReactComponent as GBPBalanceCardDisabled } from '../icons/gift-card-icons/icons-crowdenergy-299.svg'
import { CryptoCurrency, NonCryptoCurrency } from './currencyTypes'

export enum BalanceCardIcons {
  USDBalanceCardIcon = 'usdBalanceCardIcon',
  EURBalanceCardIcon = 'eurBalanceCardIcon',
  BitcoinBalanceCardIcon = 'bitcoinBalanceCardIcon',
  GBPBalanceCardIcon = 'gbpBalanceCardIcon',
}

export const balanceCardIcons = {
  [BalanceCardIcons.USDBalanceCardIcon]: {
    selected: USDBalanceCardIconSelected,
    deselected: USDBalanceCardIcon,
    disabled: USDBalanceCardIconDisabled,
  },
  [BalanceCardIcons.EURBalanceCardIcon]: {
    selected: EURBalanceCardIconSelected,
    deselected: EURBalanceCardIcon,
    disabled: EURBalanceCardDisabled,
  },
  [BalanceCardIcons.BitcoinBalanceCardIcon]: {
    selected: BitcoinBalanceCardIconSelected,
    deselected: BitcoinBalanceCardIcon,
    disabled: BitcoinBalanceCardDisabled,
  },
  [BalanceCardIcons.GBPBalanceCardIcon]: {
    selected: GBPIconBalanceCardSelected,
    deselected: GBPBalanceCardIcon,
    disabled: GBPBalanceCardDisabled,
  },
}

export const balanceCardData: BalanceCardData = {
  [CryptoCurrency.BTC]: {
    label: CryptoCurrency.BTC,
    displayName: 'Bitcoin',
    iconSelected: balanceCardIcons[BalanceCardIcons.BitcoinBalanceCardIcon].selected,
    iconDeselected: balanceCardIcons[BalanceCardIcons.BitcoinBalanceCardIcon].deselected,
    iconDisabled: balanceCardIcons[BalanceCardIcons.BitcoinBalanceCardIcon].disabled,
  },
  [NonCryptoCurrency.EUR]: {
    label: NonCryptoCurrency.EUR,
    displayName: 'EUR',
    iconSelected: balanceCardIcons[BalanceCardIcons.EURBalanceCardIcon].selected,
    iconDeselected: balanceCardIcons[BalanceCardIcons.EURBalanceCardIcon].deselected,
    iconDisabled: balanceCardIcons[BalanceCardIcons.EURBalanceCardIcon].disabled,
  },
  [NonCryptoCurrency.GBP]: {
    label: NonCryptoCurrency.GBP,
    displayName: 'GBP',
    iconSelected: balanceCardIcons[BalanceCardIcons.GBPBalanceCardIcon].selected,
    iconDeselected: balanceCardIcons[BalanceCardIcons.GBPBalanceCardIcon].deselected,
    iconDisabled: balanceCardIcons[BalanceCardIcons.GBPBalanceCardIcon].disabled,
  },
  [NonCryptoCurrency.USD]: {
    label: NonCryptoCurrency.USD,
    displayName: 'USD',
    iconSelected: balanceCardIcons[BalanceCardIcons.USDBalanceCardIcon].selected,
    iconDeselected: balanceCardIcons[BalanceCardIcons.USDBalanceCardIcon].deselected,
    iconDisabled: balanceCardIcons[BalanceCardIcons.USDBalanceCardIcon].disabled,
  },
}
