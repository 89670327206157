import { SVGIcon } from './components/Map/icons'
import { TotalAmount } from './data/bitcoinCheckoutsData'
import { CryptoCurrency, NonCryptoCurrency } from './data/currencyTypes'
import { AcceptedCurrency, POIAccess, POILocationType, POIType, ServiceType } from './graphQLTypes'

export type Dictionary<K extends string, T> = { [P in K]?: T }

export type CurrencyEntry = [string, number | undefined]

export type Currency = CryptoCurrency | NonCryptoCurrency | AcceptedCurrency
export const AllCurrency = { ...CryptoCurrency, ...NonCryptoCurrency }

export type CurrencyData = Dictionary<
  Currency,
  {
    label: string
    longName: string
    selectedIcon: SVGIcon
    deselectedIcon: SVGIcon
  }
>

export type ServiceTypeData = Dictionary<
  ServiceType,
  {
    label: ServiceType
    displayName: string
    iconSelected: SVGIcon
    iconDeselected: SVGIcon
    hasPricePerKW: boolean
    energySources?: any
    energySourcesMessageIdPostfix?: string
    connectors?: any
  }
>

export type LocationTypeData = Dictionary<
  POILocationType,
  {
    label: POILocationType
    displayName: string
    enabledIcon: SVGIcon
    disabledIcon: SVGIcon
  }
>

export type AccecssTypeData = Dictionary<
  POIAccess,
  {
    label: POIAccess
    displayName: string
    enabledIcon: SVGIcon
    disabledIcon: SVGIcon
  }
>

export type BusinessTypeData = Dictionary<
  POIType,
  {
    label: POIType
    displayName: string
    enabledIcon: SVGIcon
    disabledIcon: SVGIcon
  }
>

export type BalanceCardData = Dictionary<
  Currency,
  {
    label: Currency
    displayName: string
    iconSelected: SVGIcon
    iconDeselected: SVGIcon
    iconDisabled: SVGIcon
  }
>

export type BitcoinCheckoutIds = Dictionary<TotalAmount, string>

export enum CartType {
  EmptyCart = 'emptyCard',
  FullCart = 'fullCart',
}

export enum HistoryCoordinates {
  LAT = 'LAT',
  LNG = 'LNG',
}

export type CartData = Dictionary<
  CartType,
  {
    label: string
    iconDeselected: SVGIcon
  }
>

export type MenuItemsData = Dictionary<string, Dictionary<string, any>>

export interface MenuItemConfig {
  isNavigable?: boolean
  isDisabled?: boolean
  isSubItem?: boolean
  isVisible?: boolean
}

export interface MenuSelectableConfig {
  isVisible?: boolean
  isCentered?: boolean
  hasBiggerTopMargin?: boolean
  isSubItem?: boolean
  hasNoBottomMargin?: boolean
}

export interface MenuSelectableIconConfig {
  isSelectable?: boolean
  isVisible?: boolean
  isEnabled?: boolean
  hasFixedWidth?: boolean
}

export enum SmartSocketEnergySource {
  Renewable = 'renewable',
  Grid = 'grid',
}

export enum EVChargerEnergySource {
  Car = 'car',
  Bicycle = 'bicycle',
  Scooter = 'scooter',
}

export type EnergySource = SmartSocketEnergySource | EVChargerEnergySource

export type EnergySourceData = Dictionary<
  EnergySource,
  {
    label: string
    displayName: string
    iconDeselected: SVGIcon
    iconSelected: SVGIcon
  }
>

export enum ConnectorOutletSmartSocket {
  SmartSocketConnectorTypeA = 'TYPE_A',
  SmartSocketConnectorTypeB = 'TYPE_B',
  SmartSocketConnectorTypeC = 'TYPE_C',
  SmartSocketConnectorTypeD = 'TYPE_D',
  SmartSocketConnectorTypeE = 'TYPE_E',
  SmartSocketConnectorTypeF = 'TYPE_F',
  SmartSocketConnectorTypeG = 'TYPE_G',
  SmartSocketConnectorTypeH = 'TYPE_H',
  SmartSocketConnectorTypeI = 'TYPE_I',
  SmartSocketConnectorTypeJ = 'TYPE_J',
  SmartSocketConnectorTypeK = 'TYPE_K',
  SmartSocketConnectorTypeL = 'TYPE_L',
  SmartSocketConnectorCaravan1Phase = 'TYPE_M',
  SmartSocketConnector3Phase16A = 'TYPE_N',
  SmartSocketConnector3Phase32A = 'TYPE_O',
}

export enum ConnectorOutletEVCharger {
  MennekesType2 = 'TYPE_A',
  J1772Type1 = 'TYPE_B',
  CCSCombo1US = 'TYPE_C',
  CCSCombo2EU = 'TYPE_D',
  CHAdeMO = 'TYPE_E',
  TeslaSuperchargerUS = 'TYPE_F',
  TeslaSuperchargerV2EU = 'TYPE_G',
  TeslaSuperchargerV3EU = 'TYPE_H',
}

export type ConnectorOutlet = ConnectorOutletSmartSocket | ConnectorOutletEVCharger
export const AllConnectorOutlet = { ...ConnectorOutletSmartSocket, ...ConnectorOutletEVCharger }

export type ConnectorOutletData = Dictionary<
  ConnectorOutlet,
  {
    label: string
    displayName: string
    iconDeselected: SVGIcon
    iconSelected: SVGIcon
  }
>

export interface MenuLabelConfig {
  isVisible?: boolean
}

export interface MenuSliderConfig {
  isSubItem?: boolean
  min?: number
  max?: number
  step?: number
  defaultValue?: number
  isVisible?: boolean
}

export interface MenuSliderProps {
  value: number
  setValue: Function
  config?: MenuSliderConfig
}

export enum SocialMedia {
  Twitter = 'twitter',
  Facebook = 'facebook',
  Messenger = 'messenger',
}

export type SocialMediaData = Dictionary<
  SocialMedia,
  {
    label: string
    iconDeselected: SVGIcon
  }
>

export enum LocaleType {
  EN = 'en',
  // DE = 'de',
  SK = 'sk',
  // FR = 'fr',
}
