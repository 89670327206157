import { useMutation } from '@apollo/client'
import { Alert, Button } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useNavigate, useParams } from 'react-router-dom'
import { ASSIGN_DEVICE } from '../../../../mutations'
import { GET_MY_POIS } from '../../../../queries'
import { UserStateContext } from '../../../../store/userContext'
import { MenuBackButton } from '../../../MenuItems/MenuBackButton'
import { MenuInput } from '../../../MenuItems/MenuInput'
import { MenuItem } from '../../../MenuItems/MenuItem'

export function ServiceMenuReplaceDevice() {
  const navigate = useNavigate()
  const params = useParams()
  const { state } = useContext(UserStateContext)

  const [mac, setMac] = useState('')

  const [assignDevice, assignDeviceResult] = useMutation(ASSIGN_DEVICE, {
    refetchQueries: [GET_MY_POIS],
    awaitRefetchQueries: true,
  })

  // extract selected POI
  const poi = state.pois!.find((x) => x.poiId === params.poiId)
  const service = poi?.services.find((x) => x.serviceId === params.serviceId)

  useEffect(() => {
    if (poi == null) {
      navigate('/settings/pois/')
    }
    if (service == null) {
      navigate('/settings/pois/poi/' + encodeURIComponent(poi!.poiId))
    }
  })

  const navigateToService = () =>
    navigate('/settings/pois/service/' + encodeURIComponent(poi!.poiId) + '/' + encodeURIComponent(service!.serviceId))

  const onAssignDevice = () => {
    assignDevice({
      variables: {
        input: {
          poiId: poi?.poiId,
          serviceId: service?.serviceId,
          mac: mac,
        },
      },
    }).then(() => navigateToService())
  }

  const error_message = 'attachDevice.error.' + assignDeviceResult?.error?.message

  return (
    <div>
      <MenuBackButton onClick={navigateToService} />

      <MenuItem label="Service name" config={{ isNavigable: false }}>
        {service?.serviceName}
      </MenuItem>

      <label className="addpoi__label">
        <FormattedMessage id={'replaceDevice.deviceId'} defaultMessage="New device identifier" />
      </label>
      <MenuInput
        value={mac}
        setValue={setMac}
        config={{
          messageId: 'replaceDevice.deviceId',
          defaultMessageId: 'Device MAC',
        }}
      />

      <div style={{ paddingTop: '1rem' }}>
        {assignDeviceResult.loading && <p>Processing ...</p>}
        {assignDeviceResult.error && (
          <Alert message={<FormattedMessage id={error_message} defaultMessage={error_message} />} type="error" />
        )}
        {!assignDeviceResult.loading && (
          <div style={{ paddingTop: '0.5rem' }}>
            <Button type="primary" onClick={() => onAssignDevice()} disabled={!mac}>
              <FormattedMessage id={'replaceDevice.button'} defaultMessage="Replace device" />
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
