import React, { useContext, useEffect } from 'react'
import './MapContent.css'
import { Marker, Popup, useMapEvents } from 'react-leaflet'

import { Dictionary } from '../../types'
// @ts-ignore No typescript support for v3 yet
import { message } from 'antd'
import L, { LatLngTuple } from 'leaflet'
import {} from '@maplibre/maplibre-gl-leaflet'
import { getActiveServiceFilters, getPOIMarker, isPOITypeDemand } from '../../utils/service'
import { getMapboxGLConfig } from '../../utils/config'
import { FilterTypes, ListNearbyPOIsResponse, NearbyPOI, ServiceType } from '../../graphQLTypes'
import { getArrayOfUniqueValues, getCommonItemsOfTwoArrays } from '../../utils/common'
import { UserStateContext } from '../../store/userContext'
import { useQuery } from '@apollo/client'
import { mapConstants } from '../../data/constants'
import { LIST_NEARBY_POIS } from '../../queries'
import geohash from 'ngeohash'
import { POIDetail } from './POIDetail'
import { useLocation, useNavigate } from 'react-router-dom'

const displayLocationErrorMessage = () => {
  message.error('Error, the current position could not be determined')
}

function listNearbyPoiVariables(map: L.Map) {
  const sw = map.getBounds().getSouthWest()
  const ne = map.getBounds().getNorthEast()
  const zoom = map.getZoom()
  const precision = zoom >= 16 ? 5 : zoom >= 13 ? 4 : zoom >= 11 ? 3 : zoom >= 9 ? 2 : zoom >= 6 ? 1 : 1
  const bboxes = geohash.bboxes(sw.lat, sw.lng, ne.lat, ne.lng, precision)
  return {
    bboxes: bboxes.join(','),
    limit: mapConstants.listNearbyPOIsQueryLimit,
  }
}

function getFiltersAssociatedWithServiceTypes(serviceTypes: ServiceType[], isDemand: boolean): FilterTypes[] {
  const mappings = {
    [ServiceType.CHARGER]: [FilterTypes.Charging],
    [ServiceType.PARKING]: [FilterTypes.Parking],
    [ServiceType.SOCKET]: [FilterTypes.GreenSockets, FilterTypes.BlueSockets],
  }

  const filtersAssociatedWithServiceTypesWithDuplicates = serviceTypes.map((serviceType: ServiceType) =>
    isDemand ? [...mappings[serviceType], FilterTypes.Demand] : mappings[serviceType]
  )

  return getArrayOfUniqueValues(filtersAssociatedWithServiceTypesWithDuplicates.flat())
}

function isDemandFilterActiveFromArray(activeFilters: FilterTypes[]): boolean {
  return activeFilters.find((filter: FilterTypes) => filter === FilterTypes.Demand) !== undefined
}

function isAtLeastOneAffectingFilterActive(
  filtersAffectingGivenService: FilterTypes[],
  activeFilters: FilterTypes[]
): boolean {
  const activeFiltersAffectingGivenService = getCommonItemsOfTwoArrays(filtersAffectingGivenService, activeFilters)
  return activeFiltersAffectingGivenService.length !== 0
}

function isPOIDisplayed(poi: NearbyPOI, isPOIDemand: boolean, activeFilters: FilterTypes[]): boolean {
  if (poi.serviceTypes === null || poi.serviceTypes === []) {
    return false
  }
  const filtersAffectingGivenService = getFiltersAssociatedWithServiceTypes(poi.serviceTypes, isPOIDemand)
  const isAtLeastOneServiceFiltered = isAtLeastOneAffectingFilterActive(filtersAffectingGivenService, activeFilters)
  return isPOIDemand ? isDemandFilterActiveFromArray(activeFilters) : isAtLeastOneServiceFiltered
}

let nearbyPOIs: NearbyPOI[] | undefined = []

export function MapContent({ serviceFilters }: { serviceFilters: Dictionary<FilterTypes, boolean> }) {
  const { state, setUserLocation } = useContext(UserStateContext)
  const navigate = useNavigate()
  const location = useLocation()

  const map = useMapEvents({
    locationerror: (error) => displayLocationErrorMessage(),
    locationfound: (event) => {
      setUserLocation({
        lat: event.latlng.lat,
        lng: event.latlng.lng,
      })
    },
    moveend: (event) => nearbyPoiQuery.refetch(listNearbyPoiVariables(map)),
    click: (event) => {
      if (location.pathname !== '/') {
        navigate('/')
      }
    },
  })

  // POIs
  const nearbyPoiQuery = useQuery<ListNearbyPOIsResponse>(LIST_NEARBY_POIS, {
    fetchPolicy: 'cache-and-network',
    variables: listNearbyPoiVariables(map),
  })

  useEffect(() => {
    // @ts-ignore No need for access token in options
    L.maplibreGL(getMapboxGLConfig()).addTo(map)
  }, [map])

  // only trigger location search, coordinates will be written given in map event
  useEffect(
    () => {
      if (state.refreshUserLocation) {
        map.locate({ setView: true, maxZoom: 13 })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.refreshUserLocation]
  )

  const activeFilters = getActiveServiceFilters(serviceFilters)

  if (nearbyPoiQuery.error) {
    message.error('There was a problem loading service points!')
  } else if (!nearbyPoiQuery.loading) {
    nearbyPOIs = nearbyPoiQuery.data?.listNearbyPOIs?.nearbyPOIs
  }

  return (
    <>
      {nearbyPOIs?.map((poi: NearbyPOI, index: number) => {
        const poiMarker = getPOIMarker(poi.poiType)
        const isPoiDemand = isPOITypeDemand(poi.poiType)
        const geohashloc = geohash.decode(poi.geohash.replace('geohash#', ''))
        const servicePosition: LatLngTuple = [geohashloc.latitude, geohashloc.longitude]
        return (
          isPOIDisplayed(poi, isPoiDemand, activeFilters) && (
            <Marker key={index} position={servicePosition} icon={poiMarker}>
              <Popup>
                <POIDetail pointOfInterest={poi} />
              </Popup>
            </Marker>
          )
        )
      })}
    </>
  )
}
