import { MenuItemsData } from '../../types'
import { MenuSections } from './MenuSections'
import { addServiceConstants } from '../constants'

export enum AddServiceMenuItems {
  BackButton = 'backButton',
  AddService = 'addService',
  NameInput = 'nameInput',
  Location = 'location',
  ServiceType = 'serviceType',
  ServiceTypeSelectable = 'serviceTypeSelectable',
  ServiceTypeSelectableItem = 'serviceTypeSelectableItem',
  EnergySource = 'energySource',
  EnergySourceSelectable = 'energySourceSelectable',
  EnergySourceSelectableItem = 'energySourceSelectableItem',
  Connectors = 'connectors',
  ConnectorsSelectable = 'connectorsSelectable',
  ConnectorsSelectableItem = 'connectorsSelectableItem',
  LocationIcon = 'locationIcon',
  PricePerHour = 'pricePerHour',
  PricePerHourLabel = 'pricePerHourLabel',
  PricePerHourSlider = 'pricePerHourSlider',
  PricePerKW = 'pricePerKW',
  PricePerKWLabel = 'pricePerKWLabel',
  PricePerKWSlider = 'pricePerKWSlider',
  AdvancedSettings = 'advancedSettings',
  ConfirmButton = 'confirmButton',
}

export const addServiceMenuConfig: MenuItemsData = {
  [AddServiceMenuItems.BackButton]: {
    linkTo: MenuSections.MyServices,
  },
  [AddServiceMenuItems.AddService]: {},
  [AddServiceMenuItems.NameInput]: {
    isSubItem: true,
    messageId: 'addService.nameInputPlaceholder',
  },
  [AddServiceMenuItems.Location]: {
    isSubItem: true,
  },
  [AddServiceMenuItems.LocationIcon]: { isVisible: true, isSelectable: true, isEnabled: true },
  [AddServiceMenuItems.ServiceType]: {
    isSubItem: true,
  },
  [AddServiceMenuItems.ServiceTypeSelectable]: {
    isSubItem: true,
  },
  [AddServiceMenuItems.ServiceTypeSelectableItem]: { isVisible: true, isSelectable: true },
  [AddServiceMenuItems.EnergySource]: {
    isSubItem: true,
  },
  [AddServiceMenuItems.EnergySourceSelectable]: {
    isSubItem: true,
  },
  [AddServiceMenuItems.EnergySourceSelectableItem]: { isVisible: true, isSelectable: true },
  [AddServiceMenuItems.Connectors]: {
    isSubItem: true,
  },
  [AddServiceMenuItems.ConnectorsSelectable]: {
    isSubItem: true,
  },
  [AddServiceMenuItems.ConnectorsSelectableItem]: { isVisible: true, isSelectable: true },
  [AddServiceMenuItems.PricePerHour]: {
    isSubItem: true,
  },
  [AddServiceMenuItems.PricePerHourLabel]: {},
  [AddServiceMenuItems.PricePerHourSlider]: {
    isVisible: true,
    isSubItem: true,
    min: addServiceConstants.minimumPricePerHour,
    max: addServiceConstants.maximumPricePerHour,
    step: addServiceConstants.pricePerHourStep,
  },
  [AddServiceMenuItems.PricePerKW]: {
    isSubItem: true,
  },
  [AddServiceMenuItems.PricePerKWLabel]: {},
  [AddServiceMenuItems.PricePerKWSlider]: {
    isSubItem: true,
    min: addServiceConstants.minimumPricePerKW,
    max: addServiceConstants.maximumPricePerKW,
    step: addServiceConstants.pricePerKWStep,
  },
  [AddServiceMenuItems.AdvancedSettings]: {
    label: 'Advanced Settings',
    isNavigable: true,
    isDisabled: true,
    isSubItem: true,
    linkTo: MenuSections.NotDevelopedYet,
  },
  [AddServiceMenuItems.ConfirmButton]: {
    isDisplayed: true,
    isDisabled: false,
  },
}
