import { gql } from 'graphql-tag'

export const LIST_NEARBY_POIS = gql`
  query ListNearbyPOIs($bboxes: String!, $limit: Int!) {
    listNearbyPOIs(bboxes: $bboxes, limit: $limit, nextToken: "") {
      nearbyPOIs {
        userId
        createdAt
        geohash
        userId
        poiName
        description
        poiId
        poiType
        rating
        locationType
        access
        serviceTypes
        poiStatus
      }
    }
  }
`

export const GET_MY_PROFILE = gql`
  query profile {
    getMyProfile {
      email
      screenName
      userName
      preferredCurrency
      creditBalance {
        USD
        EUR
        GBP
        BTC
      }
      profileType
    }
  }
`

export const GET_POI = gql`
  query getPOI($poiId: String!) {
    getPOI(poiId: $poiId) {
      poiId
      poiName
      description
      geohash
      serviceTypes
      access
      locationType
      rating
      poiType
      services {
        isActive
        serviceId
        serviceName
        serviceStatus
        serviceType
        activeSessionId
        socketServiceProps {
          hasEnergyMeter
          isGreen
          socketIOTPlatform
          socketMaxPower
          socketConnectorType
        }
        chargerServiceProps {
          hasEnergyMeter
          isGreen
          chargerCurrentType
          chargerIOTPlatform
          chargerMaxPower
          chargerConnectorType
        }
        serviceSchedule {
          pricingProps {
            pricePerHour
            pricePerUnit
            minPrice
            maxPrice
            unitType
            acceptedCurrency
          }
        }
      }
    }
  }
`

export const GET_MY_POIS = gql`
  query getMyPOIs {
    getMyPOIs {
      pois {
        poiId
        poiName
        description
        geohash
        serviceTypes
        access
        locationType
        rating
        poiType
        services {
          isActive
          serviceId
          serviceName
          serviceStatus
          serviceType
          socketServiceProps {
            hasEnergyMeter
            isGreen
            socketIOTPlatform
            socketMaxPower
            socketConnectorType
          }
          chargerServiceProps {
            hasEnergyMeter
            isGreen
            chargerCurrentType
            chargerIOTPlatform
            chargerMaxPower
            chargerConnectorType
          }
          serviceSchedule {
            pricingProps {
              pricePerHour
              pricePerUnit
              minPrice
              maxPrice
              unitType
              acceptedCurrency
            }
          }
          deviceId
        }
      }
    }
  }
`

export const GET_MY_SERVICES = gql`
  query getMyServices($poiId: String!) {
    getMyServices(poiId: $poiId) {
      services {
        isActive
        serviceId
        serviceName
        serviceStatus
        serviceType
        activeSessionId
        chargerServiceProps {
          chargerMaxPower
        }
        serviceSchedule {
          pricingProps {
            pricePerHour
            pricePerUnit
            minPrice
            maxPrice
            unitType
            acceptedCurrency
          }
        }
      }
    }
  }
`

export const LIST_MY_ACTIVE_SESSIONS = gql`
  query listMyActiveSessions {
    listMyActiveSessions {
      poiId
      serviceId
      sessionId
      energyInitial
      energyCurrent
      energyConsumed
      createdAt
      updatedAt
      serviceName
      serviceType
      totalTime
      totalCost
      lastStatus
      acceptedCurrency
    }
  }
`

export const LIST_MY_PAST_SESSIONS = gql`
  query listMyPastSessions {
    listMyPastSessions {
      sessionId
      serviceName
      serviceType
      createdAt
      destroyedAt
      energyInitial
      energyConsumed
      totalTime
      totalCost
      acceptedCurrency
    }
  }
`

export const LIST_MY_BALANCE_HISTORY = gql`
  query listMyBalanceHistory {
    listMyBalanceHistory {
      createdAt
      amount
      currency
      creditOld
      creditNew
      source
      serviceId
      sessionId
      serviceName
      serviceType
    }
  }
`
