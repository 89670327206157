import React, { ReactNode } from 'react'
import './MenuSelectable.css'
import classNames from 'classnames'
import { MenuSelectableConfig } from '../../types'

export const checkIfIsMenuSelectableVisible = (isVisible?: boolean) => isVisible !== false

export function MenuSelectable({ children, config }: { children: ReactNode; config?: MenuSelectableConfig }) {
  const { isVisible, isSubItem, isCentered, hasBiggerTopMargin, hasNoBottomMargin } = config || {}
  return checkIfIsMenuSelectableVisible(isVisible) ? (
    <div
      className={classNames('menu-selectable', {
        'menu-selectable--sub-item': isSubItem,
        'menu-selectable--centered': isCentered,
        'menu-selectable--bigger-top-margin': hasBiggerTopMargin,
        'menu-selectable--no-bottom-margin': hasNoBottomMargin,
      })}
    >
      {children}
    </div>
  ) : null
}
