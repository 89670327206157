import React, { ChangeEvent } from 'react'
import './MenuInput.css'
import { Input } from 'antd'
import { useIntl } from 'react-intl'

interface MenuInputConfig {
  isSubItem?: boolean
  messageId?: string
  defaultMessageId?: string
}

export function MenuInput({
  value,
  setValue,
  config,
}: {
  value?: string
  setValue: Function
  config?: MenuInputConfig
}) {
  const intl = useIntl()
  const { isSubItem, messageId, defaultMessageId } = config || {}
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)

  return (
    <div className={`menu-input ${isSubItem ? 'menu-input--sub-item' : ''}`}>
      <Input
        placeholder={intl.formatMessage({ id: messageId, defaultMessage: defaultMessageId })}
        className={'menu-input__input'}
        variant="borderless"
        onChange={handleChange}
        value={value}
      />
    </div>
  )
}
