import { EnergySourceData, SmartSocketEnergySource } from '../types'
import { ReactComponent as RenewableIcon } from '../icons/deselected/icons-crowdenergy-152.svg'
import { ReactComponent as RenewableIconSelected } from '../icons/selected/icons-crowdenergy-152.svg'
import { ReactComponent as GridIcon } from '../icons/deselected/icons-crowdenergy-163.svg'
import { ReactComponent as GridIconSelected } from '../icons/selected/icons-crowdenergy-163.svg'

export enum SmartSocketEnergySourceIcons {
  Renewable = 'renewable',
  Grid = 'grid',
}

export const smartSocketEnergySourceIcons = {
  [SmartSocketEnergySourceIcons.Renewable]: {
    deselected: RenewableIcon,
    selected: RenewableIconSelected,
  },
  [SmartSocketEnergySourceIcons.Grid]: {
    deselected: GridIcon,
    selected: GridIconSelected,
  },
}

export const smartSocketEnergySourceData: EnergySourceData = {
  [SmartSocketEnergySource.Renewable]: {
    label: SmartSocketEnergySource.Renewable,
    displayName: 'Renewable',
    iconDeselected: smartSocketEnergySourceIcons[SmartSocketEnergySourceIcons.Renewable].deselected,
    iconSelected: smartSocketEnergySourceIcons[SmartSocketEnergySourceIcons.Renewable].selected,
  },
  [SmartSocketEnergySource.Grid]: {
    label: SmartSocketEnergySource.Grid,
    displayName: 'Grid',
    iconDeselected: smartSocketEnergySourceIcons[SmartSocketEnergySourceIcons.Grid].deselected,
    iconSelected: smartSocketEnergySourceIcons[SmartSocketEnergySourceIcons.Grid].selected,
  },
}
