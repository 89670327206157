import { ActivableTopMenuIcons } from '../components/Map/icons'
import { ReactNode } from 'react'
import { message, notification } from 'antd'
import { getIntlInstance } from './language'
import { Dictionary } from '../types'
import { commonUtilsConstants } from '../data/constants'
import Bowser from 'bowser'

export const isValueInEnum = (value: string, obj: Dictionary<string, any>) => Object.values(obj).includes(value as any)

export function zipWithObject<V>(keys: string[], values: V[]): Dictionary<string, V> {
  return Object.fromEntries(keys.map((k, i) => [k, values[i]]))
}

export const isRequestUnsuccessful = (error?: Dictionary<string, any>, obtainedData?: any) => {
  return error || obtainedData === undefined
}

export function getArrayFromDictionary<V>(dict: Dictionary<string, V>) {
  return Object.entries(dict)
}

export function getArrayOfValuesFromDictionary<V>(dict: Dictionary<string, V>) {
  return Object.values(dict)
}

export function getCommonItemsOfTwoArrays<T>(array1: T[], array2: T[]) {
  return array1.filter((value: T) => array2.includes(value))
}

export const isFilterMenuDisplayed = (activeIcon?: string) => activeIcon === ActivableTopMenuIcons.FilterIcon

export const displayIOSInstallMessage = (description: ReactNode) => {
  const intl = getIntlInstance()
  notification.info({
    message: intl.formatMessage({ id: 'common.addAppToHomeScreen' }),
    description: description,
    placement: 'bottomRight',
    duration: 0,
  })
}

export function isBoolean(variable: any): variable is boolean {
  return typeof variable === 'boolean'
}

export const getOnClickFunctionIfDefined = (onClick?: Function) => (onClick !== undefined ? () => onClick() : undefined)

export const displayNotImplementedMessage = () => {
  const intl = getIntlInstance()
  message.warning(intl.formatMessage({ id: 'common.unimplementedFunctionality' }))
}

export const isClickable = (onClick?: Function, isDisabled?: boolean) => onClick !== undefined && !isDisabled

export function isItemSelected<I>(selectedItem?: I, item?: I) {
  return selectedItem !== undefined ? selectedItem === item : false
}

export function isArrayNonEmpty<T>(arr: T[]) {
  return arr.length > 0
}

export const cutFractionDigitsAfterThreshold = (number: number, threshold: number) => +number.toFixed(threshold)

export const cutFractionDigits = (number: number) =>
  cutFractionDigitsAfterThreshold(number, commonUtilsConstants.maximumFractionDigits)

export const getBrowserVersionData = () => {
  const { name, version } = Bowser.getParser(window.navigator.userAgent).getBrowser() || {}
  return { name, version }
}

export const isDevicePhoneOrTablet = () => {
  // @ts-ignore window.opera is not present on all browsers, don't mind in our case
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      userAgent.substr(0, 4)
    )
  )
}

export const isDevicePortraitOriented = () => window.innerHeight > window.innerWidth

export const isPhoneOrTabletPortraitOriented = () => isDevicePhoneOrTablet() && isDevicePortraitOriented()

export const displayOrientationWarning = () => {
  if (isPhoneOrTabletPortraitOriented()) message.warning('Please, switch orientation back to portrait')
}

export const displayOrientationWarningOnOrientationChange = () => {
  window.addEventListener('orientationchange', (e) => {
    displayOrientationWarning()
  })
}

export function getArrayOfUniqueValues<T>(array: T[]) {
  return array.filter((value: T, index: number, arr: T[]) => arr.indexOf(value) === index)
}

export const getOSInfo = () => {
  const { version, name } = Bowser.parse(window.navigator.userAgent).os
  return { version, name }
}

export const getMetersFromKilometers = (kilometers?: number) =>
  kilometers !== undefined ? kilometers * 1000 : undefined

export const getKilometersFromMeters = (meters?: number) => (meters !== undefined ? meters / 1000 : undefined)

export const getZeroToFiveRatingFromZeroToTenRating = (rating: number) => rating / 2
