import { Loader } from '@aws-amplify/ui-react'
import React from 'react'

export function QueryResult({ loading, error, data, children }: any) {
  if (error) {
    return <p>ERROR: {`${error}`}</p>
  }
  if (loading) {
    return (
      <p>
        <Loader fr={undefined} size="large" /> Loading ...
      </p>
    )
  }
  if (!data) {
    return <p style={{ color: 'red' }}>Empty server response!</p>
  }
  if (data) {
    return children
  }
}

export default QueryResult
