import React, { ReactNode } from 'react'
import { MenuItem } from './MenuItem'
import { MenuLabel } from './MenuLabel'
import { MenuItemConfig, MenuLabelConfig, MenuSliderConfig, MenuSliderProps } from '../../types'
import { MenuSlider } from './MenuSlider'

interface MenuSliderWithLabelConfig {
  menuItemConfig?: MenuItemConfig
  menuLabelConfig?: MenuLabelConfig
  menuSliderConfig?: MenuSliderConfig
}

export function MenuSliderWithLabel({
  config,
  menuSliderProps,
  menuItemLabel,
  label,
}: {
  config?: MenuSliderWithLabelConfig
  menuSliderProps: MenuSliderProps
  menuItemLabel: string | ReactNode
  label: string | ReactNode
}) {
  const { menuItemConfig, menuLabelConfig, menuSliderConfig } = config || {}

  return (
    <>
      <MenuItem label={menuItemLabel} config={menuItemConfig}>
        <MenuLabel label={label} config={menuLabelConfig} hasFixedWidth={false} />
      </MenuItem>

      <MenuSlider {...menuSliderProps} config={menuSliderConfig} />
    </>
  )
}
