import { Alert, Button, Select, Space, Input } from 'antd'
import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MapContainer, TileLayer } from 'react-leaflet'

import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { addServiceConstants, mapConstants, pricingProps } from '../../../../data/constants'
import {
  AcceptedCurrency,
  AddPOIInput,
  IOTPlatform,
  POIAccess,
  POILocationType,
  ProfileType,
  ServiceType,
  UnitType,
} from '../../../../graphQLTypes'
import { ADD_POI } from '../../../../mutations'
import { GET_MY_POIS } from '../../../../queries'
import { ConnectorOutlet, EnergySource, SmartSocketEnergySource } from '../../../../types'
import { getArrayOfValuesFromDictionary } from '../../../../utils/common'
import { determineIfServiceHasPriceByKW, getServiceData } from '../../../../utils/service'
import { MenuBackButton } from '../../../MenuItems/MenuBackButton'
import { MenuInput } from '../../../MenuItems/MenuInput'
import { MenuItem } from '../../../MenuItems/MenuItem'
import { MenuSliderWithLabel } from '../../../MenuItems/MenuSliderWithLabel'
import { ConnectorsSelectable } from '../../../Menuold/AddServiceMenu/ConnectorsSelectable'
import { EnergySourceSelectable } from '../../../Menuold/AddServiceMenu/EnergySourceSelectable'
import { ServiceTypeSelectable } from '../../../Menuold/SetServiceDemandMenu/ServiceTypeSelectable'
import './AddPOIMenu.scss'
import { MapContent } from './MapContent'
import { UserStateContext } from '../../../../store/userContext'
import { SelectAcceptedCurrency } from './SelectAcceptedCurrency'
import MenuAmount from '../../../MenuItems/MenuAmount'
import { LatLng } from 'leaflet'

interface NewService {
  serviceName?: string
  description?: string
  geohash?: string
  locationType: POILocationType
  access: POIAccess
  poiType: ProfileType
  serviceType?: ServiceType
  energySource?: EnergySource
  connector?: ConnectorOutlet
  pricePerHour: number
  pricePerKW: number
  acceptedCurrency: AcceptedCurrency
}

export function AddPOIMenu() {
  const navigate = useNavigate()
  const { state } = useContext(UserStateContext)
  const [mapCenter, setMapCenter] = useState(state.userLocation)
  const intl = useIntl()

  const [step, setStep] = useState(1)

  const userCurrency = state?.profile?.preferredCurrency
    ? AcceptedCurrency[state?.profile?.preferredCurrency]
    : AcceptedCurrency.EUR

  const [service, setService] = useState<NewService>({
    locationType: POILocationType.PUBLIC_SPACE,
    pricePerHour: pricingProps[userCurrency].minimumPricePerHour,
    pricePerKW: pricingProps[userCurrency].minimumPricePerKW,
    access: POIAccess.UNRESTRICTED,
    poiType: state.profile?.profileType || ProfileType.INDIVIDUAL,
    acceptedCurrency: userCurrency,
  })
  const setServiceName = (value: string) => {
    setService((prevState) => ({
      ...prevState,
      serviceName: value,
    }))
  }
  const setDescription = (description: string) => {
    setService((prevState) => ({
      ...prevState,
      description: description,
    }))
  }

  const setGeohash = (geohash: string, latlng: LatLng) => {
    setMapCenter(latlng)
    setService((prevState) => ({
      ...prevState,
      geohash: geohash,
    }))
  }
  const setLocationType = (locationType: POILocationType) => {
    setService((prevState) => ({
      ...prevState,
      locationType: locationType,
    }))
  }

  const setAccess = (access: POIAccess) => {
    setService((prevState) => ({
      ...prevState,
      access: access,
    }))
  }

  const setPoiType = (poiType: ProfileType) => {
    setService((prevState) => ({
      ...prevState,
      poiType: poiType,
    }))
  }

  const setServiceType = (serviceType: ServiceType | undefined) => {
    setService((prevState) => ({
      ...prevState,
      serviceType: serviceType,
      energySource: undefined,
      connector: undefined,
    }))
  }

  const setEnergySource = (energySource: EnergySource | undefined) => {
    setService((prevState) => ({
      ...prevState,
      energySource: energySource,
    }))
  }

  const setConnector = (connector: ConnectorOutlet) => {
    setService((prevState) => ({
      ...prevState,
      connector: connector,
    }))
  }

  const setPricePerHour = (newPrice: number) => {
    setService((prevState) => ({
      ...prevState,
      pricePerHour: newPrice,
    }))
  }

  const setPricePerKW = (newPrice: number) => {
    setService((prevState) => ({
      ...prevState,
      pricePerKW: newPrice,
    }))
  }

  const setAcceptedCurrency = (acceptedCurrency: AcceptedCurrency) => {
    setService((prevState) => ({
      ...prevState,
      acceptedCurrency: acceptedCurrency,
      pricePerHour: pricingProps[acceptedCurrency].minimumPricePerHour,
      pricePerKW: pricingProps[acceptedCurrency].minimumPricePerKW,
    }))
  }

  const isValidFirstStep = (state: NewService) => {
    return state.serviceName !== '' && state.geohash != null && state.description
  }

  const serviceData = getServiceData(service.serviceType)

  const isPricePerKWSliderDisplayed = determineIfServiceHasPriceByKW(service.serviceType)

  const isValidSecondStep = (state: NewService) => {
    return (
      isValidFirstStep(state) &&
      state.serviceName != null &&
      state.serviceType !== undefined &&
      (state.energySource !== undefined || serviceData?.energySources === undefined) &&
      (state.connector !== undefined || serviceData?.connectors === undefined) &&
      state.acceptedCurrency
    )
  }

  // mutation for adding POI (with service)
  const [addPOI, addPOIResult] = useMutation(ADD_POI, {
    refetchQueries: [GET_MY_POIS],
  })

  /**
   * Transform our internal object to GraphQL request and send it
   */
  const addPOIWrapper = () => {
    const poi: AddPOIInput = {
      poiName: service.description!,
      description: service.description!,
      geohash: service.geohash!,
      poiType: service.poiType,
      locationType: service.locationType,
      access: service.access,
      service: {
        serviceName: service.serviceName!,
        serviceType: service.serviceType!,
        serviceSchedule: {
          isActive: true,
          pricingProps: {
            acceptedCurrency: service.acceptedCurrency,
            pricePerHour: service.pricePerHour,
          },
        },
      },
    }
    if (service.serviceType === ServiceType.PARKING) {
      poi.service.parkingServiceProps = {
        hasCCTV: false,
      }
    }
    if (service.serviceType === ServiceType.CHARGER) {
      poi.service.serviceSchedule.pricingProps.unitType = UnitType.KW
      poi.service.serviceSchedule.pricingProps.pricePerUnit = service.pricePerKW
      poi.service.chargerServiceProps = {
        hasEnergyMeter: false,
        isGreen: service.energySource === SmartSocketEnergySource.Renewable,
        chargerIOTPlatform: IOTPlatform.CE_NATIVE,
        chargerConnectorType: service.connector!,
      }
    }
    if (service.serviceType === ServiceType.SOCKET) {
      poi.service.serviceSchedule.pricingProps.unitType = UnitType.KW
      poi.service.serviceSchedule.pricingProps.pricePerUnit = service.pricePerKW
      poi.service.socketServiceProps = {
        hasEnergyMeter: false,
        isGreen: service.energySource === SmartSocketEnergySource.Renewable,
        socketIOTPlatform: IOTPlatform.CE_NATIVE,
        socketConnectorType: service.connector!,
      }
    }
    addPOI({ variables: { input: poi } }).then(() => {
      navigate('/settings/pois')
    })
  }

  return (
    <div>
      <MenuBackButton onClick={() => (step === 1 ? navigate('/settings/pois') : setStep(1))} />

      {step === 1 && (
        <div className="menu-poi">
          <h3>
            <FormattedMessage id={'addPOI.step1.title'} />
          </h3>

          <label className="addpoi__label">
            <FormattedMessage id={'addPOI.step1.description'} />
          </label>
          <Input.TextArea
            placeholder={intl.formatMessage({ id: 'addPOI.step1.description.placeholder' })}
            rows={2}
            value={service.description}
            onChange={(event) => setDescription(event.target.value)}
          ></Input.TextArea>

          <label className="addpoi__label">{<FormattedMessage id={'addService.location'} />}</label>
          <MapContainer
            center={mapCenter}
            zoom={mapConstants.defaultZoom}
            scrollWheelZoom={true}
            className="addpoi__map"
            attributionControl={false}
          >
            <TileLayer attribution="n/a" url="n/a" crossOrigin={true} />
            <MapContent setGeohash={setGeohash} poiType={service.poiType} />
          </MapContainer>

          <label className="addpoi__label">
            <FormattedMessage id={'addPOI.step1.type'} />
          </label>
          <Select style={{ minWidth: 200 }} defaultValue={service.poiType} onChange={(value) => setPoiType(value)}>
            <Select.Option value={ProfileType.INDIVIDUAL}>
              <FormattedMessage id={'profile.profileType.INDIVIDUAL'} />
            </Select.Option>
            <Select.Option value={ProfileType.BUSINESS}>
              <FormattedMessage id={'profile.profileType.BUSINESS'} />
            </Select.Option>
          </Select>

          <label className="addpoi__label">
            <FormattedMessage id={'addPOI.step1.locationType'} />
          </label>
          <Select
            style={{ minWidth: 200 }}
            defaultValue={service.locationType}
            onChange={(value) => setLocationType(value)}
          >
            <Select.Option value={POILocationType.PUBLIC_SPACE}>
              <FormattedMessage id={'poi.locationType.PUBLIC_SPACE'} />
            </Select.Option>
            <Select.Option value={POILocationType.PRIVATE}>
              <FormattedMessage id={'poi.locationType.PRIVATE'} />
            </Select.Option>
          </Select>

          <label className="addpoi__label">
            <FormattedMessage id={'addPOI.step1.access'} />
          </label>
          <Select style={{ minWidth: 200 }} defaultValue={service.access} onChange={(value) => setAccess(value)}>
            <Select.Option value={POIAccess.UNRESTRICTED}>
              <FormattedMessage id={'poi.access.UNRESTRICTED'} />
            </Select.Option>
            <Select.Option value={POIAccess.RESTRICTED}>
              <FormattedMessage id={'poi.access.RESTRICTED'} />
            </Select.Option>
          </Select>

          <div style={{ paddingTop: '1rem' }}>
            <Button type="primary" onClick={() => setStep(2)} disabled={!isValidFirstStep(service)}>
              &raquo;&nbsp;
              <FormattedMessage id={'addPOI.step1.continue'} />
            </Button>
          </div>
        </div>
      )}

      {step === 2 && (
        <div>
          <h3>
            <FormattedMessage id={'addPOI.step2.title'} />
          </h3>

          <label className="addpoi__label">
            <FormattedMessage id={'addPOI.step2.name'} />
          </label>
          <MenuInput
            value={service?.serviceName}
            setValue={setServiceName}
            config={{ messageId: 'addPOI.step2.name.placeholder' }}
          />

          <label className="addpoi__label">
            <FormattedMessage id={'addService.serviceType'} />
          </label>
          <ServiceTypeSelectable
            selectedServiceType={service.serviceType}
            handleSetSelectedServiceType={setServiceType}
            iconsConfig={{ isVisible: true, isSelectable: true, hasFixedWidth: true }}
          />

          {serviceData?.energySources && (
            <label className="addpoi__label">
              <FormattedMessage id={`addService.${serviceData?.energySourcesMessageIdPostfix}`} />
            </label>
          )}
          <EnergySourceSelectable
            energySources={serviceData?.energySources}
            setSelectedEnergySource={setEnergySource}
            selectedEnergySource={service.energySource}
            itemsConfig={{ isVisible: true, isSelectable: true }}
          />
          <MenuItem
            label={<FormattedMessage id={'addService.connectors'} />}
            config={{
              isVisible: serviceData?.connectors !== undefined,
            }}
          />
          <ConnectorsSelectable
            selectedConnector={service.connector}
            setSelectedConnector={setConnector}
            connectors={serviceData?.connectors}
            itemsConfig={{
              isVisible: true,
              isSelectable: true,
              hasFixedWidth:
                serviceData?.connectors !== undefined &&
                getArrayOfValuesFromDictionary(serviceData?.connectors).length >
                  addServiceConstants.maxConnectorsInPanel,
            }}
          />

          <MenuItem label="Accepted currency" />
          <SelectAcceptedCurrency
            acceptedCurrency={service.acceptedCurrency}
            setAcceptedCurrency={setAcceptedCurrency}
          />

          <MenuSliderWithLabel
            menuSliderProps={{
              value: service.pricePerHour,
              setValue: setPricePerHour,
            }}
            menuItemLabel={<FormattedMessage id={'addService.pricePerHour'} />}
            label={<MenuAmount amount={service.pricePerHour} currency={service.acceptedCurrency} />}
            config={{
              menuSliderConfig: {
                isVisible: true,
                min: pricingProps[service.acceptedCurrency].minimumPricePerHour,
                max: pricingProps[service.acceptedCurrency].maximumPricePerHour,
                step: pricingProps[service.acceptedCurrency].pricePerHourStep,
              },
            }}
          />
          <MenuSliderWithLabel
            menuSliderProps={{
              value: service.pricePerKW,
              setValue: setPricePerKW,
            }}
            menuItemLabel={<FormattedMessage id={'addService.pricePerKW'} />}
            label={<MenuAmount amount={service.pricePerKW} currency={service.acceptedCurrency} />}
            config={{
              menuItemConfig: {
                isVisible: isPricePerKWSliderDisplayed,
              },
              menuLabelConfig: {
                isVisible: isPricePerKWSliderDisplayed,
              },
              menuSliderConfig: {
                min: pricingProps[service.acceptedCurrency].minimumPricePerKW,
                max: pricingProps[service.acceptedCurrency].maximumPricePerKW,
                step: pricingProps[service.acceptedCurrency].pricePerKWStep,
                isVisible: isPricePerKWSliderDisplayed,
              },
            }}
          />

          <div style={{ paddingTop: '1em' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              {addPOIResult.loading && <div>Processing ...</div>}
              {addPOIResult.error && <Alert message={'Error: ' + addPOIResult.error.message} type="error" />}
              {!addPOIResult.loading && (
                <Button type="primary" onClick={addPOIWrapper} disabled={!isValidSecondStep(service)}>
                  <FormattedMessage id={'addService.confirmButtonOptionalText'} />
                </Button>
              )}
            </Space>
          </div>
        </div>
      )}
    </div>
  )
}
