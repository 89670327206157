import React from 'react'
import { LeftOutlined } from '@ant-design/icons'
import './MenuBackButton.css'

export function MenuBackButton({ onClick }: { onClick?: Function }) {
  return (
    <div>
      <LeftOutlined
        className={'menu-back-button__arrow'}
        onClick={onClick !== undefined ? () => onClick() : undefined}
      />
    </div>
  )
}
