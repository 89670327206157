import React, { Fragment, useContext } from 'react'

import Icon from '@ant-design/icons'
import { getServiceDetailIcon } from '../../utils/service'
import { Descriptions } from 'antd'
import { UserStateContext } from '../../store/userContext'
import { formatCurrency } from '../../utils/currency'
import POIServiceActionStop from '../POI/POIServiceActionStop'
import './Sessions.css'

function fmtTime(unix_timestamp: string) {
  return new Date(parseInt(unix_timestamp) * 1000).toLocaleTimeString()
}

export default function Sessions() {
  const userCtx = useContext(UserStateContext)

  return (
    <Fragment>
      {userCtx.state.activeSessions.map((session) => (
        <div className="session__box" key={session.sessionId}>
          <span className="poi__poiServiceIcons">
            <Icon className={'service-type__icon'} component={getServiceDetailIcon(session.serviceType)} />
          </span>
          <Descriptions title={session.serviceName} bordered={true} column={1}>
            <Descriptions.Item label="Start time">{fmtTime(session.createdAt)}</Descriptions.Item>
            <Descriptions.Item label="Energy consumed">{session.energyConsumed} KWh</Descriptions.Item>
            <Descriptions.Item label="Total time">{session.totalTime} seconds</Descriptions.Item>
            <Descriptions.Item label="Total price">
              {formatCurrency(session.acceptedCurrency, session.totalCost)}
            </Descriptions.Item>
          </Descriptions>
          <div className="session__actions">
            <POIServiceActionStop poiId={session.poiId} serviceId={session.serviceId} sessionId={session.sessionId} />
          </div>
        </div>
      ))}
    </Fragment>
  )
}
