import React from 'react'
import Icon from '@ant-design/icons'
import { SVGIcon } from './Map/icons'
import './SelectableIcon.css'

interface SelectableIconConfig {
  isSelected?: boolean
  isEnabled?: boolean
}

const getEnabledIcon = (isSelected?: boolean, iconSelected?: SVGIcon, iconDeselected?: SVGIcon) =>
  isSelected ? iconSelected : iconDeselected

export function SelectableIcon({
  onClick,
  iconSelected,
  iconDeselected,
  iconDisabled,
  className,
  config,
}: {
  onClick?: Function
  iconSelected?: SVGIcon
  iconDeselected: SVGIcon
  className?: string
  iconDisabled?: SVGIcon
  config?: SelectableIconConfig
}) {
  const { isEnabled, isSelected } = config || {}
  return (
    <Icon
      className={`selectable-icon ${className}`}
      component={isEnabled ? getEnabledIcon(isSelected, iconSelected, iconDeselected) : iconDisabled}
      onClick={onClick !== undefined ? () => onClick() : undefined}
    />
  )
}
