import React from 'react'
import './POIService.css'
import { OtherPOI, OtherService, ServiceStatus } from '../../graphQLTypes'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { isAuthenticated } from '../../utils/auth'
import POIServiceActionStart from './POIServiceActionStart'
import POIServiceActionStop from './POIServiceActionStop'
import './POIServiceActions.css'
import { POIActivationProperties } from './POI'

export default function POIServiceActions({
  poi,
  service,
  activationProperties,
}: Readonly<{ poi: OtherPOI; service: OtherService; activationProperties: POIActivationProperties }>) {
  const isServiceAvailable = service.serviceStatus === ServiceStatus.AVAILABLE
  const isServiceInUse = service.serviceStatus === ServiceStatus.IN_USE
  const isServiceInUseByMe = isServiceInUse && service.activeSessionId

  // user is not authenticated
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  if (!isAuthenticated(authStatus)) {
    return <div className="poiservice_actions__box">Please log in to start this service</div>
  }

  // start service block
  if (isServiceAvailable) {
    return (
      <POIServiceActionStart
        poiId={poi.poiId}
        serviceId={service.serviceId}
        activationProperties={activationProperties}
      />
    )
  }

  // stop service
  if (isServiceInUseByMe) {
    return <POIServiceActionStop poiId={poi.poiId} serviceId={service.serviceId} sessionId={service.activeSessionId} />
  }

  // someone else using it
  if (isServiceInUse) {
    return <div>Service is in use by someone else</div>
  }

  return <div>Service status: {service.serviceStatus}</div>
}
