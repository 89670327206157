import { useMutation } from '@apollo/client'
import { DISPATCH_ACTION, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js'
import { PAYPAL_CAPTURE_ORDER } from '../../../mutations'
import { GET_MY_PROFILE } from '../../../queries'
import { Loader } from '@aws-amplify/ui-react'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { Alert } from 'antd'
import { formatCurrency } from '../../../utils/currency'

interface Props {
  amount: number
  currency: string
  setFrozen: Dispatch<SetStateAction<boolean>>
}

export default function BuyPaypalButtonInner({ amount, currency, setFrozen }: Readonly<Props>) {
  const [{ isPending, options }, dispatch] = usePayPalScriptReducer()

  // re-initialize paypal with changed currency
  useEffect(() => {
    console.log('dispatching ....', currency)
    dispatch({
      type: DISPATCH_ACTION.RESET_OPTIONS,
      value: {
        ...options,
        currency: currency,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency])

  const [mutateFunction, { data, loading, error, reset }] = useMutation(PAYPAL_CAPTURE_ORDER, {
    refetchQueries: [GET_MY_PROFILE],
    onCompleted: () => {
      setFrozen(false)
    },
    onError: () => {
      setFrozen(false)
    },
  })

  // reset payments on currency or amount change
  useEffect(() => {
    reset()
  }, [currency, amount, reset])

  // till buttons are ready
  if (isPending) {
    return (
      <>
        <Loader fr={undefined} /> Preparing paypal payment ...
      </>
    )
  }

  // onApprove mutation in progress
  if (loading) {
    return (
      <div className="buy-balance-card-paypal-buttons__container">
        <p>
          <Loader fr={undefined} /> Updating user balance ...
        </p>
      </div>
    )
  }

  // onApprove mutation error
  if (error) {
    return (
      <div className="buy-balance-card-paypal-buttons__container">
        <Alert showIcon type="error" message="There was a error updating Your balance." />
      </div>
    )
  }

  // onApprove mutation success
  if (data) {
    const formattedAmount = formatCurrency(
      data.paypalCaptureOrder.balanceCurrency,
      data.paypalCaptureOrder.balanceAdded
    )
    return (
      <div className="buy-balance-card-paypal-buttons__container">
        <Alert showIcon type="success" message={`Your balance was successfully increased by ${formattedAmount}.`} />
      </div>
    )
  }
  return (
    <div>
      <PayPalButtons
        style={{
          layout: 'vertical',
          label: 'pay',
        }}
        forceReRender={[currency, amount, options]}
        createOrder={(_, actions) => {
          setFrozen(true)
          // create order on the client = JavaScript side
          return actions.order
            .create({
              intent: 'CAPTURE',
              purchase_units: [
                {
                  amount: {
                    currency_code: currency,
                    value: '' + amount,
                  },
                },
              ],
            })
            .then((orderId) => {
              console.log(`order ${orderId} created, amount: ${amount}, currency: ${currency}`)
              return orderId
            })
        }}
        onApprove={async (data, actions) => {
          console.log(`order ${data.orderID} approved`)
          mutateFunction({ variables: { order: { id: data.orderID } } })
        }}
        onError={(err) => {
          console.log('error happened')
          setFrozen(false)
        }}
        onCancel={() => {
          console.log('order cancelled by user')
          setFrozen(false)
        }}
      ></PayPalButtons>
    </div>
  )
}
