import { useQuery } from '@apollo/client'
import { useContext, useEffect } from 'react'

import { Outlet } from 'react-router-dom'
import { GetMyPOIsResponse } from '../../../graphQLTypes'
import { GET_MY_POIS } from '../../../queries'
import { UserStateContext } from '../../../store/userContext'
import QueryResult from '../../QueryResult'

export function POIMenu() {
  const { refreshUserPois } = useContext(UserStateContext)

  // query user POIs and services
  const { loading, error, data } = useQuery<GetMyPOIsResponse>(GET_MY_POIS)

  // and store this into context
  useEffect(() => {
    if (data?.getMyPOIs.pois != null) {
      refreshUserPois(data?.getMyPOIs.pois)
    }
  }, [data, refreshUserPois])

  return (
    <QueryResult loading={loading} error={error} data={data}>
      <Outlet />
    </QueryResult>
  )
}
