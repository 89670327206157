import React, { useContext } from 'react'
import { Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import './LanguageDropdown.css'
import { UserStateContext } from '../../../store/userContext'
import { useMutation } from '@apollo/client'
import { UPDATE_PROFILE } from '../../../mutations'
import { GET_MY_PROFILE } from '../../../queries'
import { Loader } from '@aws-amplify/ui-react'
import type { MenuProps } from 'antd'
import { ProfileType } from '../../../graphQLTypes'
import { FormattedMessage } from 'react-intl'

export function ProfileTypeDropdown() {
  const { state } = useContext(UserStateContext)

  const [mutateFunction, { loading, error }] = useMutation(UPDATE_PROFILE, {
    refetchQueries: [GET_MY_PROFILE],
  })

  function changeProfileType(profileType: ProfileType) {
    mutateFunction({
      variables: {
        input: {
          profileType: profileType,
        },
      },
    })
  }

  if (loading) {
    return (
      <div>
        <Loader fr={undefined} /> updating ...
      </div>
    )
  }
  if (error) {
    return <div>error: {error.message}!</div>
  }

  console.log(Object.values(ProfileType))

  const items: MenuProps['items'] = Object.values(ProfileType).map((profileType) => {
    return {
      key: profileType!,
      disabled: state?.profile?.profileType
        ? state?.profile?.profileType === profileType
        : profileType === ProfileType.INDIVIDUAL,
      onClick: () => changeProfileType(profileType!),
      label: (
        <span>
          <FormattedMessage id={'profile.profileType.' + profileType} />
        </span>
      ),
    }
  })

  return (
    <Menu>
      <Menu.Item>
        <Dropdown menu={{ items }}>
          <a href="/#" className="ant-dropdown-link language-dropdown__link" onClick={(e) => e.preventDefault()}>
            <FormattedMessage id={'profile.profileType.' + (state?.profile?.profileType || ProfileType.INDIVIDUAL)} />
            <DownOutlined />
          </a>
        </Dropdown>
      </Menu.Item>
    </Menu>
  )
}
