import React, { useContext } from 'react'
import { MenuBackButton } from '../../MenuItems/MenuBackButton'
import { MenuItem } from '../../MenuItems/MenuItem'
import { FormattedMessage } from 'react-intl'
import { LanguageDropdown } from './LanguageDropdown'
import { useNavigate } from 'react-router-dom'
import { CurrencyDropdown } from './CurrencyDropdown'
import { UserStateContext } from '../../../store/userContext'
import { ProfileTypeDropdown } from './ProfileTypeDropdown'

export function ProfileMenu() {
  const navigate = useNavigate()
  const { state } = useContext(UserStateContext)

  return (
    <div>
      <MenuBackButton onClick={() => navigate('/settings')} />

      <MenuItem label={<FormattedMessage id={'profile.profile'} />} config={{}} />

      <MenuItem label={<FormattedMessage id={'profile.username'} />} config={{ isSubItem: true }}>
        {state.profile?.userName}
      </MenuItem>

      <MenuItem label={<FormattedMessage id={'profile.email'} />} config={{ isSubItem: true }}>
        {state.profile?.email}
      </MenuItem>

      <MenuItem label={<FormattedMessage id={'profile.nickname'} />} config={{ isSubItem: true }}>
        {state.profile?.screenName}
      </MenuItem>

      <MenuItem
        label={<FormattedMessage id={'profile.avatar'} />}
        config={{ isSubItem: true, isDisabled: true }}
      ></MenuItem>

      <MenuItem label={<FormattedMessage id={'profile.chooseCurrency'} />} config={{ isSubItem: true }}>
        <CurrencyDropdown />
      </MenuItem>

      <MenuItem label={<FormattedMessage id={'profile.language'} />} config={{ isSubItem: true }}>
        <LanguageDropdown />
      </MenuItem>

      <MenuItem label={<FormattedMessage id={'profile.profileType'} />} config={{ isSubItem: true }}>
        <ProfileTypeDropdown />
      </MenuItem>
    </div>
  )
}
