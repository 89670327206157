import L, { PointTuple } from 'leaflet'

export const generateOptions = (iconUrl: string) => ({
  iconUrl: iconUrl,
  iconRetinaUrl: iconUrl,
  popupAnchor: [0, -75] as PointTuple,
  iconSize: new L.Point(60, 75),
  iconAnchor: new L.Point(30, 75),
  className: 'leaflet-div-icon',
})
