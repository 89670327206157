import { useQuery } from '@apollo/client'
import React, { useContext, useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { GET_POI } from '../../queries'
import QueryResult from '../QueryResult'
import SettingsWrapper from '../Settings/SettingsWrapper'
import POI from './POI'
import './POIWrapper.css'
import { LeftOutlined } from '@ant-design/icons'
import { GetPOIResponse } from '../../graphQLTypes'
import { Authenticator } from '@aws-amplify/ui-react'
import { UserStateContext } from '../../store/userContext'
import geohash from 'ngeohash'
import { ServiceStateContext } from '../../store/markerContext'
import { HistoryCoordinates } from '../../types'

export default function POIWrapper() {
  const params = useParams()
  const { stateMarker, addService } = useContext(ServiceStateContext)
  const { setLastServiceLocation } = useContext(UserStateContext)

  const { loading, error, data } = useQuery<GetPOIResponse>(GET_POI, {
    variables: {
      poiId: params.poiId,
    },
  })

  useEffect(() => {
    addService(data?.getPOI)
    if (params != null && data != null) {
      const geohashloc = geohash.decode(data!.getPOI.geohash.replace('geohash#', ''))
      setLastServiceLocation({
        lat: geohashloc.latitude,
        lng: geohashloc.longitude,
      })
      localStorage.setItem(HistoryCoordinates.LNG, geohashloc.longitude.toString())
      localStorage.setItem(HistoryCoordinates.LAT, geohashloc.latitude.toString())
    }
  }, [data?.getPOI])

  return (
    <SettingsWrapper>
      <div className={'poi__wrapper'}>
        <Authenticator signUpAttributes={['email']} className={'auth__authentificator'}>
          {() => (
            <QueryResult loading={loading} error={error} data={stateMarker.otherPOI}>
              <NavLink to="/">
                <LeftOutlined></LeftOutlined>
              </NavLink>
              <POI poi={stateMarker.otherPOI} />
            </QueryResult>
          )}
        </Authenticator>
      </div>
    </SettingsWrapper>
  )
}
