import React, { Fragment } from 'react'
import { MyPastChargingSession } from '../../graphQLTypes'
import type { ColumnsType } from 'antd/es/table'
import Table from 'antd/es/table'
import { formatCurrency } from '../../utils/currency'

type Props = {
  data: MyPastChargingSession[]
}

function fmtDateTime(unix_timestamp: string) {
  return new Date(parseInt(unix_timestamp) * 1000).toLocaleString()
}

function fmtTotalTime(totalTime: string) {
  return totalTime + 's'
}

function fmtTotalCost(totalCost: string, record: MyPastChargingSession) {
  return formatCurrency(record.acceptedCurrency, record.totalCost)
}

const columns: ColumnsType<MyPastChargingSession> = [
  { title: 'Started', dataIndex: 'createdAt', key: 'createdAt', render: fmtDateTime },
  { title: 'Total time', dataIndex: 'totalTime', key: 'totalTime', render: fmtTotalTime },
  { title: 'Total cost', dataIndex: 'totalCost', key: 'totalCost', render: fmtTotalCost, align: 'right' },
]

export default function SessionHistoryTable({ data }: Readonly<Props>) {
  return (
    <Table
      size="small"
      columns={columns}
      dataSource={data}
      rowKey="sessionId"
      expandable={{
        expandedRowRender: (record) => <p>{record.serviceName}</p>,
        rowExpandable: (record) => true,
        expandRowByClick: true,
        showExpandColumn: false,
      }}
    />
  )
}
