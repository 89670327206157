import React from 'react'
import { MenuItem } from '../MenuItems/MenuItem'
import { FormattedMessage } from 'react-intl'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useNavigate } from 'react-router-dom'

export default function RootMenu() {
  const { signOut } = useAuthenticator((context) => [context.user])
  const navigate = useNavigate()

  return (
    <div>
      <MenuItem label={<FormattedMessage id={'menu.profile'} />} onClick={() => navigate('profile')} config={{}} />
      <MenuItem
        label={<FormattedMessage id={'menu.pois'} defaultMessage="Service locations" />}
        onClick={() => navigate('pois')}
        config={{}}
      />
      <MenuItem label={<FormattedMessage id={'menu.balances'} />} onClick={() => navigate('balance')} config={{}} />
      <MenuItem label={<FormattedMessage id={'menu.about'} />} onClick={() => navigate('about')} config={{}} />
      <MenuItem label={<FormattedMessage id={'menu.signOut'} />} onClick={signOut} config={{}} />
    </div>
  )
}
