import React from 'react'
import { MyBalanceHistory, ServiceType } from '../../../graphQLTypes'
import { Flex, Table } from 'antd'
import { formatCurrency } from '../../../utils/currency'
import type { ColumnsType } from 'antd/es/table'
import Icon, { CreditCardTwoTone } from '@ant-design/icons'

import { getServiceDetailIcon } from '../../../utils/service'

type Props = {
  data: MyBalanceHistory[]
}

function fmtDateTime(unix_timestamp: string) {
  return new Date(parseInt(unix_timestamp) * 1000).toLocaleString()
}

function fmtSource(item: MyBalanceHistory) {
  if (item.source === 'session') {
    if (item.serviceType === null || item.serviceName === null) {
      return 'Not provided'
    }
    const serviceTypeKey = item.serviceType as keyof typeof ServiceType
    return (
      <Flex align="center">
        <Icon component={getServiceDetailIcon(ServiceType[serviceTypeKey])} style={{ fontSize: '150%' }} />
        {item.serviceName}
      </Flex>
    )
  }
  if (item.source === 'paypal') {
    return (
      <Flex align="center">
        <CreditCardTwoTone style={{ marginLeft: '0.2rem', marginRight: '0.2rem' }} /> PayPal
      </Flex>
    )
  }
  return item.source
}

const columns: ColumnsType<MyBalanceHistory> = [
  { title: 'Date', dataIndex: 'createdAt', render: fmtDateTime, width: '40%' },
  { title: 'Source', render: fmtSource, width: '40%' },
  {
    title: 'Amount',
    width: '20%',
    align: 'right',
    render: (_, record) => <strong>{formatCurrency(record.currency, record.amount)}</strong>,
  },
]

export default function BalanceHistoryTable({ data }: Readonly<Props>) {
  console.log(data)
  return <Table size="small" columns={columns} pagination={{ pageSize: 10 }} dataSource={data} rowKey="sk" />
}
