import React, { ReactNode } from 'react'
import { RightOutlined } from '@ant-design/icons'
import './MenuItem.css'
import classNames from 'classnames'
import { MenuItemConfig } from '../../types'
import { isClickable } from '../../utils/common'

const isMenuItemVisible = (isVisible?: boolean) => isVisible !== false

export function MenuItem({
  label,
  onClick,
  config,
  children,
}: {
  label: string | ReactNode
  onClick?: Function
  config?: MenuItemConfig
  children?: ReactNode
}) {
  const { isNavigable, isDisabled, isSubItem, isVisible } = config || {}
  const isMenuItemClickable = isClickable(onClick, isDisabled)
  return isMenuItemVisible(isVisible) ? (
    <div
      onClick={isMenuItemClickable ? () => onClick!() : undefined}
      className={classNames('menu-item', {
        'menu-item--disabled': isDisabled,
        'menu-item--clickable': isMenuItemClickable,
      })}
    >
      <span
        className={classNames('menu-item__label', {
          'menu-item__label--sub-item': isSubItem,
        })}
      >
        {label}
      </span>
      {isNavigable && <RightOutlined className={'menu-item__arrow'} />}
      {children !== undefined && children}
    </div>
  ) : null
}
