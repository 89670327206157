import { POILocationType } from '../graphQLTypes'
import { LocationTypeData } from '../types'
import { ReactComponent as ParkingIcon } from '../icons/deselected/icons-crowdenergy-158.svg'
import { ReactComponent as SocketIcon } from '../icons/deselected/icons-crowdenergy-161.svg'
import { ReactComponent as ChargingIcon } from '../icons/deselected/icons-crowdenergy-186.svg'

export enum LocationTypeIcons {
  PublicIcon = 'publicIcon',
  ResidentialIcon = 'residentialIcon',
  BusinessIcon = 'businessIcon',
}

export const locationTypeIcons = {
  [LocationTypeIcons.PublicIcon]: {
    enabled: ParkingIcon,
    disabled: ParkingIcon,
  },
  [LocationTypeIcons.ResidentialIcon]: {
    enabled: SocketIcon,
    disabled: SocketIcon,
  },
  [LocationTypeIcons.BusinessIcon]: {
    enabled: ChargingIcon,
    disabled: ChargingIcon,
  },
}

export const locationTypeData: LocationTypeData = {
  [POILocationType.PUBLIC_SPACE]: {
    label: POILocationType.PUBLIC_SPACE,
    displayName: 'Public',
    enabledIcon: locationTypeIcons[LocationTypeIcons.PublicIcon].enabled,
    disabledIcon: locationTypeIcons[LocationTypeIcons.PublicIcon].disabled,
  },
  [POILocationType.PRIVATE]: {
    label: POILocationType.PRIVATE,
    displayName: 'Private',
    enabledIcon: locationTypeIcons[LocationTypeIcons.ResidentialIcon].enabled,
    disabledIcon: locationTypeIcons[LocationTypeIcons.ResidentialIcon].disabled,
  },
  [POILocationType.RESIDENTIAL]: {
    label: POILocationType.RESIDENTIAL,
    displayName: 'Residential',
    enabledIcon: locationTypeIcons[LocationTypeIcons.ResidentialIcon].enabled,
    disabledIcon: locationTypeIcons[LocationTypeIcons.ResidentialIcon].disabled,
  },
  [POILocationType.BUSINESS]: {
    label: POILocationType.BUSINESS,
    displayName: 'Business',
    enabledIcon: locationTypeIcons[LocationTypeIcons.BusinessIcon].enabled,
    disabledIcon: locationTypeIcons[LocationTypeIcons.BusinessIcon].disabled,
  },
}
