import { IOSPopupIcons, iOSPopupIcons } from './icons'
import React from 'react'
import './IOSPopupContent.css'

export function IOSPopupContent() {
  return (
    <p>
      Tap Share{' '}
      <img src={iOSPopupIcons[IOSPopupIcons.ShareIcon]} className={'iOS-popup-content__icon'} alt="Share Icon" /> ,
      scroll down and tap 'Add to homescreen'{' '}
      <img src={iOSPopupIcons[IOSPopupIcons.PlusIcon]} className={'iOS-popup-content__icon'} alt="Plus Icon" />
    </p>
  )
}
