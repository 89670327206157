import { getArrayOfValuesFromDictionary, isItemSelected } from '../../../utils/common'
import { MenuSelectableIcon } from '../../MenuItems/MenuSelectableIcon'
import { MenuSelectable } from '../../MenuItems/MenuSelectable'
import React from 'react'
import { MenuSelectableConfig, MenuSelectableIconConfig } from '../../../types'

export function EnergySourceSelectable({
  energySources,
  selectedEnergySource,
  setSelectedEnergySource,
  config,
  itemsConfig,
}: {
  energySources: any
  selectedEnergySource?: string
  setSelectedEnergySource: Function
  config?: MenuSelectableConfig
  itemsConfig?: MenuSelectableIconConfig
}) {
  const isEnergySourceSelected = (energySource: string): boolean => isItemSelected(selectedEnergySource, energySource)

  return (
    <MenuSelectable config={config}>
      {energySources !== undefined &&
        getArrayOfValuesFromDictionary(energySources).map((energySource: any) => {
          return (
            <MenuSelectableIcon
              label={energySource.label}
              displayName={energySource.displayName}
              iconDeselected={energySource.iconDeselected}
              iconSelected={energySource.iconSelected}
              isSelected={() => isEnergySourceSelected(energySource.label)}
              onClick={() => setSelectedEnergySource(energySource.label)}
              config={itemsConfig}
            />
          )
        })}
    </MenuSelectable>
  )
}
