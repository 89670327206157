import { ReactComponent as ParkingIconSelected } from '../icons/selected/icons-crowdenergy-158-selected.svg'
import { ReactComponent as ParkingIcon } from '../icons/deselected/icons-crowdenergy-158.svg'
import { ReactComponent as ChargingIconSelected } from '../icons/selected/icons-crowdenergy-186-selected.svg'
import { ReactComponent as ChargingIcon } from '../icons/deselected/icons-crowdenergy-186.svg'
import { ReactComponent as SocketIconSelected } from '../icons/selected/icons-crowdenergy-161.svg'
import { ReactComponent as SocketIcon } from '../icons/deselected/icons-crowdenergy-161.svg'
import { ServiceTypeData } from '../types'
import { connectorOutletSmartSocketData } from './connectorOutletSmartSocketData'
import { smartSocketEnergySourceData } from './smartSocketEnergySourceData'
import { connectorOutletEVChargerData } from './connectorOutletEVChargerData'
import { ServiceType } from '../graphQLTypes'

export enum ServiceTypeIcons {
  ParkingIcon = 'parkingIcon',
  ChargingIcon = 'chargingIcon',
  SocketIcon = 'socketIcon',
}

export const serviceTypeIcons = {
  [ServiceTypeIcons.ParkingIcon]: {
    selected: ParkingIconSelected,
    deselected: ParkingIcon,
  },
  [ServiceTypeIcons.SocketIcon]: {
    selected: SocketIconSelected,
    deselected: SocketIcon,
  },
  [ServiceTypeIcons.ChargingIcon]: {
    selected: ChargingIconSelected,
    deselected: ChargingIcon,
  },
}

export const serviceTypeData: ServiceTypeData = {
  [ServiceType.PARKING]: {
    label: ServiceType.PARKING,
    displayName: 'Parking',
    iconSelected: serviceTypeIcons[ServiceTypeIcons.ParkingIcon].selected,
    iconDeselected: serviceTypeIcons[ServiceTypeIcons.ParkingIcon].deselected,
    hasPricePerKW: false,
  },
  [ServiceType.SOCKET]: {
    label: ServiceType.SOCKET,
    displayName: 'Smart Socket',
    iconSelected: serviceTypeIcons[ServiceTypeIcons.SocketIcon].selected,
    iconDeselected: serviceTypeIcons[ServiceTypeIcons.SocketIcon].deselected,
    energySources: smartSocketEnergySourceData,
    energySourcesMessageIdPostfix: 'energySource',
    connectors: connectorOutletSmartSocketData,
    hasPricePerKW: true,
  },
  [ServiceType.CHARGER]: {
    label: ServiceType.CHARGER,
    displayName: 'EV Charger',
    iconSelected: serviceTypeIcons[ServiceTypeIcons.ChargingIcon].selected,
    iconDeselected: serviceTypeIcons[ServiceTypeIcons.ChargingIcon].deselected,
    energySources: smartSocketEnergySourceData,
    energySourcesMessageIdPostfix: 'energySource',
    connectors: connectorOutletEVChargerData,
    hasPricePerKW: true,
  },
}
