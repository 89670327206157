import { Currency } from '../types'
import { currencyData } from '../data/currencyData'
import { balanceCardData } from '../data/balanceCardData'
import { CryptoCurrency, NonCryptoCurrency } from '../data/currencyTypes'

export const getCurrencyData = () => currencyData
export const getCurrency = (currency: Currency) => getCurrencyData()[currency]
export const getCurrencyLongname = (currency: Currency) => getCurrency(currency)?.longName!

export const getBalanceCardData = () => balanceCardData

export const isBitcoin = (label: Currency) => label === CryptoCurrency.BTC

const eurCurrencyFormatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })
const usdCurrencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

export const formatCurrency = (currency: string, amount: number) => {
  if (currency.localeCompare(NonCryptoCurrency.EUR, undefined, { sensitivity: 'accent' }) === 0) {
    return eurCurrencyFormatter.format(amount)
  }
  if (currency.localeCompare(NonCryptoCurrency.USD, undefined, { sensitivity: 'accent' }) === 0) {
    return usdCurrencyFormatter.format(amount)
  }
  return Number(amount).toFixed(2) + ' ' + currency
}
