import React from 'react'
import Icon from '@ant-design/icons'
import { serviceDetailIcons } from './icons'
import { Rate } from 'antd'
import './POIDetail.css'
import { getServiceDetailIcon } from '../../utils/service'
import { getZeroToFiveRatingFromZeroToTenRating } from '../../utils/common'
import { NearbyPOI, OtherService, ServiceType } from '../../graphQLTypes'
import { formatCurrency } from '../../utils/currency'
import { useNavigate } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { isAuthenticated } from '../../utils/auth'
import POIStatusIcon from './POIStatusIcon'
import { FormattedMessage } from 'react-intl'

export function price(service: OtherService): string {
  const price = formatCurrency(
    service.serviceSchedule.pricingProps.acceptedCurrency,
    service.serviceSchedule.pricingProps.pricePerUnit
  )
  return price + ' / ' + service.serviceSchedule.pricingProps.unitType
}

export function POIDetail({ pointOfInterest }: { pointOfInterest: NearbyPOI }) {
  const navigate = useNavigate()

  const { authStatus } = useAuthenticator((context) => [context.authStatus])

  const poiStatus = pointOfInterest?.poiStatus

  return (
    <div className={'service-detail'}>
      <div className={'service-detail__service-type'}>
        {pointOfInterest.serviceTypes.map((serviceType: ServiceType) => (
          <Icon key={serviceType} component={getServiceDetailIcon(serviceType)} className={'service-type__icon'} />
        ))}
        <Icon
          component={isAuthenticated(authStatus) ? serviceDetailIcons.unlocked : serviceDetailIcons.locked}
          className={'service-type__icon'}
        />
      </div>
      <div className={'service-detail__properties'}>
        <p className={'properties__service-name'}>{pointOfInterest.description}</p>
      </div>
      <div className="service-detail__properties-status">
        <POIStatusIcon poiStatus={poiStatus}></POIStatusIcon>{' '}
        <FormattedMessage id={'poiStatus.' + poiStatus} defaultMessage={poiStatus} />
      </div>

      <div className={'service-detail__startstop-button'}>
        <button
          className="ant-btn ant-btn-primary ant-btn-round service-detail__startstop-button__button"
          ant-click-animating-without-extra-node="false"
          onClick={() => navigate('/poi/' + encodeURIComponent(pointOfInterest.poiId))}
        >
          <span className="service-detail__startstop-button__text">Select</span>
        </button>
      </div>
      <div className={'service-detail__rating'}>
        <Rate
          className={'rating__stars'}
          value={getZeroToFiveRatingFromZeroToTenRating(pointOfInterest.rating)}
          disabled={true}
        />
        <span className={'rating__votes'}>(42)</span>
      </div>
    </div>
  )
}
