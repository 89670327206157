import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Marker, useMapEvents } from 'react-leaflet'
import { getMapboxGLConfig } from '../../../../utils/config'
import L, { LatLng, Marker as LeafletMarker } from 'leaflet'
import geohash from 'ngeohash'
import { getPOIMarker } from '../../../../utils/service'
import { ProfileType } from '../../../../graphQLTypes'

export function MapContent({
  setGeohash,
  poiType,
}: {
  setGeohash: (a: string, b: LatLng) => void
  poiType: ProfileType
}) {
  const map = useMapEvents({})
  const poiMarker = getPOIMarker(poiType)
  const [position, setPosition] = useState(map.getCenter())
  const markerRef = useRef<LeafletMarker>(null)
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          const latLng = marker.getLatLng()
          const ge = geohash.encode(latLng.lat, latLng.lng, 10) // default pricision is 9, but found that 10 is even better ;-)
          setGeohash(ge, latLng)
          const gd = geohash.decode(ge)
          setPosition(new LatLng(gd.latitude, gd.longitude)) // will use geohash precision here (so the client really know what he get)
        }
      },
    }),
    [setGeohash]
  )

  useEffect(() => {
    L.maplibreGL(getMapboxGLConfig()).addTo(map)

    // on map initialization set geohash to current position
    const pos = map.getCenter()
    setGeohash(geohash.encode(pos.lat, pos.lng, 10), pos)
  }, [map])

  return (
    <Marker
      draggable={true}
      autoPan={true}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={poiMarker}
    ></Marker>
  )
}
