function isAlreadyInstalled() {
  // @ts-ignore
  return window.navigator.standalone !== undefined && window.navigator.standalone
}

function isDeviceIOS(userAgent: any) {
  const isIPad = !!userAgent.match(/iPad/i)
  const isIPhone = !!userAgent.match(/iPhone/i)
  return isIPad || isIPhone
}

export function isIOS() {
  if (isAlreadyInstalled()) return false
  const userAgent = window.navigator.userAgent
  const isWebkit = !!userAgent.match(/WebKit/i)
  const isIOS = isDeviceIOS(userAgent)
  const isSafari = isIOS && isWebkit && !userAgent.match(/CriOS/i)
  return isIOS && isSafari
}
