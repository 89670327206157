import Icon from '@ant-design/icons'
import React from 'react'
import { GeneralPOI, GeneralService } from '../../graphQLTypes'
import { getServiceDetailIcon } from '../../utils/service'
import { POIServiceSocket } from './POIServiceSocket'
import ServiceStatusIcon from '../Menu/POI/ServiceMenu/ServiceStatusIcon'

export function POIServiceName({ poi, service }: { poi: GeneralPOI; service: GeneralService }) {
  return (
    <div className="poiService__box__name">
      <div className={'poi__poiServiceIconspoi__poiServiceIcons_container'}>
        <span className="poi__poiServiceIcons">
          <Icon className={'service-type__icon'} component={getServiceDetailIcon(service.serviceType)} />
        </span>
        <POIServiceSocket service={service} />
      </div>
      <h3 className="poiService__header">
        <ServiceStatusIcon serviceStatus={service.serviceStatus}></ServiceStatusIcon> {service.serviceName}
      </h3>
    </div>
  )
}
