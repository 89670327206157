import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button } from 'antd'
import { MQTTContext } from '../../store/mqttContext'
import { useApolloClient, useMutation } from '@apollo/client'
import { SERVICE_START } from '../../mutations'
import { GET_POI } from '../../queries'
import { Loader } from '@aws-amplify/ui-react'
import { FormattedMessage } from 'react-intl'
import './POIServiceActionStart.css'
import { POIActivationProperties } from './POI'
import { POIActivationType } from '../../data/activationTypeData'

interface POIServiceActionStartProps {
  poiId: string
  serviceId: string
  activationProperties: POIActivationProperties
}

export default function POIServiceActionStart({
  poiId,
  serviceId,
  activationProperties,
}: Readonly<POIServiceActionStartProps>) {
  const mqttCtx = useContext(MQTTContext)
  const apolloClient = useApolloClient()

  // changing over time with loading indicator
  const [progress, setProgress] = useState<string | undefined>()

  // will be displayed in red when something goes wrong
  const [error, setError] = useState<string | undefined>()

  // session id returned from API, later used in MQTT communication
  const [sessionId, setSessionId] = useState<string | undefined>()

  const setErrorMessage = (message: string) => {
    setProgress(undefined)
    setError(message)
  }

  // mutation
  const [serviceStartMutation] = useMutation(SERVICE_START, {
    onError: () => {
      setErrorMessage('Sending of start command ended with error!')
      mqttCtx.setListen(false)
    },
    onCompleted: (data) => {
      setProgress('Waiting for device start confirmation')
      setSessionId(data.serviceStart.sessionId)
    },
  })

  // onclick handler
  const handleStart = () => {
    mqttCtx.setListen(true)
    setError(undefined) // reset errror - new try
    setProgress('Sending start to device')

    // pass activation properties

    serviceStartMutation({
      variables: {
        input: {
          poiId,
          serviceId,
          activeUntilPrice:
            activationProperties.type === POIActivationType.ACTIVE_UNTIL_PRICE ? activationProperties.price : null,
          activeUntilTime:
            activationProperties.type === POIActivationType.ACTIVE_UNTIL_TIME ? activationProperties.time.unix() : null,
        },
      },
    })
  }

  // called when MQTT message arrives
  useEffect(() => {
    if (sessionId && mqttCtx.startedSessions.includes(sessionId)) {
      setProgress('Successfully started')
      // intentionaly keeping MQTT connection open
      apolloClient.refetchQueries({
        include: [GET_POI],
      })
    }
  }, [apolloClient, sessionId, mqttCtx.startedSessions])

  return (
    <div>
      {!progress && (
        <Button type="primary" onClick={handleStart}>
          START Service
        </Button>
      )}

      {progress && (
        <div className="service_start__loader">
          <Loader fr={undefined} size="large" /> <span className="service_start__loader-text"> {progress}</span> ...
        </div>
      )}

      {error && <Alert message={<FormattedMessage id={error} defaultMessage={error} />} type="error" />}
    </div>
  )
}
