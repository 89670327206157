import React, { useContext, useEffect, useState } from 'react'
import { Circle, CircleMarker } from 'react-leaflet'
import { UserStateContext } from '../../store/userContext'

/**
 * This components render current position (big blue circle with accuracy)
 */
export function MapCurrentPosition() {
  // we use state.refreshUserLocation flag - only when true current position is visible
  const { state } = useContext(UserStateContext)

  // flag indicating if we show current possigion (but only if available)
  const [position, setPosition] = useState({
    lat: 0,
    lng: 0,
    radius: 0,
  })

  const updatePosition = (position: any) => {
    setPosition({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
      radius: position.coords.accuracy,
    })
  }

  // we will refresh it every ...
  useEffect(() => {
    // execute only if client is requesting this
    if (state.refreshUserLocation) {
      // and only in case it's available in the browser
      if (navigator.geolocation) {
        // in this case, fetch the position immediately, then in period of 5 seconds
        navigator.geolocation.getCurrentPosition(updatePosition)
        const interval = setInterval(() => navigator.geolocation.getCurrentPosition(updatePosition), 5000)
        return () => clearInterval(interval)
      }
    }
  }, [state.refreshUserLocation])

  return (
    <React.Fragment>
      {state.refreshUserLocation && (
        <React.Fragment>
          <Circle
            fillOpacity={0.1}
            stroke={false}
            center={{ lat: position.lat, lng: position.lng }}
            pathOptions={{ fillColor: 'blue' }}
            radius={position.radius}
          />
          <CircleMarker
            center={{ lat: position.lat, lng: position.lng }}
            pathOptions={{ color: '#ffffff' }}
            fillOpacity={0.8}
            fillColor="#0f79cf"
            opacity={1}
            radius={8}
          ></CircleMarker>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
