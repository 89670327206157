import React, { useContext, useEffect, useState } from 'react'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { mapConstants } from '../../data/constants'
import { FilterTypes } from '../../graphQLTypes'
import { Dictionary, HistoryCoordinates } from '../../types'
import './Map.css'
import { MapContent } from './MapContent'
import { UserLocation, UserStateContext } from '../../store/userContext'
import { MapCurrentPosition } from './MapCurrentPosition'

const RecenterMap = ({ location }: { location: UserLocation }) => {
  const map = useMap()
  useEffect(() => {
    map.setView([Number(location.lat), Number(location.lng)], 30)
  }, [location])
  return null
}

function Map({ serviceFilters }: { serviceFilters: Dictionary<FilterTypes, boolean> }) {
  // stored on user level
  const { state } = useContext(UserStateContext)

  // current map location (center)
  const [location, setLocation] = useState<UserLocation>(state.userLocation!)

  // on load set location to last stored one
  useEffect(() => {
    setLocation({
      lng: Number(localStorage.getItem(HistoryCoordinates.LNG))
        ? Number(localStorage.getItem(HistoryCoordinates.LNG))
        : location.lng,
      lat: Number(localStorage.getItem(HistoryCoordinates.LAT))
        ? Number(localStorage.getItem(HistoryCoordinates.LAT))
        : location.lat,
    })
  }, [])

  // we use this global state to move map to our service location
  useEffect(() => {
    if (state.lastServiceLocation !== undefined) {
      setLocation({
        lng: state.lastServiceLocation?.lng,
        lat: state.lastServiceLocation?.lat,
      })
    }
  }, [state.lastServiceLocation])

  return (
    <div className={'map'}>
      <MapContainer
        center={[Number(location.lat), Number(location.lng)]}
        zoom={mapConstants.defaultZoom}
        scrollWheelZoom={true}
        className={'map__map-container'}
        attributionControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="xxx-not-used-xxx"
          crossOrigin={true}
        />
        <MapContent serviceFilters={serviceFilters} />
        <RecenterMap location={location} />
        <MapCurrentPosition />
      </MapContainer>
    </div>
  )
}

export default Map
