import { useMutation } from '@apollo/client'
import { Alert } from 'antd'
import React, { useContext, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { DELETE_SERVICE } from '../../../../mutations'
import { GET_MY_POIS } from '../../../../queries'
import { UserStateContext } from '../../../../store/userContext'
import { MenuBackButton } from '../../../MenuItems/MenuBackButton'
import { MenuItem } from '../../../MenuItems/MenuItem'

export function ServiceMenuDelete() {
  const navigate = useNavigate()
  const params = useParams()
  const { state } = useContext(UserStateContext)

  // mutation for adding POI (with service)
  const [deleteService, deleteServiceResult] = useMutation(DELETE_SERVICE, {
    refetchQueries: [GET_MY_POIS],
    awaitRefetchQueries: true,
  })

  // extract selected POI
  const poi = state.pois!.find((x) => x.poiId === params.poiId)
  const service = poi?.services.find((x) => x.serviceId === params.serviceId)

  useEffect(() => {
    if (poi == null) {
      navigate('/settings/pois/')
    }
    if (service == null) {
      navigate('/settings/pois/poi/' + encodeURIComponent(poi!.poiId))
    }
  })

  const navigateToPOI = () => navigate('/settings/pois/poi/' + encodeURIComponent(poi!.poiId))
  const navigateToService = () =>
    navigate('/settings/pois/service/' + encodeURIComponent(poi!.poiId) + '/' + encodeURIComponent(service!.serviceId))

  const onYes = () => {
    deleteService({
      variables: {
        input: {
          poiId: poi?.poiId,
          serviceId: service?.serviceId,
        },
      },
    }).then(() => navigateToPOI())
  }

  return (
    <div>
      <MenuBackButton onClick={navigateToService} />

      <MenuItem label="Service" config={{ isNavigable: false }}>
        {service?.serviceName}
      </MenuItem>

      <div style={{ margin: '1rem 0' }}>
        <Alert message="Are You sure You want to delete this service?" type="warning"></Alert>
      </div>

      {deleteServiceResult.loading && <p>Deleting ...</p>}
      {deleteServiceResult.error && <p>{deleteServiceResult.error.message}</p>}
      {!deleteServiceResult.loading && (
        <div>
          <MenuItem label="Yes" onClick={onYes} config={{ isNavigable: true, isSubItem: true }} />
          <MenuItem label="No" onClick={navigateToService} config={{ isNavigable: true, isSubItem: true }} />
        </div>
      )}
    </div>
  )
}
