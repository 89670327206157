import React, { useEffect } from 'react'
import { amplifyConfig } from './awsConfig'
import { Amplify } from 'aws-amplify'
import { UserStateContextProvider } from './store/userContext'
import { ServiceStateContextProvider } from './store/markerContext'
import { AppWithAuth } from './components/AppWithAuth'
import { displayOrientationWarningOnOrientationChange } from './utils/common'
import { ConfigProvider } from 'antd'

// so we got consistent look in every browser
import 'antd/dist/reset.css'
import MQTTContextProvider from './store/mqttContext'

Amplify.configure(amplifyConfig)

function App() {
  useEffect(() => displayOrientationWarningOnOrientationChange(), [])

  const theme = {
    token: {
      colorPrimary: '#fcac04',
    },
  }

  return (
    <ConfigProvider theme={theme}>
      <ServiceStateContextProvider>
        <UserStateContextProvider>
          <MQTTContextProvider>
            <AppWithAuth />
          </MQTTContextProvider>
        </UserStateContextProvider>
      </ServiceStateContextProvider>
    </ConfigProvider>
  )
}

export default App
