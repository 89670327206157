import { ReactComponent as ParkingIcon } from '../icons/deselected/icons-crowdenergy-158.svg'
import { ReactComponent as ChargingIcon } from '../icons/deselected/icons-crowdenergy-186.svg'
import { ReactComponent as SocketIcon } from '../icons/deselected/icons-crowdenergy-161.svg'
import { ServiceType } from '../graphQLTypes'

export const serviceIcons = {
  [ServiceType.PARKING]: ParkingIcon,
  [ServiceType.CHARGER]: ChargingIcon,
  [ServiceType.SOCKET]: SocketIcon,
}
