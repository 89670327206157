import { ReactComponent as ParkingIcon } from '../icons/deselected/icons-crowdenergy-158.svg'
import { ReactComponent as SocketIcon } from '../icons/deselected/icons-crowdenergy-161.svg'
import { ReactComponent as ChargingIcon } from '../icons/deselected/icons-crowdenergy-186.svg'
import { BusinessTypeData } from '../types'
import { POIType } from '../graphQLTypes'

export enum BusinessTypeIcons {
  BusinessIcon = 'businessIcon',
  IndividualIcon = 'individualIcon',
  DemandIcon = 'demandIcon',
}

export const businesTypeIcons = {
  [BusinessTypeIcons.BusinessIcon]: {
    enabled: ParkingIcon,
    disabled: ParkingIcon,
  },
  [BusinessTypeIcons.IndividualIcon]: {
    enabled: SocketIcon,
    disabled: SocketIcon,
  },
  [BusinessTypeIcons.DemandIcon]: {
    enabled: ChargingIcon,
    disabled: ChargingIcon,
  },
}

export const businessTypeData: BusinessTypeData = {
  [POIType.BUSINESS]: {
    label: POIType.BUSINESS,
    displayName: 'Business',
    enabledIcon: businesTypeIcons[BusinessTypeIcons.BusinessIcon].enabled,
    disabledIcon: businesTypeIcons[BusinessTypeIcons.BusinessIcon].disabled,
  },
  [POIType.INDIVIDUAL]: {
    label: POIType.INDIVIDUAL,
    displayName: 'Individual',
    enabledIcon: businesTypeIcons[BusinessTypeIcons.IndividualIcon].enabled,
    disabledIcon: businesTypeIcons[BusinessTypeIcons.IndividualIcon].disabled,
  },
  [POIType.DEMAND]: {
    label: POIType.DEMAND,
    displayName: 'Demand',
    enabledIcon: businesTypeIcons[BusinessTypeIcons.DemandIcon].enabled,
    disabledIcon: businesTypeIcons[BusinessTypeIcons.DemandIcon].disabled,
  },
}
