import { ReactNode, createContext, useReducer } from 'react'
import { AcceptedCurrency, OtherPOI, OtherService } from '../graphQLTypes'

enum ActionType {
  SET_PAYIN_SERVICE_CURRENCY = 'SET_PAYIN_SERVICE_CURRENCY',
  ADD_SERVICE = 'ADD_SERVICE',
}

type Action =
  | { type: ActionType.SET_PAYIN_SERVICE_CURRENCY; payload: AcceptedCurrency }
  | { type: ActionType.ADD_SERVICE; payload: OtherPOI | undefined }

export interface MarkerState {
  otherPOI?: OtherPOI
}

const markerInitialState = {
  otherPOI: undefined,
}

export const ServiceStateContext = createContext<{
  stateMarker: MarkerState
  setPayInServiceCurrency: (payload: AcceptedCurrency) => void
  addService: (payload: OtherPOI | undefined) => void
}>({
  stateMarker: markerInitialState,
  setPayInServiceCurrency: () => null,
  addService: () => null,
})

function serviceReducer(prevState: MarkerState, action: Action): MarkerState {
  switch (action.type) {
    case ActionType.SET_PAYIN_SERVICE_CURRENCY: {
      let array = Array<OtherService>()
      let state = { ...prevState }
      state.otherPOI!.services.map((o) => {
        let y = {
          ...o,
          serviceSchedule: {
            ...o.serviceSchedule,
            pricingProps: {
              ...o.serviceSchedule.pricingProps,
              acceptedCurrency: action.payload,
            },
          },
        }
        array.push(y)
      })

      let correctedState = {
        ...prevState,
        otherPOI: {
          ...prevState.otherPOI!,
          services: array,
        },
      }
      return correctedState
    }
    case ActionType.ADD_SERVICE: {
      return {
        ...prevState,
        otherPOI: action.payload,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`)
    }
  }
}

export const ServiceStateContextProvider = ({ children }: { children?: ReactNode }) => {
  const [state, dispatch] = useReducer(serviceReducer, markerInitialState)

  const setPayInServiceCurrency = (payload: AcceptedCurrency) => {
    dispatch({ type: ActionType.SET_PAYIN_SERVICE_CURRENCY, payload: payload })
  }

  const addService = (payload: OtherPOI | undefined) => {
    dispatch({ type: ActionType.ADD_SERVICE, payload: payload })
  }

  const stateMarkerState = {
    stateMarker: state,
    setPayInServiceCurrency,
    addService,
  }

  return <ServiceStateContext.Provider value={stateMarkerState}>{children}</ServiceStateContext.Provider>
}
