import React from 'react'
import { Button, Slider } from 'antd'
import './MenuSlider.css'
import { MenuSliderProps } from '../../types'
import { cutFractionDigits } from '../../utils/common'

const isPlusButtonDisabled = (value: number, max?: number): boolean => max !== undefined && value >= max

const isMinusButtonDisabled = (value: number, min?: number): boolean => min !== undefined && value <= min

const handleOnClickPlusButton = (setValue: Function, value: number, max?: number, step?: number) =>
  setValue(max === undefined || value < max ? cutFractionDigits(value + step!) : value)

const handleOnClickMinusButton = (setValue: Function, value: number, min?: number, step?: number) =>
  setValue(min === undefined || value > min ? cutFractionDigits(value - step!) : value)

export function MenuSlider({ value, setValue, config }: MenuSliderProps) {
  const { isSubItem, isVisible, min, max, step, defaultValue } = config || {}

  return isVisible ? (
    <div className={`menu-slider ${isSubItem ? 'menu-slider--sub-item' : ''}`}>
      <Button
        disabled={isMinusButtonDisabled(value, min)}
        shape={'round'}
        className={'menu-slider__button'}
        onClick={() => handleOnClickMinusButton(setValue, value, min, step)}
      >
        <p className={'menu-slider__button__text'}>-</p>
      </Button>
      <Slider
        min={min !== undefined ? min : 0}
        max={max !== undefined ? max : 100}
        step={step !== undefined ? step : 1}
        trackStyle={{ backgroundColor: '#fcac04' }}
        handleStyle={{ borderColor: '#fcac04' }}
        style={{ color: '#fcac04' }}
        className={'menu-slider__slider'}
        defaultValue={defaultValue !== undefined ? defaultValue : 0}
        onChange={(value: number) => setValue(value)}
        value={value}
      />
      <Button
        disabled={isPlusButtonDisabled(value, max)}
        shape={'round'}
        className={'menu-slider__button'}
        onClick={() => handleOnClickPlusButton(setValue, value, max, step)}
      >
        <p className={'menu-slider__button__text'}>+</p>
      </Button>
    </div>
  ) : null
}
