import {
  ActivableBottomMenuIcons,
  ActivableMenuIcons,
  ActivableTopMenuIcons,
  FilterMenuIcons,
  topMenuIcons,
  topMenuSettingsIcons,
  topMenuSettingsIconsAuthenticated,
} from '../components/Map/icons'
import { isValueInEnum } from './common'
import { CryptoCurrency, NonCryptoCurrency } from '../data/currencyTypes'
import { cartData } from '../data/cartData'
import { balanceCardData } from '../data/balanceCardData'

export const isFilterIcon = (iconName: string): boolean => isValueInEnum(iconName, FilterMenuIcons)

export const isActivableMenuIcon = (iconName: string): boolean =>
  isValueInEnum(iconName, ActivableTopMenuIcons) || isValueInEnum(iconName, ActivableBottomMenuIcons)

export const isFilterIconSelected = (serviceFilters: any, iconName: string): boolean => serviceFilters?.[iconName][0]

export const getTopMenuSettingsIcons = (isAuthenticated: boolean) =>
  isAuthenticated ? topMenuSettingsIconsAuthenticated : topMenuSettingsIcons

export const getTopMenuIcons = (areSettingsDisplayed: boolean, isAuthenticated: boolean) =>
  areSettingsDisplayed ? getTopMenuSettingsIcons(isAuthenticated) : topMenuIcons

export const isIconSelected = (serviceFilters: any, iconName: string, activeIconName?: ActivableMenuIcons): boolean =>
  (isFilterIcon(iconName) && isFilterIconSelected(serviceFilters, iconName)) ||
  (isActivableMenuIcon(iconName) && activeIconName !== undefined && activeIconName === iconName)

export const getBalanceCardIcon = (selectedCurrency: NonCryptoCurrency, isBitcoinSelected: boolean) =>
  isBitcoinSelected
    ? balanceCardData[CryptoCurrency.BTC]?.iconDeselected!
    : balanceCardData[selectedCurrency]?.iconDeselected!

export const getCartIcon = (isDepositButtonDisabled: boolean) =>
  isDepositButtonDisabled ? cartData.emptyCard?.iconDeselected! : cartData.fullCart?.iconDeselected!
