import { ReactComponent as RadioButtonOff } from '../icons/radio-button-off.svg'
import { ReactComponent as RadioButtonOn } from '../icons/radio-button-on.svg'
import { Dictionary } from '../types'
import { SVGIcon } from '../components/Map/icons'

export enum POIPaymentType {
  CREDIT = 'CREDIT',
  PAYPAL = 'PAYPAL',
}

export type PaymentMethodTypeData = Dictionary<
  POIPaymentType,
  {
    label: POIPaymentType
    displayName: string
    selected: SVGIcon
    deselected: SVGIcon
  }
>

export enum paymentMethodTypeData {
  CreditBalance = 'credit',
  PaypalBalance = 'paypal',
}

export const paymentMethodTypeIcons = {
  [paymentMethodTypeData.PaypalBalance]: {
    selected: RadioButtonOn,
    deselected: RadioButtonOff,
  },
  [paymentMethodTypeData.CreditBalance]: {
    selected: RadioButtonOn,
    deselected: RadioButtonOff,
  },
}

export const paymentTypeData: PaymentMethodTypeData = {
  [POIPaymentType.PAYPAL]: {
    label: POIPaymentType.PAYPAL,
    displayName: 'PayPal',
    selected: paymentMethodTypeIcons[paymentMethodTypeData.PaypalBalance].selected,
    deselected: paymentMethodTypeIcons[paymentMethodTypeData.PaypalBalance].deselected,
  },
  [POIPaymentType.CREDIT]: {
    label: POIPaymentType.CREDIT,
    displayName: 'Credit',
    selected: paymentMethodTypeIcons[paymentMethodTypeData.CreditBalance].selected,
    deselected: paymentMethodTypeIcons[paymentMethodTypeData.CreditBalance].deselected,
  },
}
