import { addServiceMenuConfig } from '../data/menuConfig/addServiceMenuConfig'

export const getMapboxGLConfig = () => {
  const apiKey = process.env.REACT_APP_MAPTILER_KEY || ''
  return {
    attribution:
      'Crowd Energy \u003ca href="https://www.maptiler.com/copyright/" target="_blank"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href="https://www.openstreetmap.org/copyright" target="_blank"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e',
    style: `https://api.maptiler.com/maps/a0d792cf-5a85-4ced-a729-19fdbae73fe6/style.json?key=${apiKey}`,
  }
}

// TODO: Replace for server fetch
export const getAddServiceMenuConfig = () => {
  return addServiceMenuConfig
}
