import L from 'leaflet'
import { generateOptions } from '../utils/markers'
import demandPOIMarkerIcon from '../icons/map-marker/icons-crowdenergy-209.svg'
import businessPOIMarkerIcon from '../icons/map-marker/icons-crowdenergy-350.svg'
import individualPOIMarkerIcon from '../icons/map-marker/icons-crowdenergy-351.svg'
import { POIType, ProfileType } from '../graphQLTypes'

export const demandPOIMarker = new L.Icon(generateOptions(demandPOIMarkerIcon))
export const businessPOIMarker = new L.Icon(generateOptions(businessPOIMarkerIcon))
export const individualPOIMarker = new L.Icon(generateOptions(individualPOIMarkerIcon))

export const poiMarkers = {
  [POIType.DEMAND]: demandPOIMarker,
  [POIType.BUSINESS]: businessPOIMarker,
  [POIType.INDIVIDUAL]: individualPOIMarker,
  [ProfileType.BUSINESS]: businessPOIMarker,
  [ProfileType.INDIVIDUAL]: individualPOIMarker,
}
