import React from 'react'
import './Menu.css'
import { Icon } from './Icon'
import { ActivableMenuIcons, IconLabel, Icons } from './icons'
import { Dictionary } from '../../types'
import { determineActionsByIconName, getServiceFilterSetterByIconName } from '../../utils/service'
import { isIconSelected } from '../../utils/icons'

export enum MenuType {
  Horizontal = 'menu--horizontal',
  Vertical = 'menu--vertical',
}

export function Menu({
  icons,
  type,
  className,
  iconClassName,
  serviceFilters,
  activeMainIconName,
  changeActiveMainIcon,
  onClickHandlers,
}: {
  icons: Icons[]
  type: MenuType
  className?: string
  iconClassName?: string
  serviceFilters?: any
  activeMainIconName?: ActivableMenuIcons
  changeActiveMainIcon: Function
  onClickHandlers?: Dictionary<IconLabel, Function>
}) {
  return (
    <div className={`menu ${type} ${className}`}>
      {icons.map((iconPair: Icons, index: number) => {
        return (
          <Icon
            key={index}
            selectedIcon={iconPair[0]}
            deselectedIcon={iconPair[1]}
            className={`${iconClassName}`}
            onClickHandlers={determineActionsByIconName(
              iconPair[2],
              () => changeActiveMainIcon(iconPair[2]),
              getServiceFilterSetterByIconName(serviceFilters, iconPair[2]),
              onClickHandlers
            )}
            isSelected={isIconSelected(serviceFilters, iconPair[2], activeMainIconName)}
          />
        )
      })}
    </div>
  )
}
