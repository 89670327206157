import Icon from '@ant-design/icons'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { GeneralPOI } from '../../graphQLTypes'
import { ReactComponent as IconDown } from '../../icons/arrow-down.svg'
import { ReactComponent as IconUp } from '../../icons/arrow-up.svg'
import './POI.css'

export function POIAccessability({ poi }: { poi: GeneralPOI | undefined }) {
  const [isAreaSelected, setIsAreaSelected] = useState<boolean>(false)

  return (
    <div className={'accessibility_main_container'}>
      <div className={'accessibility_top_container'}>
        <div>
          <h2>
            <FormattedMessage id={'poi.accessibility'} />
          </h2>
        </div>
        <div className={'accessibility_top_container_hide_link'}>
          <a
            onClick={() => {
              setIsAreaSelected(!isAreaSelected)
            }}
          >
            <Icon
              style={{
                color: 'black',
              }}
              component={isAreaSelected ? IconUp : IconDown}
            />
          </a>
        </div>
      </div>
      <div
        style={{
          display: isAreaSelected ? 'block' : 'none',
        }}
      >
        <div className={'poi__accessability'}>
          <div className={'poi__accessability_item'}>
            <FormattedMessage id={'poi.locationarea'} />
          </div>
          <span>
            <FormattedMessage id={'poi.locationType.' + poi!.locationType} />
            {}
          </span>
          {/* 
          <Icon
            className={'service-type__icon'}
            component={getLocationDetailIcon(poi!.locationType || POILocationType.PUBLIC_SPACE)}
          />
          */}
        </div>
        <div className={'poi__accessability'}>
          <div className={'poi__accessability_item'}>
            <FormattedMessage id={'poi.access'} />
          </div>
          <span>
            <FormattedMessage id={'poi.access.' + poi!.access} />
            {}
          </span>
          {/*
          <Icon
            className={'service-type__icon'}
            component={getAccessDetailIcon(poi!.access || POIAccess.UNRESTRICTED)}
          />
          */}
        </div>
        <div className={'poi__accessability'}>
          <div className={'poi__accessability_item'}>
            <FormattedMessage id={'poi.serviceprovider'} />
          </div>
          <span>
            <FormattedMessage id={'poi.type.' + poi!.poiType} />
            {}
          </span>

          {/*
          <Icon
            className={'service-type__icon'}
            component={getBusinessDetailIcon(poi!.poiType || POIType.BUSINESS)}
          />
          */}
        </div>
      </div>
    </div>
  )
}
