import React from 'react'
import { GeneralService, ServiceType } from '../../graphQLTypes'
import Icon from '@ant-design/icons'
import { getConnectorsDataByServiceType } from '../../utils/service'

export function POIServiceSocket({ service }: { service: GeneralService }) {
  const getIcon = () => {
    // fetch defined connectors for this service type
    const connectors = getConnectorsDataByServiceType(service.serviceType)
    if (connectors == null) {
      return undefined
    }
    if (service.serviceType === ServiceType.SOCKET) {
      const icon = connectors[service.socketServiceProps.socketConnectorType]
      if (icon != null) {
        return icon.iconDeselected
      }
    }
    if (service.serviceType === ServiceType.CHARGER) {
      const icon = connectors[service.chargerServiceProps.chargerConnectorType]
      if (icon != null) {
        return icon.iconDeselected
      }
    }
  }

  const icon = getIcon()

  return (
    <span className="poi__poiServiceIcons">{icon && <Icon className={'service-type__icon'} component={icon} />}</span>
  )
}
