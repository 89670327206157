import { CartData } from '../types'
import { ReactComponent as EmptyCart } from '../icons/gift-card-icons/icons-crowdenergy-307.svg'
import { ReactComponent as FullCart } from '../icons/gift-card-icons/icons-crowdenergy-309.svg'

export enum CartIcons {
  EmptyCart = 'emptyCard',
  FullCart = 'fullCart',
}

export const cartIcons = {
  [CartIcons.EmptyCart]: {
    deselected: EmptyCart,
  },
  [CartIcons.FullCart]: {
    deselected: FullCart,
  },
}

export const cartData: CartData = {
  [CartIcons.EmptyCart]: {
    label: CartIcons.EmptyCart,
    iconDeselected: cartIcons[CartIcons.EmptyCart].deselected,
  },
  [CartIcons.FullCart]: {
    label: CartIcons.FullCart,
    iconDeselected: cartIcons[CartIcons.FullCart].deselected,
  },
}
