import { Dictionary } from '../types'
import { LatLngTuple } from 'leaflet'
import { IconLabel, SVGIcon } from '../components/Map/icons'
import { isActivableMenuIcon, isFilterIcon } from './icons'
import { getArrayFromDictionary } from './common'
import { serviceTypeData } from '../data/serviceTypeData'
import { FilterTypes, POIAccess, POILocationType, POIType, ProfileType, ServiceType } from '../graphQLTypes'
import { serviceIcons } from '../data/serviceIcons'
import { poiMarkers } from '../data/poiMarkers'
import { locationTypeData } from '../data/locationTypeData'
import { accessTypeData } from '../data/accessTypeData'
import { businessTypeData } from '../data/businessTypeData'
import { paymentMethodTypeData, paymentMethodTypeIcons } from '../data/paymentMethodTypeData'
import { activationMethodTypeIcons, POIActivationType } from '../data/activationTypeData'

export const isPOITypeDemand = (poiType: POIType) => poiType === POIType.DEMAND

export const getActiveServiceFilters = (serviceFilters: Dictionary<FilterTypes, boolean>): FilterTypes[] => {
  return getArrayFromDictionary(serviceFilters)
    .filter((entry) => entry[1])
    .map((entry) => entry[0]) as any
}

export const getServiceIcon = (serviceType: ServiceType): SVGIcon => {
  return serviceIcons[serviceType]
}

export const getServiceFilterSetterByIconName = (serviceFilters: any, iconName: string) =>
  serviceFilters?.[iconName]?.[1]

export const isDemandFilterActive = (serviceFilters: Dictionary<FilterTypes, boolean>): boolean =>
  serviceFilters.demand!

export const isCircleDisplayed = (
  serviceFilters: Dictionary<FilterTypes, boolean>,
  circleParameters?: { center: LatLngTuple; radius: number }
) => circleParameters !== undefined && isDemandFilterActive(serviceFilters)

export const determineActionsByIconName = (
  iconName: IconLabel,
  changeMainIconName: Function,
  handleFilterIconClick: Function,
  onClickHandlers?: Dictionary<IconLabel, Function>
): [Function?, Function?] => {
  let action
  if (isActivableMenuIcon(iconName)) action = changeMainIconName
  else if (isFilterIcon(iconName)) action = handleFilterIconClick
  else action = undefined
  const additionalAction = onClickHandlers?.[iconName]
  return [action, additionalAction]
}

export const getServiceTypeData = () => serviceTypeData

export const getServiceData = (serviceType?: ServiceType) => {
  return serviceType !== undefined ? serviceTypeData?.[serviceType] : undefined
}
export const getEnergySourceDataByServiceType = (serviceType?: ServiceType) => {
  const serviceData = getServiceData(serviceType)
  return serviceData?.energySources
}

export const getConnectorsDataByServiceType = (serviceType?: ServiceType) => {
  const serviceData = getServiceData(serviceType)
  return serviceData?.connectors
}

export const determineIfServiceHasPriceByKW = (serviceType?: ServiceType): boolean => {
  if (serviceType === undefined) return false
  return serviceTypeData[serviceType]!.hasPricePerKW!
}

export const getSelectedIconByServiceType = (serviceType?: ServiceType) => {
  const serviceData = getServiceData(serviceType)
  return serviceData?.iconSelected
}
export const getPOIMarker = (poiType: POIType | ProfileType) => {
  return poiMarkers[poiType]
}

export const getServiceDetailIcon = (serviceType: ServiceType) => {
  return serviceTypeData[serviceType]?.iconDeselected
}

export const getLocationDetailIcon = (locationType: POILocationType) => {
  return locationTypeData[locationType]?.enabledIcon
}

export const getAccessDetailIcon = (accessType: POIAccess) => {
  return accessTypeData[accessType]?.enabledIcon
}

export const getBusinessDetailIcon = (businessType: POIType) => {
  return businessTypeData[businessType]?.enabledIcon
}

export const getPaymentMethodIcon = (paymentType: paymentMethodTypeData) => {
  return paymentMethodTypeIcons[paymentType]
}

export const getActivationMethodIcon = (activationType: POIActivationType) => {
  return activationMethodTypeIcons[activationType]
}
