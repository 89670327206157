import Icon from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { GET_MY_PROFILE } from '../../queries'
import { UserStateContext } from '../../store/userContext'
import { formatCurrency } from '../../utils/currency'
import { SettingsIcons, settingsIcons } from '../Map/icons'
import QueryResult from '../QueryResult'
import './UserInfo.css'
import { Tag } from 'antd'
import { AcceptedCurrency } from '../../graphQLTypes'

function BalanceTag({ currency, amount }: Readonly<{ currency: string; amount: number }>) {
  if (amount === undefined) {
    return <></>
  }
  return (
    <Tag color="#87d068">
      <strong>{formatCurrency(currency, amount)}</strong>
    </Tag>
  )
}

export function UserInfo() {
  // this is a bit a hack, i retrieve data via apollo and then store it insice user context via dispatch
  const { loading, error, data } = useQuery(GET_MY_PROFILE)
  const { setUserProfile } = useContext(UserStateContext)

  useEffect(() => {
    if (data?.getMyProfile !== undefined) {
      setUserProfile(data?.getMyProfile)
    }
  }, [data, setUserProfile])

  const creditBalance = data?.getMyProfile?.creditBalance

  return (
    <div className={'user-info__box'}>
      <QueryResult loading={loading} error={error} data={data}>
        {!window.location.pathname.startsWith('/settings/pois/add') && (
          <div className={'user-info'}>
            <div className={'user-info__photo-container'}>
              <Icon component={settingsIcons[SettingsIcons.UserIcon]} className={'photo-container__photo'} />
            </div>

            <div className={'user-info__details'}>
              <p className={'userinfo__username'}>{data?.getMyProfile?.screenName}</p>
              <p className={'userinfo__balance'}>
                {creditBalance &&
                  Object.keys(AcceptedCurrency).map((currency) => {
                    if (creditBalance[currency]) {
                      return <BalanceTag key={currency} currency={currency} amount={Number(creditBalance[currency])} />
                    }
                  })}
              </p>
            </div>
          </div>
        )}
        <div className={'userinfo__menu'}>
          <Outlet />
        </div>
      </QueryResult>
    </div>
  )
}
