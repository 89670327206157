import { OtherPOI, OtherService } from '../../graphQLTypes'
import { FormattedMessage } from 'react-intl'
import React, { useState } from 'react'
import './POI.css'
import { getActivationMethodIcon } from '../../utils/service'
import { SelectableIcon } from '../SelectableIcon'
import { POIActivationType } from '../../data/activationTypeData'
import Icon from '@ant-design/icons'
import { ReactComponent as IconUp } from '../../icons/arrow-up.svg'
import { ReactComponent as IconDown } from '../../icons/arrow-down.svg'
import { POIActivationProperties } from './POI'
import { InputNumber, TimePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'

export function POIActivation({
  poi,
  service,
  activationProperties,
  setActivationProperties,
}: Readonly<{
  poi: OtherPOI
  service: OtherService
  activationProperties: POIActivationProperties
  setActivationProperties: React.Dispatch<React.SetStateAction<POIActivationProperties>>
}>) {
  const [isAreaSelected, setIsAreaSelected] = useState<boolean>(true)

  const isUntilCancelled = activationProperties.type === POIActivationType.ACTIVE_UNTIL_CANCEL
  const isUntilTime = activationProperties.type === POIActivationType.ACTIVE_UNTIL_TIME
  const isUntilPrice = activationProperties.type === POIActivationType.ACTIVE_UNTIL_PRICE

  const selectedUntilCancelled = () => {
    setActivationProperties({
      type: POIActivationType.ACTIVE_UNTIL_CANCEL,
    })
  }

  const selectedUntilTime = () => {
    setActivationProperties({
      type: POIActivationType.ACTIVE_UNTIL_TIME,
      time: dayjs().add(1, 'hour'),
    })
  }

  const selectedUntilPrice = () => {
    setActivationProperties({
      type: POIActivationType.ACTIVE_UNTIL_PRICE,
      price: 10.0,
    })
  }

  const handleTimeChange = (time: Dayjs) => {
    // take current date and modify hour and second
    let newTime = dayjs().hour(time.hour()).minute(time.minute()).second(0)
    setActivationProperties({
      type: POIActivationType.ACTIVE_UNTIL_TIME,
      time: newTime.isBefore(dayjs()) ? newTime.add(1, 'day') : newTime, // in case this date is in past (given time passed), use following day
    })
  }

  const handlePriceChange = (val: number | string | null) => {
    if (val) {
      setActivationProperties((old) => {
        if (old.type === POIActivationType.ACTIVE_UNTIL_PRICE) {
          return {
            ...old,
            price: Number(val),
          }
        }
        return old
      })
    }
  }

  return (
    <div className={'menu-item-container'}>
      <div className={'accessibility_top_container'}>
        <div>
          <h2>
            <FormattedMessage id={'poi.activation'} />
          </h2>
        </div>
        <div className={'accessibility_top_container_hide_link'}>
          <a
            onClick={() => {
              setIsAreaSelected(!isAreaSelected)
            }}
          >
            <Icon
              style={{
                color: 'black',
              }}
              component={isAreaSelected ? IconUp : IconDown}
            />
          </a>
        </div>
      </div>

      <div
        style={{
          display: isAreaSelected ? 'block' : 'none',
        }}
      >
        <div className={'poi_activation'}>
          <div className={'poi__activation_item'}>
            <FormattedMessage id={'poi.untilcancelled'} />
          </div>
          <div>
            <span className="poi__poiServiceIcons">
              <SelectableIcon
                config={{
                  isSelected: isUntilCancelled,
                  isEnabled: true,
                }}
                onClick={selectedUntilCancelled}
                iconSelected={getActivationMethodIcon(POIActivationType.ACTIVE_UNTIL_CANCEL).selected}
                iconDeselected={getActivationMethodIcon(POIActivationType.ACTIVE_UNTIL_CANCEL).deselected}
                className={'menu-selectable-icon__icon'}
                key={1}
              />
            </span>
          </div>
        </div>

        <div className={'poi_activation'}>
          <div className={'poi__activation_item'}>
            <div>
              <FormattedMessage id={'poi.untiltime'} />
            </div>
            {isUntilTime && (
              <div className={'price_info_until_price_container'}>
                <TimePicker
                  onChange={handleTimeChange}
                  allowClear={false}
                  size="large"
                  format="HH:mm"
                  showNow={false}
                  value={activationProperties.time}
                  style={{ width: '11rem' }}
                />
              </div>
            )}
          </div>
          <div>
            <span className="poi__poiServiceIcons">
              <SelectableIcon
                config={{
                  isSelected: isUntilTime,
                  isEnabled: true,
                }}
                onClick={selectedUntilTime}
                iconSelected={getActivationMethodIcon(POIActivationType.ACTIVE_UNTIL_TIME).selected}
                iconDeselected={getActivationMethodIcon(POIActivationType.ACTIVE_UNTIL_TIME).deselected}
                className={'menu-selectable-icon__icon'}
                key={2}
              />
            </span>
          </div>
        </div>

        <div className={'poi_activation'}>
          <div className={'poi__activation_item'}>
            <div>
              <FormattedMessage id={'poi.untilprice'} />
            </div>
            {isUntilPrice && (
              <InputNumber<number>
                size="large"
                min={1}
                max={100000}
                value={activationProperties.price}
                onChange={handlePriceChange}
                addonAfter={service.serviceSchedule.pricingProps.acceptedCurrency}
                style={{ width: '11rem' }}
              />
            )}
          </div>
          <div>
            <span className="poi__poiServiceIcons">
              <SelectableIcon
                config={{
                  isSelected: isUntilPrice,
                  isEnabled: true,
                }}
                onClick={selectedUntilPrice}
                iconSelected={getActivationMethodIcon(POIActivationType.ACTIVE_UNTIL_PRICE).selected}
                iconDeselected={getActivationMethodIcon(POIActivationType.ACTIVE_UNTIL_PRICE).deselected}
                className={'menu-selectable-icon__icon'}
                key={3}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
