import { AUTH_TYPE } from 'aws-appsync-auth-link'
import { fetchAuthSession } from 'aws-amplify/auth'

type API_KEY = 'API_KEY'
type AMAZON_COGNITO_USER_POOLS = 'AMAZON_COGNITO_USER_POOLS'

export enum FetchPolicy {
  CACHE_AND_NETWORK = 'cache-and-network',
  CACHE_FIRST = 'cache-first',
  NETWORK_ONLY = 'network-only',
  NO_CACHE = 'cache-only',
  STANDBY = 'standby',
}

export const publicEndpointConfig = {
  url: process.env.REACT_APP_AWS_APPSYNC_ENDPOINT_PUBLIC || '',
  region: process.env.REACT_APP_AWS_APPSYNC_REGION || '',
  auth: {
    type: AUTH_TYPE.API_KEY as API_KEY,
    apiKey: process.env.REACT_APP_AWS_APPSYNC_API_KEY_PUBLIC || '',
  },
}

export const privateEndpointConfig = {
  url: process.env.REACT_APP_AWS_APPSYNC_ENDPOINT_PRIVATE || '',
  region: process.env.REACT_APP_AWS_APPSYNC_REGION || '',
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS as AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => {
      const currentSession = await fetchAuthSession()
      return currentSession.tokens?.accessToken.toString()!
    },
  },
}

export const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID || '',
      userPoolClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID || '',
    },
  },
}
