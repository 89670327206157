import React, { useContext, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { UserStateContext } from '../../../../store/userContext'
import { MenuBackButton } from '../../../MenuItems/MenuBackButton'
import { MenuItem } from '../../../MenuItems/MenuItem'
import { POIServiceName } from '../../../POI/POIServiceName'
import { formatCurrency } from '../../../../utils/currency'
import { PricingProps } from '../../../../graphQLTypes'

function PricingSection({ pricing }: Readonly<{ pricing: PricingProps }>) {
  console.log(pricing)
  return (
    <>
      {pricing.pricePerHour !== null && (
        <MenuItem label="Price per hour" config={{ isNavigable: false }}>
          {formatCurrency(pricing.acceptedCurrency, pricing.pricePerHour)}
        </MenuItem>
      )}
      {pricing.pricePerUnit !== null && (
        <MenuItem label={'Price per ' + pricing.unitType} config={{ isNavigable: false }}>
          {formatCurrency(pricing.acceptedCurrency, pricing.pricePerUnit)}
        </MenuItem>
      )}
      {pricing.minPrice !== null && (
        <MenuItem label="Min price" config={{ isNavigable: false }}>
          {formatCurrency(pricing.acceptedCurrency, pricing.minPrice)}
        </MenuItem>
      )}
      {pricing.maxPrice !== null && (
        <MenuItem label="Max price" config={{ isNavigable: false }}>
          {formatCurrency(pricing.acceptedCurrency, pricing.maxPrice)}
        </MenuItem>
      )}
    </>
  )
}

export function ServiceMenu() {
  const navigate = useNavigate()
  const params = useParams()
  const { state } = useContext(UserStateContext)

  // extract selected POI
  const poi = state.pois!.find((x) => x.poiId === params.poiId)

  // extract selected Service
  const service = poi?.services.find((x) => x.serviceId === params.serviceId)

  // when poi does not exists
  useEffect(() => {
    if (poi == null) {
      navigate('/settings/pois/')
    }
  })

  if (service == null) {
    return <span />
  }

  const hasDevice = !!service.deviceId
  const serviceUrl =
    '/settings/pois/service/' + encodeURIComponent(poi!.poiId) + '/' + encodeURIComponent(service!.serviceId)

  // service.serviceSchedule.pricingProps

  return (
    <div>
      <MenuBackButton onClick={() => navigate('/settings/pois/poi/' + encodeURIComponent(poi!.poiId))} />
      <MenuItem label="Service" config={{ isNavigable: false }} />
      <POIServiceName poi={poi!} service={service!} />

      {service?.serviceSchedule?.pricingProps && <PricingSection pricing={service?.serviceSchedule?.pricingProps} />}

      <MenuItem label="Device ID" config={{ isNavigable: false }}>
        <code>{service?.deviceId}</code>
      </MenuItem>
      <MenuItem
        label="Edit service"
        config={{ isNavigable: true, isSubItem: true }}
        onClick={() => navigate(serviceUrl + '/edit')}
      />
      {!hasDevice && (
        <MenuItem
          label="Assign device"
          config={{ isNavigable: true, isSubItem: true }}
          onClick={() => navigate(serviceUrl + '/attachDevice')}
        />
      )}
      {hasDevice && (
        <MenuItem
          label="Replace device"
          config={{ isNavigable: true, isSubItem: true }}
          onClick={() => navigate(serviceUrl + '/replaceDevice')}
        />
      )}
      <MenuItem
        label="Delete service"
        config={{ isNavigable: true, isSubItem: true }}
        onClick={() => navigate(serviceUrl + '/delete')}
      />
    </div>
  )
}
