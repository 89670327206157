import { default as AntIcon } from '@ant-design/icons'
import React from 'react'
import { SVGIcon } from './icons'

export function Icon({
  selectedIcon,
  deselectedIcon,
  className,
  onClickHandlers,
  isSelected,
}: {
  selectedIcon: SVGIcon
  deselectedIcon: SVGIcon
  className: string
  onClickHandlers?: (Function | undefined)[]
  isSelected: boolean
}) {
  const onClick = () => {
    onClickHandlers?.forEach((onClickHandler) => onClickHandler !== undefined && onClickHandler())
  }

  return <AntIcon component={isSelected ? selectedIcon : deselectedIcon} className={`${className}`} onClick={onClick} />
}
