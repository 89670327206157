import React from 'react'
import SettingsWrapper from '../Settings/SettingsWrapper'
import './SessionHistory.css'
import { useQuery } from '@apollo/client'
import { LIST_MY_PAST_SESSIONS } from '../../queries'
import { ListMyPastSessionsResponse } from '../../graphQLTypes'
import QueryResult from '../QueryResult'
import SessionHistoryTable from './SessionHistoryTable'

export default function SessionHistory() {
  const { loading, error, data } = useQuery<ListMyPastSessionsResponse>(LIST_MY_PAST_SESSIONS)

  const compareFnd = (a: any, b: any) => {
    if (a.createdAt < b.createdAt) {
      return 1
    }
    if (a.createdAt > b.createdAt) {
      return -1
    }
    return 0
  }

  const sortedData = data?.listMyPastSessions ? [...data.listMyPastSessions].sort(compareFnd) : []

  return (
    <SettingsWrapper>
      <div className="sessionhistory__wrapper">
        <h2 className={'session__header'}>My history</h2>
        <QueryResult loading={loading} error={error} data={data?.listMyPastSessions}>
          <SessionHistoryTable data={sortedData} />
        </QueryResult>
      </div>
    </SettingsWrapper>
  )
}
