import { useAuthenticator } from '@aws-amplify/ui-react'
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { iot, mqtt5 } from 'aws-iot-device-sdk-v2'
import { UserStateContext } from './userContext'
import { fetchAuthSession } from 'aws-amplify/auth'

/**
 * I will store client in global variable (but as a promise, becasue it is created async)
 */
let MQTTClient: Promise<mqtt5.Mqtt5Client> | undefined = undefined

export const MQTTContext = createContext({
  startedSessions: [] as string[],
  finishedSessions: [] as string[],
  setListen: (a: boolean) => {},
})

/**
 * Create new connection and subscribe to user's notification topic
 */
async function connectClientInternal(
  username: string | null,
  messageHandler: (event: mqtt5.MessageReceivedEvent) => void
) {
  const session = await fetchAuthSession()

  let builder = iot.AwsIotMqtt5ClientConfigBuilder.newWebsocketMqttBuilderWithCustomAuth(
    process.env.REACT_APP_AWS_MQTT_ENDPOINT!,
    {
      authorizerName: 'iot-authorizer-v1',
      username: session.tokens?.accessToken.toString(),
    }
  )
  const client = new mqtt5.Mqtt5Client(builder.build())
  client.on('connectionSuccess', (event) => {
    console.log('MQTT connected')
    client.subscribe({ subscriptions: [{ qos: 0, topicFilter: 'user/' + username + '/notify' }] }).then((suback) => {
      const result = suback.reasonCodes[0]
      if (result === 0) {
        console.log('MQTT subscribed')
      } else {
        console.log('MQTT subscription failed, with result: ', result)
      }
    })
  })
  client.on('messageReceived', messageHandler)
  client.on('attemptingConnect', (event) => console.log('MQTT Attempting to connect'))
  client.on('connectionFailure', (event) => console.log('MQTT Connection failure', event))
  client.on('error', (error) => console.log('MQTT Connection error', error))
  client.start()
  return client
}

function connectClient(username: string | null, messageHandler: (event: mqtt5.MessageReceivedEvent) => void) {
  if (MQTTClient) {
    console.log('already connected')
    return
  }
  MQTTClient = connectClientInternal(username, messageHandler)
}

const disconnectClient = () => {
  if (MQTTClient) {
    console.log('disconnecting ...')
    MQTTClient.then((c) => c.stop())
    MQTTClient = undefined
  }
}

const MQTTContextProvider = ({ children }: { children?: ReactNode }) => {
  // user context
  const { removeActiveSession, updateActiveSession, setShowActiveSessions } = useContext(UserStateContext)

  // retrieve username of currently logged user
  const { user } = useAuthenticator((context) => [context.user])
  const username = user ? user.username : null

  // build a list of started sessions
  const [listen, setListen] = useState<boolean>(false)
  const [startedSessions, setStartedSessions] = useState<string[]>([])
  const [finishedSessions, setFinishedSessions] = useState<string[]>([])

  // anyting which sets listen will trigger this, any stop mqtt event will stop this (if we should allow multiple concurrent sessions, we need to change this)
  useEffect(() => {
    if (listen) {
      connectClient(username, (event) => {
        console.log('received message', event.message.topicName, event.message.payload!.toString())
        const payload = JSON.parse(event.message.payload!.toString())
        if (payload.type === 'do-switch-on-result') {
          console.log('marking ON as complete', payload['session-id'])
          setStartedSessions((oldstate) => [...oldstate, payload['session-id']])
          setShowActiveSessions(true)
        }
        if (payload.type === 'do-switch-off-result') {
          console.log('marking OFF as complete')
          setFinishedSessions((oldstate) => [...oldstate, payload['session-id']])
          console.log('removing session from active sessions', payload['session-id'])
          removeActiveSession(payload['session-id'])
          console.log('marking as not listening ...')
          setListen(false)
        }
        if (payload.type === 'energy-update') {
          console.log('energy update')
          updateActiveSession({
            sessionId: payload['session-id'],
            energyCurrent: payload['energy-current'],
            energyConsumed: payload['energy-consumed'],
            totalTime: payload['total-time'],
            totalCost: payload['total-cost'],
            lastStatus: payload['last-status'],
            lastStatusText: payload['last-status-text'],
          })
        }
      })
    } else {
      disconnectClient()
    }
  }, [listen, username, removeActiveSession, updateActiveSession])

  // call disconnect on username change
  useEffect(() => () => disconnectClient(), [username])

  // this is our context (in memo to make eslint happy)
  const contextData = useMemo(
    () => ({
      startedSessions,
      finishedSessions,
      setListen,
    }),
    [startedSessions, finishedSessions, setListen]
  )

  return <MQTTContext.Provider value={contextData}>{children}</MQTTContext.Provider>
}

export default MQTTContextProvider
