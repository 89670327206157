import { gql } from 'graphql-tag'

export const SERVICE_START = gql`
  mutation serviceStart($input: ServiceStartInput!) {
    serviceStart(input: $input) {
      poiId
      serviceId
      sessionId
    }
  }
`

export const SERVICE_STOP = gql`
  mutation serviceStop($input: ServiceStopInput!) {
    serviceStop(input: $input) {
      poiId
      serviceId
      sessionId
      createdAt
      destroyedAt
      energyInitial
      energyConsumed
    }
  }
`

export const ADD_POI = gql`
  mutation addPOI($input: AddPOIInput!) {
    addPOI(input: $input) {
      poiId
      serviceId
    }
  }
`

export const ADD_SERVICE = gql`
  mutation addService($service: AddServiceInput!) {
    addService(ipnut: $service) {
      serviceId
    }
  }
`

export const UPDATE_SERVICE = gql`
  mutation updateService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      poiId
      serviceId
    }
  }
`

export const ASSIGN_DEVICE = gql`
  mutation assignDevice($input: AssignDeviceInput!) {
    assignDevice(input: $input) {
      poiId
      serviceId
    }
  }
`

export const DELETE_SERVICE = gql`
  mutation deleteService($input: DeleteServiceInput!) {
    deleteService(input: $input) {
      poiId
      serviceId
    }
  }
`

export const PAYPAL_CAPTURE_ORDER = gql`
  mutation paypalCaptureOrder($order: PaypalCaptureOrderInput!) {
    paypalCaptureOrder(input: $order) {
      balanceCurrency
      balanceAdded
      balanceNew
    }
  }
`

export const UPDATE_MY_POI = gql`
  mutation updateMyPOI($poi: MyPOIUpdateInput!) {
    updateMyPOI(MyNewPOI: $poi) {
      poiId
      services {
        serviceId
      }
    }
  }
`

export const UPDATE_PROFILE = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      screenName
      avatarUrl
      preferredCurrency
      profileType
    }
  }
`
