import { Currency } from './types'

export enum ProfileType {
  BUSINESS = 'BUSINESS',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum POIType {
  BUSINESS = 'BUSINESS',
  INDIVIDUAL = 'INDIVIDUAL',
  DEMAND = 'DEMAND',
}

// TODO: Decide that to do with this OLD Service type
export enum ServiceTypeOld {
  Charger = 'Charger',
  ChargerBlue = 'ChargerBlue',
  ChargerGreen = 'ChargerGreen',
  Demand = 'Demand',
  Parking = 'Parking',
  Socket = 'Socket',
  SocketBlue = 'SocketBlue',
  SocketBlueParking = 'SocketBlueParking',
  SocketGreen = 'SocketGreen',
  SocketGreenParking = 'SocketGreenParking',
  SocketParking = 'SocketParking',
}

export enum ServiceType {
  PARKING = 'PARKING',
  CHARGER = 'CHARGER',
  SOCKET = 'SOCKET',
}

export interface BusinessPOIProps {
  brand: string
}

export interface IndividualPOIProps {
  tbd: String
}

export interface DemandPOIProps {
  range: number
  expireAt: string
  reward: number
}

export enum POIAccess {
  UNRESTRICTED = 'UNRESTRICTED',
  RESTRICTED = 'RESTRICTED',
}

export enum POILocationType {
  PUBLIC_SPACE = 'PUBLIC_SPACE',
  PRIVATE = 'PRIVATE',
  // not used, keeping only for backward compatibility
  RESIDENTIAL = 'RESIDENTIAL',
  // not used, keeping only for backward compatibility
  BUSINESS = 'BUSINESS',
}

export enum AcceptedCurrency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  BTC = 'BTC',
}

export enum ServiceStatus {
  IN_DEMAND = 'IN_DEMAND',
  UNDER_MAINTENANCE = 'UNDER_MAINTENANCE',
  ON_HOLD = 'ON_HOLD',
  RESERVED = 'RESERVED',
  REDUCED = 'REDUCED',
  AVAILABLE = 'AVAILABLE',
  IN_USE = 'IN_USE',
  OFFLINE = 'OFFLINE',
  DISABLED = 'DISABLED',
}

export enum POIStatus {
  AVAILABLE = 'AVAILABLE',
  IN_USE = 'IN_USE',
  OFFLINE = 'OFFLINE',
  DISABLED = 'DISABLED',
}

interface ParkingServiceProps {
  hasCCTV: boolean
}

export enum IOTPlatform {
  TUYA = 'TUYA',
  CE_NATIVE = 'CE_NATIVE',
}

export enum MaxPower {
  W3600 = 'W3600',
  W11000 = 'W11000',
  W50000 = 'W50000',
  W125000 = 'W125000',
  W250000 = 'W250000',
  W350000 = 'W350000',
}

export enum ChargerCurrentType {
  AC = 'AC',
  DC = 'DC',
}

interface ChargerServiceProps {
  hasEnergyMeter: boolean
  isGreen: boolean
  chargerCurrentType?: ChargerCurrentType
  chargerIOTPlatform: IOTPlatform
  chargerMaxPower?: MaxPower
  chargerConnectorType: string
}

interface SocketServiceProps {
  hasEnergyMeter: boolean
  isGreen: boolean
  socketIOTPlatform: IOTPlatform
  socketMaxPower?: MaxPower
  socketConnectorType: string
}

export enum UnitType {
  KW = 'KW',
}

export interface PricingProps {
  acceptedCurrency: AcceptedCurrency
  pricePerHour: number
  pricePerUnit: number
  unitType: UnitType
  minPrice: number
  maxPrice: number
}

interface ServiceSchedule {
  isActive: Boolean
  pricingProps: PricingProps
}

export interface GeneralService {
  serviceId: string
  serviceName: string
  isActive: boolean
  createdAt: string
  serviceStatus: ServiceStatus
  serviceType: ServiceType
  parkingServiceProps: ParkingServiceProps
  chargerServiceProps: ChargerServiceProps
  socketServiceProps: SocketServiceProps
  serviceSchedule: ServiceSchedule
}

export interface OtherService extends GeneralService {
  activeSessionId: string
}

export interface NearbyPOI {
  userId: string
  poiId: string
  poiName: string
  description: string
  geohash: string
  rating: number
  createdAt: string
  avatarUrl?: string
  businessPOIProps: BusinessPOIProps
  individualPOIProps: IndividualPOIProps
  poiType: POIType
  locationType: POILocationType
  access: POIAccess
  serviceTypes: ServiceType[]
  poiStatus: POIStatus
}

export interface ListNearbyPOIsResponse {
  listNearbyPOIs: {
    nearbyPOIs: NearbyPOI[]
  }
}

export interface GeneralPOI {
  poiId: string
  poiName: string
  description: string
  geohash: string
  rating: number
  createdAt: string
  avatarUrl?: string
  businessPOIProps: BusinessPOIProps
  individualPOIProps: IndividualPOIProps
  poiType: POIType
  locationType: POILocationType
  access: POIAccess
  serviceTypes: ServiceType[]
}

export interface OtherPOI extends GeneralPOI {
  services: OtherService[]
}

export interface GetPOIResponse {
  getPOI: OtherPOI
}

export interface GetMyPOIsPayload {
  pois: [MyPOI]
}

export interface GetMyPOIsResponse {
  getMyPOIs: GetMyPOIsPayload
}

export interface GetMyServicesPayload {
  services: OtherService[]
}

export interface GetMyServicesResponse {
  getMyServices: GetMyServicesPayload
}

export enum FilterTypes {
  Demand = 'demand',
  Favourites = 'favourites',
  GreenSockets = 'greenSockets',
  BlueSockets = 'blueSockets',
  Parking = 'parking',
  Charging = 'charging',
}

export interface MyChargingSession {
  poiId: string
  serviceId: string
  sessionId: string
  userNameOwner: string
  userNameConsumer: string
  createdAt: string
  updatedAt: string
  energyInitial: string
  energyCurrent: string
  energyConsumed: string
  serviceName: string
  serviceType: ServiceType
  totalTime: number
  totalCost: number
  lastStatus: string
  acceptedCurrency: AcceptedCurrency
}

export interface MyPastChargingSession {
  sessionId: string
  serviceName: string
  serviceType: ServiceType
  createdAt: string
  destroyedAt: string
  energyInitial: string
  energyConsumed: string
  totalTime: number
  totalCost: number
  acceptedCurrency: AcceptedCurrency
}

export interface MyBalanceHistory {
  createdAt: number
  amount: number
  currency: AcceptedCurrency
  creditOld: number
  creditNew: number
  source: string
  serviceId?: string
  sessionId?: string
  serviceName?: string
  serviceType?: string
}

export interface ListMyActiveSessionsResponse {
  listMyActiveSessions: [MyChargingSession]
}

export interface ListMyPastSessionsResponse {
  listMyPastSessions: [MyPastChargingSession]
}

export interface ListMyBalanceHistoryResponse {
  listMyBalanceHistory: [MyBalanceHistory]
}

export interface UpdatedChargingSessionResponse {
  updatedChargingSession: MyChargingSession
}

interface BusinessPOIInput {
  brand: string
}

interface IndividualPOIInput {
  tbd: string
}

export interface MyPOIInput {
  poiName: string
  description: string
  avatarUrl: string
  geohash: string
  businessPOIProps: BusinessPOIInput
  individualPOIProps: IndividualPOIInput
  poiType: POIType
  locationType: POILocationType
  access: POIAccess
}

export interface MyServiceInput {
  poiId: string
  serviceName: string
  isActive: boolean
  serviceStatus: ServiceStatus
  serviceType: ServiceType
  deviceId: string
  parkingServiceProps: ParkingServiceProps
  chargerServiceProps: ChargerServiceProps
  socketServiceProps: SocketServiceProps
  serviceSchedule: ServiceSchedule
}

export interface CreditBalance {
  USD: number
  EUR: number
  GBP: number
  BTC: number
}

export interface MyService extends GeneralService {
  deviceId: String
}

export interface MyPOI extends GeneralPOI {
  services: [MyService]
}

export interface MyProfile {
  screenName: string
  userName: string
  email: string
  pois: MyPOI[]
  creditBalance: CreditBalance
  avatar: string
  preferredCurrency: Currency
  profileType: ProfileType
}

export interface MyServiceUpdateInput {
  poiId: string
  serviceId: string
  serviceName?: String
  isActive?: boolean
  serviceStatus?: ServiceStatus
  serviceType?: ServiceType
  deviceId?: string
  parkingServiceProps?: ParkingServiceProps
  chargerServiceProps?: ChargerServiceProps
  socketServiceProps?: SocketServiceProps
  serviceSchedule?: ServiceSchedule
}

export interface MyPOIUpdateInput {
  poiId: string
  poiName?: string
  description?: string
  avatarUrl?: string
  geohash?: string
  businessPOIProps?: BusinessPOIInput
  individualPOIProps?: IndividualPOIInput
  poiType?: POIType
  locationType?: POILocationType
  access?: POIAccess
}

export interface UpdateProfileInput {
  screenName?: string
  avatarUrl?: string
  preferredCurrency?: Currency
}

export interface PricingPropsInput {
  acceptedCurrency?: AcceptedCurrency
  pricePerHour?: number
  pricePerUnit?: number
  unitType?: UnitType
  minPrice?: number
  maxPrice?: number
}

export interface ServiceScheduleInput {
  isActive: boolean
  pricingProps: PricingPropsInput
}

export interface SocketServicePropsInput {
  hasEnergyMeter: boolean
  isGreen: boolean
  socketIOTPlatform: IOTPlatform
  socketMaxPower?: MaxPower
  socketConnectorType: string
}

export interface ChargerServicePropsInput {
  hasEnergyMeter: boolean
  isGreen: boolean
  chargerCurrentType?: ChargerCurrentType
  chargerIOTPlatform: IOTPlatform
  chargerMaxPower?: MaxPower
  chargerConnectorType: string
}

export interface ParkingServicePropsInput {
  hasCCTV: boolean
}

// Used for adding new service together with POI
export interface ServiceInput {
  serviceName: string
  serviceType: string
  parkingServiceProps?: ParkingServicePropsInput
  chargerServiceProps?: ChargerServicePropsInput
  socketServiceProps?: SocketServicePropsInput
  serviceSchedule: ServiceScheduleInput
}

export interface AddPOIInput {
  poiName: string
  description: string
  geohash: string
  rating?: number
  avatarUrl?: string
  poiType: ProfileType
  locationType: POILocationType
  access: POIAccess
  service: ServiceInput
}

export interface UpdateServiceInput {
  poiId: string
  serviceId: string
  deviceId?: string
  serviceName?: string
}
