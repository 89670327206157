import React, { ReactNode } from 'react'
import './MenuLabel.css'
import { MenuLabelConfig } from '../../types'
import classNames from 'classnames'

const isMenuLabelVisible = (isVisible?: boolean) => isVisible !== false

export function MenuLabel({
  label,
  config,
  hasFixedWidth,
}: {
  label: string | ReactNode
  config?: MenuLabelConfig
  hasFixedWidth?: boolean
}) {
  const { isVisible } = config || {}
  return isMenuLabelVisible(isVisible) ? (
    <p
      className={classNames('menu-label', {
        'menu-label--fixed-width': hasFixedWidth,
      })}
    >
      {label}
    </p>
  ) : null
}
