import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { LIST_MY_ACTIVE_SESSIONS } from '../../queries'
import QueryResult from '../QueryResult'
import SettingsWrapper from '../Settings/SettingsWrapper'
import { LeftOutlined } from '@ant-design/icons'
import { ListMyActiveSessionsResponse } from '../../graphQLTypes'
import './SessionWrapper.css'
import { UserStateContext } from '../../store/userContext'
import Sessions from './Sessions'
import { MQTTContext } from '../../store/mqttContext'
import { Button } from 'antd'

export default function SessionWrapper() {
  const navigate = useNavigate()
  const userCtx = useContext(UserStateContext)
  const mqttCtx = useContext(MQTTContext)

  const onSessionHistoryHandler = () => navigate('/sessions/history')

  const { loading, error, data } = useQuery<ListMyActiveSessionsResponse>(LIST_MY_ACTIVE_SESSIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      userCtx.setActiveSessions(data.listMyActiveSessions)
      // when there are any active sessions, ensure we are connected to MQTT
      if (data.listMyActiveSessions.length > 0) {
        console.log('connecting to MQTT', data.listMyActiveSessions)
        mqttCtx.setListen(true)
      }
    },
    onError: () => userCtx.setActiveSessions([]),
  })

  return (
    <SettingsWrapper>
      <div className={'session__wrapper'}>
        <QueryResult loading={loading} error={error} data={data?.listMyActiveSessions}>
          <NavLink to="/">
            <LeftOutlined></LeftOutlined>
          </NavLink>
          <h2 className={'session__header'}>My currently started services</h2>
          {userCtx.state.activeSessions.length ? <Sessions /> : <p>You don't have currently any active charging.</p>}
          <div className="session__historybox">
            <Button type="default" onClick={onSessionHistoryHandler}>
              History
            </Button>
          </div>
        </QueryResult>
      </div>
    </SettingsWrapper>
  )
}
