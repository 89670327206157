import React, { useContext } from 'react'
import { Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { getArrayOfValuesFromDictionary, isItemSelected } from '../../../utils/common'
import { LocaleType } from '../../../types'
import './LanguageDropdown.css'
import { UserStateContext } from '../../../store/userContext'
import type { MenuProps } from 'antd'

export function LanguageDropdown() {
  const { state, changeLocale } = useContext(UserStateContext)

  const items: MenuProps['items'] = getArrayOfValuesFromDictionary(LocaleType).map((locale) => {
    return {
      key: locale!,
      disabled: isItemSelected(state.localeType, locale),
      onClick: () => changeLocale(locale!),
      label: <span>{locale}</span>,
    }
  })

  return (
    <Menu>
      <Menu.Item>
        <Dropdown menu={{ items }}>
          <a href="/#" className="ant-dropdown-link language-dropdown__link" onClick={(e) => e.preventDefault()}>
            {state.localeType} <DownOutlined />
          </a>
        </Dropdown>
      </Menu.Item>
    </Menu>
  )
}
