import React from 'react'
import { POIStatus } from '../../graphQLTypes'

function POIStatusIcon({ poiStatus }: { poiStatus: POIStatus | undefined }) {
  if (poiStatus === POIStatus.AVAILABLE) {
    return <span>&#128994;</span>
  }
  if (poiStatus === POIStatus.IN_USE) {
    return <span>&#128308;</span>
  }
  if (poiStatus === POIStatus.OFFLINE) {
    return <span>&#128992;</span>
  }

  return <span>&#11044;</span>
}

export default POIStatusIcon
