import { ReactNode, createContext, useCallback, useMemo, useReducer } from 'react'
import { mapConstants } from '../data/constants'
import { MyChargingSession, MyPOI, MyProfile } from '../graphQLTypes'
import { AllCurrency, Currency, LocaleType } from '../types'
import { IntlProvider } from 'react-intl'
import { getDefaultLocale, getMessages } from '../utils/language'

enum ActionType {
  SET_LAST_SERVICE_LOCATION = 'SET_LAST_SERVICE_LOCATION',
  SET_USER_LOCATION = 'SET_USER_LOCATION',
  REFRESH_USER_LOCATION = 'REFRESH_USER_LOCATION',
  REFRESH_USER_POIS = 'REFRESH_USER_POIS',
  SET_USER_PROFILE = 'SET_USER_PROFILE',
  CHANGE_LOCALE = 'CHANGE_LOCALE',
  SET_BITCOIN_RATE = 'SET_BITCOIN_RATE',
  SET_ACTIVE_SESSIONS = 'SET_ACTIVE_SESSIONS',
  REMOVE_ACTIVE_SESSION = 'REMOVE_ACTIVE_SESSION',
  UPDATE_ACTIVE_SESSION = 'UPDATE_ACTIVE_SESSION',
  SET_SHOW_ACTIVE_SESSIONS = 'SET_SHOW_ACTIVE_SESSIONS',
}

export interface UserLocation {
  lat: number
  lng: number
}

export interface UpdateActiveSessionPayload {
  sessionId: string
  energyCurrent: string
  energyConsumed: string
  totalTime: string
  totalCost: string
  lastStatus: string
  lastStatusText: string
}

export interface UserState {
  lastServiceLocation?: UserLocation
  userLocation?: UserLocation
  refreshUserLocation?: boolean
  profile?: MyProfile
  localeType?: LocaleType
  currencyType?: Currency
  pois?: MyPOI[]
  bitcoinEuroRate?: number
  activeSessions: MyChargingSession[]
  showActiveSessions: boolean
}

type Action =
  | { type: ActionType.SET_LAST_SERVICE_LOCATION; payload: UserLocation }
  | { type: ActionType.SET_USER_LOCATION; payload: UserLocation }
  | { type: ActionType.REFRESH_USER_LOCATION }
  | { type: ActionType.REFRESH_USER_POIS; payload: MyPOI[] }
  | { type: ActionType.SET_USER_PROFILE; payload: MyProfile }
  | { type: ActionType.CHANGE_LOCALE; payload: LocaleType }
  | { type: ActionType.SET_BITCOIN_RATE; payload: number }
  | { type: ActionType.SET_ACTIVE_SESSIONS; payload: MyChargingSession[] }
  | { type: ActionType.REMOVE_ACTIVE_SESSION; payload: string }
  | { type: ActionType.UPDATE_ACTIVE_SESSION; payload: UpdateActiveSessionPayload }
  | { type: ActionType.SET_SHOW_ACTIVE_SESSIONS; payload: boolean }

const initialState = {
  lastServiceLocation: undefined,
  userLocation: {
    lat: mapConstants.defaultLatitude,
    lng: mapConstants.defaultLongitude,
  },
  refreshUserLocation: false,
  profile: undefined,
  localeType: LocaleType.EN,
  currencyType: AllCurrency.EUR,
  pois: [],
  activeSessions: [],
  showActiveSessions: false,
}

export const UserStateContext = createContext<{
  state: UserState
  setLastServiceLocation: (payload: UserLocation) => void
  setUserLocation: (payload: UserLocation) => void
  refreshUserLocation: () => void
  refreshUserPois: (payload: MyPOI[]) => void
  setUserProfile: (payload: MyProfile) => void
  changeLocale: (payload: LocaleType) => void
  setBitcoinRate: (payload: number) => void
  setActiveSessions: (payload: MyChargingSession[]) => void
  removeActiveSession: (payload: string) => void
  updateActiveSession: (payload: UpdateActiveSessionPayload) => void
  setShowActiveSessions: (payload: boolean) => void
}>({
  state: initialState,
  setUserLocation: () => null,
  setLastServiceLocation: () => null,
  refreshUserLocation: () => null,
  refreshUserPois: () => null,
  setUserProfile: () => null,
  changeLocale: () => null,
  setBitcoinRate: () => null,
  setActiveSessions: () => null,
  removeActiveSession: () => null,
  updateActiveSession: () => null,
  setShowActiveSessions: () => null,
})

function userReducer(prevState: UserState, action: Action): UserState {
  switch (action.type) {
    case ActionType.SET_LAST_SERVICE_LOCATION: {
      return {
        ...prevState,
        lastServiceLocation: action.payload,
      }
    }
    case ActionType.CHANGE_LOCALE: {
      return {
        ...prevState,
        localeType: action.payload,
      }
    }
    case ActionType.SET_USER_LOCATION: {
      return {
        ...prevState,
        userLocation: action.payload,
      }
    }
    case ActionType.REFRESH_USER_LOCATION: {
      return {
        ...prevState,
        refreshUserLocation: !prevState.refreshUserLocation,
      }
    }
    case ActionType.SET_USER_PROFILE: {
      return {
        ...prevState,
        profile: action.payload,
      }
    }
    case ActionType.REFRESH_USER_POIS: {
      return {
        ...prevState,
        pois: action.payload,
      }
    }
    case ActionType.SET_BITCOIN_RATE: {
      return {
        ...prevState,
        bitcoinEuroRate: action.payload,
      }
    }
    case ActionType.SET_ACTIVE_SESSIONS: {
      return {
        ...prevState,
        activeSessions: action.payload,
        showActiveSessions: action.payload.length > 0,
      }
    }
    case ActionType.REMOVE_ACTIVE_SESSION: {
      const newActiveSessions = prevState.activeSessions.filter((session) => session.sessionId !== action.payload)
      return {
        ...prevState,
        activeSessions: newActiveSessions,
        showActiveSessions: newActiveSessions.length > 0,
      }
    }
    case ActionType.UPDATE_ACTIVE_SESSION: {
      return {
        ...prevState,
        activeSessions: prevState.activeSessions.map((session) => {
          if (session.sessionId === action.payload.sessionId) {
            return {
              ...session,
              energyCurrent: action.payload.energyCurrent,
              energyConsumed: action.payload.energyConsumed,
              totalTime: parseFloat(action.payload.totalTime),
              totalCost: parseFloat(action.payload.totalCost),
              lastStatus: action.payload.lastStatus,
            }
          } else {
            return session
          }
        }),
      }
    }
    case ActionType.SET_SHOW_ACTIVE_SESSIONS: {
      return {
        ...prevState,
        showActiveSessions: action.payload,
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action}`)
    }
  }
}

export const UserStateContextProvider = ({ children }: { children?: ReactNode }) => {
  // use reducer to store state
  const [state, dispatch] = useReducer(userReducer, initialState)

  const setUserLocation = useCallback(
    (payload: UserLocation) => {
      dispatch({ type: ActionType.SET_USER_LOCATION, payload: payload })
    },
    [dispatch]
  )

  const setLastServiceLocation = useCallback(
    (payload: UserLocation) => {
      dispatch({ type: ActionType.SET_LAST_SERVICE_LOCATION, payload: payload })
    },
    [dispatch]
  )

  const refreshUserLocation = useCallback(() => {
    dispatch({ type: ActionType.REFRESH_USER_LOCATION })
  }, [dispatch])

  const refreshUserPois = useCallback(
    (payload: MyPOI[]) => {
      dispatch({ type: ActionType.REFRESH_USER_POIS, payload: payload })
    },
    [dispatch]
  )

  const setUserProfile = useCallback(
    (payload: MyProfile) => {
      dispatch({ type: ActionType.SET_USER_PROFILE, payload: payload })
    },
    [dispatch]
  )

  const changeLocale = useCallback(
    (payload: LocaleType) => {
      dispatch({ type: ActionType.CHANGE_LOCALE, payload: payload })
    },
    [dispatch]
  )
  const setBitcoinRate = useCallback(
    (payload: number) => {
      dispatch({ type: ActionType.SET_BITCOIN_RATE, payload: payload })
    },
    [dispatch]
  )
  const setActiveSessions = useCallback(
    (payload: MyChargingSession[]) => {
      dispatch({ type: ActionType.SET_ACTIVE_SESSIONS, payload: payload })
    },
    [dispatch]
  )
  const removeActiveSession = useCallback(
    (payload: string) => {
      dispatch({ type: ActionType.REMOVE_ACTIVE_SESSION, payload: payload })
    },
    [dispatch]
  )
  const updateActiveSession = useCallback(
    (payload: UpdateActiveSessionPayload) => {
      dispatch({ type: ActionType.UPDATE_ACTIVE_SESSION, payload: payload })
    },
    [dispatch]
  )
  const setShowActiveSessions = useCallback(
    (payload: boolean) => {
      dispatch({ type: ActionType.SET_SHOW_ACTIVE_SESSIONS, payload: payload })
    },
    [dispatch]
  )

  const contextState = useMemo(
    () => ({
      state,
      setUserLocation,
      setLastServiceLocation,
      refreshUserLocation,
      refreshUserPois,
      setUserProfile,
      changeLocale,
      setBitcoinRate,
      setActiveSessions,
      removeActiveSession,
      updateActiveSession,
      setShowActiveSessions,
    }),
    [
      state,
      setUserLocation,
      setLastServiceLocation,
      refreshUserLocation,
      refreshUserPois,
      setUserProfile,
      changeLocale,
      setBitcoinRate,
      setActiveSessions,
      removeActiveSession,
      updateActiveSession,
      setShowActiveSessions,
    ]
  )

  return (
    <UserStateContext.Provider value={contextState}>
      <IntlProvider
        locale={state.localeType!}
        messages={getMessages(state.localeType!)}
        defaultLocale={getDefaultLocale()}
      >
        {children}
      </IntlProvider>
    </UserStateContext.Provider>
  )
}
