import { ReactComponent as RadioButtonOff } from '../icons/radio-button-off.svg'
import { ReactComponent as RadioButtonOn } from '../icons/radio-button-on.svg'
import { Dictionary } from '../types'
import { SVGIcon } from '../components/Map/icons'

export enum POIActivationType {
  ACTIVE_UNTIL_CANCEL = 'CANCEL',
  ACTIVE_UNTIL_TIME = 'TIME',
  ACTIVE_UNTIL_PRICE = 'PRICE',
}

export type POIActivationTypeData = Dictionary<
  POIActivationType,
  {
    label: POIActivationType
    displayName: string
    selected: SVGIcon
    deselected: SVGIcon
  }
>

export const activationMethodTypeIcons = {
  [POIActivationType.ACTIVE_UNTIL_CANCEL]: {
    selected: RadioButtonOn,
    deselected: RadioButtonOff,
  },
  [POIActivationType.ACTIVE_UNTIL_TIME]: {
    selected: RadioButtonOn,
    deselected: RadioButtonOff,
  },
  [POIActivationType.ACTIVE_UNTIL_PRICE]: {
    selected: RadioButtonOn,
    deselected: RadioButtonOff,
  },
}

export const activationTypeData: POIActivationTypeData = {
  [POIActivationType.ACTIVE_UNTIL_PRICE]: {
    label: POIActivationType.ACTIVE_UNTIL_PRICE,
    displayName: 'Active until Price',
    selected: activationMethodTypeIcons[POIActivationType.ACTIVE_UNTIL_PRICE].selected,
    deselected: activationMethodTypeIcons[POIActivationType.ACTIVE_UNTIL_PRICE].deselected,
  },
  [POIActivationType.ACTIVE_UNTIL_TIME]: {
    label: POIActivationType.ACTIVE_UNTIL_TIME,
    displayName: 'Active until Time',
    selected: activationMethodTypeIcons[POIActivationType.ACTIVE_UNTIL_TIME].selected,
    deselected: activationMethodTypeIcons[POIActivationType.ACTIVE_UNTIL_TIME].deselected,
  },
  [POIActivationType.ACTIVE_UNTIL_CANCEL]: {
    label: POIActivationType.ACTIVE_UNTIL_CANCEL,
    displayName: 'Active until Cancel',
    selected: activationMethodTypeIcons[POIActivationType.ACTIVE_UNTIL_CANCEL].selected,
    deselected: activationMethodTypeIcons[POIActivationType.ACTIVE_UNTIL_CANCEL].deselected,
  },
}
