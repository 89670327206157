import { POIAccess } from '../graphQLTypes'
import { AccecssTypeData } from '../types'
import { ReactComponent as ParkingIcon } from '../icons/deselected/icons-crowdenergy-158.svg'
import { ReactComponent as SocketIcon } from '../icons/deselected/icons-crowdenergy-161.svg'

export enum AccessTypeIcons {
  UNRESTRICTED = 'unrestrictedIcon',
  RESTRICTED = 'restrictedIcon',
}

export const accessTypeIcons = {
  [AccessTypeIcons.UNRESTRICTED]: {
    enabled: ParkingIcon,
    disabled: ParkingIcon,
  },
  [AccessTypeIcons.RESTRICTED]: {
    enabled: SocketIcon,
    disabled: SocketIcon,
  },
}

export const accessTypeData: AccecssTypeData = {
  [POIAccess.RESTRICTED]: {
    label: POIAccess.RESTRICTED,
    displayName: 'Restricted',
    enabledIcon: accessTypeIcons[AccessTypeIcons.RESTRICTED].enabled,
    disabledIcon: accessTypeIcons[AccessTypeIcons.RESTRICTED].disabled,
  },
  [POIAccess.UNRESTRICTED]: {
    label: POIAccess.UNRESTRICTED,
    displayName: 'Unrestricted',
    enabledIcon: accessTypeIcons[AccessTypeIcons.UNRESTRICTED].enabled,
    disabledIcon: accessTypeIcons[AccessTypeIcons.UNRESTRICTED].disabled,
  },
}
