import React, { useState } from 'react'
import './POI.css'
import { OtherPOI, OtherService } from '../../graphQLTypes'
import { FormattedMessage } from 'react-intl'
import Icon from '@ant-design/icons'
import { ReactComponent as IconUp } from '../../icons/arrow-up.svg'
import { ReactComponent as IconDown } from '../../icons/arrow-down.svg'

export function POIPriceInfo({ poi, service }: { poi: OtherPOI; service: OtherService }) {
  const [isAreaSelected, setIsAreaSelected] = useState<boolean>(true)

  function displayPriceperUnit(service: OtherService): string {
    const price = service.serviceSchedule.pricingProps.pricePerUnit || 0
    return price.toFixed(2) + ' ' + service.serviceSchedule.pricingProps.acceptedCurrency
  }

  function displayPriceperHour(service: OtherService): string {
    const price = service.serviceSchedule.pricingProps.pricePerHour || 0
    return price.toFixed(2) + ' ' + service.serviceSchedule.pricingProps.acceptedCurrency
  }

  return (
    <div className={'menu-item-container'}>
      <div className={'accessibility_top_container'}>
        <div>
          <h2>
            <FormattedMessage id={'poi.priceinfo'} />
          </h2>
        </div>
        <div className={'accessibility_top_container_hide_link'}>
          <a
            onClick={() => {
              setIsAreaSelected(!isAreaSelected)
            }}
          >
            <Icon
              style={{
                color: 'black',
              }}
              component={isAreaSelected ? IconUp : IconDown}
            />
          </a>
        </div>
      </div>

      <div className={isAreaSelected ? 'price_info_area show_selected_area' : 'price_info_area hide_selected_area'}>
        <div className={'poi_priceinfo'}>
          <div className={'poi_priceinfo_item'}>
            <FormattedMessage id={'poi.pricehour'} />
          </div>
          <div>{displayPriceperHour(service)}</div>
        </div>
        <div className={'poi_priceinfo'}>
          <div className={'poi_priceinfo_item'}>
            <FormattedMessage id={'poi.pricekw'} />
          </div>
          <div>{displayPriceperUnit(service)}</div>
        </div>
      </div>
    </div>
  )
}
