import { useContext } from 'react'

import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { UserStateContext } from '../../../store/userContext'
import { MenuBackButton } from '../../MenuItems/MenuBackButton'
import { MenuItem } from '../../MenuItems/MenuItem'

export default function POIMenuDefault() {
  const navigate = useNavigate()

  const { state } = useContext(UserStateContext)

  return (
    <div>
      <MenuBackButton onClick={() => navigate('/settings')} />
      <MenuItem
        label={<FormattedMessage id={'menu.pois'} defaultMessage="Service locations" />}
        config={{ isNavigable: false }}
      />

      {state.pois?.map((poi) => (
        <MenuItem
          key={poi.poiId}
          label={poi.poiName}
          config={{ isSubItem: true, isNavigable: true }}
          onClick={() => navigate('poi/' + encodeURIComponent(poi.poiId))}
        />
      ))}

      <div style={{ marginTop: '.5rem' }}>
        <MenuItem
          label={<FormattedMessage id={'menu.pois.addPoi'} defaultMessage="Add new location and service" />}
          config={{ isNavigable: true }}
          onClick={() => navigate('add')}
        />
      </div>
    </div>
  )
}
