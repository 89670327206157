import { LocaleType } from '../types'
import { messages } from '../data/lang/messages'
import { createIntl, createIntlCache } from 'react-intl'

export const getMessages = (locale: LocaleType) => {
  return messages[locale]
}

export const getDefaultLocale = (): LocaleType => {
  return LocaleType.EN
}

export const getIntlInstance = () => {
  const cache = createIntlCache()
  const locale = getDefaultLocale()
  return createIntl(
    {
      locale: locale,
      messages: getMessages(locale),
      defaultLocale: locale,
    },
    cache
  )
}
