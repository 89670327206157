import { GeneralPOI } from '../../graphQLTypes'
import { Icon } from '../Map/Icon'
import { ReactComponent as DashboardIconSelected } from '../../icons/favorite-on.svg'
import { ReactComponent as DashboardIcon } from '../../icons/favorite-off.svg'
import React, { useState } from 'react'
import './POI.css'

export function POIHeader({ poi }: { poi: GeneralPOI | undefined }) {
  const [isFavoriteSelected, setIsFavoriteSelected] = useState<boolean>(false)

  const handleIsFavoriteSelected = () => {
    setIsFavoriteSelected((prevState) => !prevState)
  }

  return (
    <div className={'availability_container_poi'}>
      <div className={'availability_container_poi_left'}>
        <h2>{poi!.poiName}</h2>
        <p>{poi!.description}</p>
      </div>
      <div className={'availability_container_poi_right'}>
        <Icon
          className="favorite_icon"
          selectedIcon={DashboardIconSelected}
          deselectedIcon={DashboardIcon}
          onClickHandlers={[() => handleIsFavoriteSelected()]}
          isSelected={isFavoriteSelected}
        />
      </div>
    </div>
  )
}
