import { useIntl } from 'react-intl'
import { ServiceStatus } from '../../../../graphQLTypes'

function ServiceStatusIcon({ serviceStatus }: { serviceStatus: ServiceStatus | undefined }) {
  const intl = useIntl()

  const title = intl.formatMessage({ id: 'serviceStatus.' + serviceStatus, defaultMessage: serviceStatus })

  if (serviceStatus === ServiceStatus.AVAILABLE) {
    return <span title={title}>&#128994;</span>
  }
  if (serviceStatus === ServiceStatus.IN_USE) {
    return <span title={title}>&#128308;</span>
  }
  if (serviceStatus === ServiceStatus.OFFLINE) {
    return <span title={title}>&#128992;</span>
  }

  return <span title={title}>&#11044;</span>
}

export default ServiceStatusIcon
