import { ConnectorOutletData, ConnectorOutletSmartSocket } from '../types'
import { ReactComponent as SmartSocketConnectorTypeA } from '../icons/plug_types_smart_sockets/icons-crowdenergy-236.svg'
import { ReactComponent as SmartSocketConnectorTypeASelected } from '../icons/plug_types_smart_sockets/icons-crowdenergy-270.svg'
import { ReactComponent as SmartSocketConnectorTypeB } from '../icons/plug_types_smart_sockets/icons-crowdenergy-237.svg'
import { ReactComponent as SmartSocketConnectorTypeBSelected } from '../icons/plug_types_smart_sockets/icons-crowdenergy-271.svg'
import { ReactComponent as SmartSocketConnectorTypeCSelected } from '../icons/plug_types_smart_sockets/icons-crowdenergy-272.svg'
import { ReactComponent as SmartSocketConnectorTypeC } from '../icons/plug_types_smart_sockets/icons-crowdenergy-238.svg'

import { ReactComponent as SmartSocketConnectorTypeD } from '../icons/plug_types_smart_sockets/icons-crowdenergy-239.svg'
import { ReactComponent as SmartSocketConnectorTypeDSelected } from '../icons/plug_types_smart_sockets/icons-crowdenergy-273.svg'
import { ReactComponent as SmartSocketConnectorTypeE } from '../icons/plug_types_smart_sockets/icons-crowdenergy-240.svg'
import { ReactComponent as SmartSocketConnectorTypeESelected } from '../icons/plug_types_smart_sockets/icons-crowdenergy-274.svg'
import { ReactComponent as SmartSocketConnectorTypeF } from '../icons/plug_types_smart_sockets/icons-crowdenergy-241.svg'
import { ReactComponent as SmartSocketConnectorTypeFSelected } from '../icons/plug_types_smart_sockets/icons-crowdenergy-275.svg'
import { ReactComponent as SmartSocketConnectorTypeGSelected } from '../icons/plug_types_smart_sockets/icons-crowdenergy-276.svg'
import { ReactComponent as SmartSocketConnectorTypeG } from '../icons/plug_types_smart_sockets/icons-crowdenergy-242.svg'

import { ReactComponent as SmartSocketConnectorTypeH } from '../icons/plug_types_smart_sockets/icons-crowdenergy-243.svg'
import { ReactComponent as SmartSocketConnectorTypeHSelected } from '../icons/plug_types_smart_sockets/icons-crowdenergy-277.svg'
import { ReactComponent as SmartSocketConnectorTypeI } from '../icons/plug_types_smart_sockets/icons-crowdenergy-244.svg'
import { ReactComponent as SmartSocketConnectorTypeISelected } from '../icons/plug_types_smart_sockets/icons-crowdenergy-278.svg'
import { ReactComponent as SmartSocketConnectorTypeJSelected } from '../icons/plug_types_smart_sockets/icons-crowdenergy-279.svg'
import { ReactComponent as SmartSocketConnectorTypeJ } from '../icons/plug_types_smart_sockets/icons-crowdenergy-245.svg'

import { ReactComponent as SmartSocketConnectorTypeK } from '../icons/plug_types_smart_sockets/icons-crowdenergy-246.svg'
import { ReactComponent as SmartSocketConnectorTypeKSelected } from '../icons/plug_types_smart_sockets/icons-crowdenergy-280.svg'
import { ReactComponent as SmartSocketConnectorTypeL } from '../icons/plug_types_smart_sockets/icons-crowdenergy-247.svg'
import { ReactComponent as SmartSocketConnectorTypeLSelected } from '../icons/plug_types_smart_sockets/icons-crowdenergy-281.svg'
import { ReactComponent as SmartSocketConnectorCaravan1Phase } from '../icons/plug_types_smart_sockets/icons-crowdenergy-248.svg'
import { ReactComponent as SmartSocketConnectorCaravan1PhaseSelected } from '../icons/plug_types_smart_sockets/icons-crowdenergy-282.svg'
import {
  ReactComponent as SmartSocketConnector3Phase16A,
  ReactComponent as SmartSocketConnector3Phase32A,
} from '../icons/plug_types_smart_sockets/icons-crowdenergy-249.svg'
import {
  ReactComponent as SmartSocketConnector3Phase16ASelected,
  ReactComponent as SmartSocketConnector3Phase32ASelected,
} from '../icons/plug_types_smart_sockets/icons-crowdenergy-283.svg'

export const connectorOutletSmartSocketIcons = {
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeA]: {
    deselected: SmartSocketConnectorTypeA,
    selected: SmartSocketConnectorTypeASelected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeB]: {
    deselected: SmartSocketConnectorTypeB,
    selected: SmartSocketConnectorTypeBSelected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeC]: {
    deselected: SmartSocketConnectorTypeC,
    selected: SmartSocketConnectorTypeCSelected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeD]: {
    deselected: SmartSocketConnectorTypeD,
    selected: SmartSocketConnectorTypeDSelected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeE]: {
    deselected: SmartSocketConnectorTypeE,
    selected: SmartSocketConnectorTypeESelected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeF]: {
    deselected: SmartSocketConnectorTypeF,
    selected: SmartSocketConnectorTypeFSelected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeG]: {
    deselected: SmartSocketConnectorTypeG,
    selected: SmartSocketConnectorTypeGSelected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeH]: {
    deselected: SmartSocketConnectorTypeH,
    selected: SmartSocketConnectorTypeHSelected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeI]: {
    deselected: SmartSocketConnectorTypeI,
    selected: SmartSocketConnectorTypeISelected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeJ]: {
    deselected: SmartSocketConnectorTypeJ,
    selected: SmartSocketConnectorTypeJSelected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeK]: {
    deselected: SmartSocketConnectorTypeK,
    selected: SmartSocketConnectorTypeKSelected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeL]: {
    deselected: SmartSocketConnectorTypeL,
    selected: SmartSocketConnectorTypeLSelected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorCaravan1Phase]: {
    deselected: SmartSocketConnectorCaravan1Phase,
    selected: SmartSocketConnectorCaravan1PhaseSelected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnector3Phase16A]: {
    deselected: SmartSocketConnector3Phase16A,
    selected: SmartSocketConnector3Phase16ASelected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnector3Phase32A]: {
    deselected: SmartSocketConnector3Phase32A,
    selected: SmartSocketConnector3Phase32ASelected,
  },
}

export const connectorOutletSmartSocketData: ConnectorOutletData = {
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeA]: {
    label: ConnectorOutletSmartSocket.SmartSocketConnectorTypeA,
    displayName: 'Type A',
    iconDeselected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeA].deselected,
    iconSelected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeA].selected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeB]: {
    label: ConnectorOutletSmartSocket.SmartSocketConnectorTypeB,
    displayName: 'Type B',
    iconDeselected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeB].deselected,
    iconSelected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeB].selected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeC]: {
    label: ConnectorOutletSmartSocket.SmartSocketConnectorTypeC,
    displayName: 'Type C',
    iconDeselected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeC].deselected,
    iconSelected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeC].selected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeD]: {
    label: ConnectorOutletSmartSocket.SmartSocketConnectorTypeD,
    displayName: 'Type D',
    iconDeselected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeD].deselected,
    iconSelected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeD].selected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeE]: {
    label: ConnectorOutletSmartSocket.SmartSocketConnectorTypeE,
    displayName: 'Type E',
    iconDeselected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeE].deselected,
    iconSelected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeE].selected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeF]: {
    label: ConnectorOutletSmartSocket.SmartSocketConnectorTypeF,
    displayName: 'Type F',
    iconDeselected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeF].deselected,
    iconSelected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeF].selected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeG]: {
    label: ConnectorOutletSmartSocket.SmartSocketConnectorTypeG,
    displayName: 'Type G',
    iconDeselected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeG].deselected,
    iconSelected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeG].selected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeH]: {
    label: ConnectorOutletSmartSocket.SmartSocketConnectorTypeH,
    displayName: 'Type H',
    iconDeselected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeH].deselected,
    iconSelected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeH].selected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeI]: {
    label: ConnectorOutletSmartSocket.SmartSocketConnectorTypeI,
    displayName: 'Type I',
    iconDeselected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeI].deselected,
    iconSelected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeI].selected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeJ]: {
    label: ConnectorOutletSmartSocket.SmartSocketConnectorTypeJ,
    displayName: 'Type J',
    iconDeselected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeJ].deselected,
    iconSelected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeJ].selected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeK]: {
    label: ConnectorOutletSmartSocket.SmartSocketConnectorTypeK,
    displayName: 'Type K',
    iconDeselected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeK].deselected,
    iconSelected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeK].selected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorTypeL]: {
    label: ConnectorOutletSmartSocket.SmartSocketConnectorTypeL,
    displayName: 'Type L',
    iconDeselected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeL].deselected,
    iconSelected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorTypeL].selected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnectorCaravan1Phase]: {
    label: ConnectorOutletSmartSocket.SmartSocketConnectorCaravan1Phase,
    displayName: 'Caravan 1-Phase',
    iconDeselected:
      connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorCaravan1Phase].deselected,
    iconSelected:
      connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnectorCaravan1Phase].selected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnector3Phase16A]: {
    label: ConnectorOutletSmartSocket.SmartSocketConnector3Phase16A,
    displayName: '3-Phase 16A',
    iconDeselected:
      connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnector3Phase16A].deselected,
    iconSelected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnector3Phase16A].selected,
  },
  [ConnectorOutletSmartSocket.SmartSocketConnector3Phase32A]: {
    label: ConnectorOutletSmartSocket.SmartSocketConnector3Phase32A,
    displayName: '3-Phase 32A',
    iconDeselected:
      connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnector3Phase32A].deselected,
    iconSelected: connectorOutletSmartSocketIcons[ConnectorOutletSmartSocket.SmartSocketConnector3Phase32A].selected,
  },
}
