import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import React, { Dispatch, SetStateAction } from 'react'
import BuyPaypalButtonInner from './BuyPaypalButtonInner'

interface Props {
  amount: number
  currency: string
  setFrozen: Dispatch<SetStateAction<boolean>>
}

export default function BuyPapalButton({ amount, currency, setFrozen }: Readonly<Props>) {
  /**
   * PayPal configuration
   */
  const paypalInitialOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID!,
    currency: currency.toUpperCase(),
    enableFunding: 'venmo',
  }

  return (
    <PayPalScriptProvider deferLoading={true} options={paypalInitialOptions}>
      <BuyPaypalButtonInner amount={amount} currency={currency} setFrozen={setFrozen} />
    </PayPalScriptProvider>
  )
}
