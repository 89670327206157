import React from 'react'
import { MenuItem } from '../../MenuItems/MenuItem'
import { MenuBackButton } from '../../MenuItems/MenuBackButton'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'

export function BalanceMenu() {
  const navigate = useNavigate()

  return (
    <div>
      <MenuBackButton onClick={() => navigate('/settings')} />
      <MenuItem label={<FormattedMessage id={'balances.balances'} />} config={{}} />

      <MenuItem
        label={<FormattedMessage id={'balances.buyBalanceCard'} />}
        onClick={() => navigate('buy')}
        config={{ isSubItem: true, isNavigable: true }}
      />
      <MenuItem
        label={<FormattedMessage id={'balances.viewBalanceHistory'} defaultMessage="View balance history" />}
        onClick={() => navigate('history')}
        config={{ isSubItem: true, isNavigable: true }}
      />
    </div>
  )
}
