import React, { useContext, useEffect } from 'react'
import { AcceptedCurrency } from '../../graphQLTypes'
import { UserStateContext } from '../../store/userContext'
import { formatCurrency } from '../../utils/currency'
import './MenuAmount.css'

function MenuAmount({ amount, currency }: { amount: number; currency: AcceptedCurrency }) {
  const { state, setBitcoinRate } = useContext(UserStateContext)

  // load bitcoin currency, currencly called twice at add service, if this is a issue, move it higher
  useEffect(() => {
    if (state.bitcoinEuroRate === undefined) {
      fetch('https://api.coinbase.com/v2/exchange-rates?currency=BTC')
        .then((x) => x.json())
        .then((result) => setBitcoinRate(result.data.rates.EUR))
    }
  }, [])

  if (currency === AcceptedCurrency.BTC) {
    const sats = Number(amount * 100000000).toFixed(0) + ' SATS'
    if (state.bitcoinEuroRate && state.bitcoinEuroRate > 0) {
      const eurs = formatCurrency(AcceptedCurrency.EUR, amount * state.bitcoinEuroRate)
      return (
        <span>
          <span className="menu-amount__eur">({eurs})</span> <span className="menu-amount__amount">{sats}</span>
        </span>
      )
    } else {
      return <span className="menu-amount__amount">{sats}</span>
    }
  }

  return <span className="menu-amount__amount">{formatCurrency(currency, amount)}</span>
}

export default MenuAmount
