import { ReactComponent as UserLocationIconSelected } from '../../icons/selected/icons-crowdenergy-192-selected.svg'
import { ReactComponent as FilterIconSelected } from '../../icons/selected/icons-crowdenergy-195-selected.svg'
import { ReactComponent as UserLocationIcon } from '../../icons/deselected/icons-crowdenergy-192.svg'
import { ReactComponent as FindIcon } from '../../icons/deselected/icons-crowdenergy-201.svg'
import { ReactComponent as FilterIcon } from '../../icons/deselected/icons-crowdenergy-195.svg'

import { ReactComponent as LocationIcon } from '../../icons/deselected/icons-crowdenergy-191.svg'
import { ReactComponent as DashboardIcon } from '../../icons/icons-crowdenergy-200-200.svg'
import { ReactComponent as BellIcon } from '../../icons/deselected/icons-crowdenergy-203.svg'
import { ReactComponent as SettingsIcon } from '../../icons/deselected/icons-crowdenergy-202.svg'
import { ReactComponent as LocationIconSelected } from '../../icons/selected/icons-crowdenergy-191-selected.svg'
import { ReactComponent as SettingsIconSelected } from '../../icons/selected/icons-crowdenergy-202-selected.svg'

import { ReactComponent as FavouriteIcon } from '../../icons/deselected/icons-crowdenergy-211-211.svg'
import { ReactComponent as DemandIcon } from '../../icons/deselected/icons-crowdenergy-194.svg'
import { ReactComponent as GreenChargingIcon } from '../../icons/deselected/icons-crowdenergy-196.svg'
import { ReactComponent as BlueChargingIcon } from '../../icons/deselected/icons-crowdenergy-197.svg'
import { ReactComponent as ParkingIcon } from '../../icons/deselected/icons-crowdenergy-158.svg'
import { ReactComponent as ChargerIcon } from '../../icons/deselected/icons-crowdenergy-186.svg'
import { ReactComponent as DemandIconSelected } from '../../icons/selected/icons-crowdenergy-194-selected.svg'
import { ReactComponent as GreenChargingIconSelected } from '../../icons/selected/icons-crowdenergy-196-selected.svg'
import { ReactComponent as BlueChargingIconSelected } from '../../icons/selected/icons-crowdenergy-197-selected.svg'
import { ReactComponent as ParkingIconSelected } from '../../icons/selected/icons-crowdenergy-158-selected.svg'
import { ReactComponent as ChargerIconSelected } from '../../icons/selected/icons-crowdenergy-186-selected.svg'
import { ReactComponent as LogoIcon } from '../../icons/logo-crowdenergy-s.svg'
import { ReactComponent as LockIcon } from '../../icons/deselected/icons-crowdenergy-198.svg'
import { ReactComponent as UnlockIcon } from '../../icons/deselected/icons-crowdenergy-199.svg'

import { ReactComponent as UserIcon } from '../../icons/deselected/icons-crowdenergy-171.svg'

import plusIcon from '../../icons/Plus-icon.png'
import shareIcon from '../../icons/Share-icon.png'

import { FunctionComponent, SVGProps } from 'react'

export enum TopMenuIcons {
  UserLocationIcon = 'userLocationIcon',
  FindIcon = 'findIcon',
  FilterIcon = 'filterIcon',
}

export enum TopMenuSettingsIcons {
  LockIcon = 'lockIcon',
}

export enum TopMenuSettingsIconsAuthenticated {
  UnlockIcon = 'unlockIcon',
}

export enum FilterMenuIcons {
  FavouriteIcon = 'favouriteIcon',
  DemandIcon = 'demandIcon',
  GreenChargingIcon = 'greenChargingIcon',
  BlueChargingIcon = 'blueChargingIcon',
  ParkingIcon = 'parkingIcon',
  ChargerIcon = 'chargerIcon',
}

export enum BottomMenuIcons {
  LocationIcon = 'locationIcon',
  Dashboard = 'dashboard',
  LogoIcon = 'logoIcon',
  BellIcon = 'bellIcon',
  SettingsIcon = 'settingsIcon',
}

export enum ActivableTopMenuIcons {
  FindIcon = 'findIcon',
  FilterIcon = 'filterIcon',
}

export enum ActivableBottomMenuIcons {
  LocationIcon = 'locationIcon',
  SettingsIcon = 'settingsIcon',
  Others = 'n/a',
}

export type ActivableMenuIcons = ActivableTopMenuIcons | ActivableBottomMenuIcons

export type SVGIcon = FunctionComponent<SVGProps<SVGSVGElement>>

export type IconLabel =
  | TopMenuIcons
  | FilterMenuIcons
  | BottomMenuIcons
  | TopMenuSettingsIcons
  | TopMenuSettingsIconsAuthenticated

export type Icons = [SVGIcon, SVGIcon, IconLabel]

export const topMenuIcons: Icons[] = [
  [UserLocationIconSelected, UserLocationIcon, TopMenuIcons.UserLocationIcon],
  [FindIcon, FindIcon, TopMenuIcons.FindIcon],
  [FilterIconSelected, FilterIcon, TopMenuIcons.FilterIcon],
]

export const topMenuSettingsIcons: Icons[] = [[LockIcon, LockIcon, TopMenuSettingsIcons.LockIcon]]
export const topMenuSettingsIconsAuthenticated: Icons[] = [
  [UnlockIcon, UnlockIcon, TopMenuSettingsIconsAuthenticated.UnlockIcon],
]

export const bottomMenuIcons: Icons[] = [
  [LocationIconSelected, LocationIcon, BottomMenuIcons.LocationIcon],
  [DashboardIcon, DashboardIcon, BottomMenuIcons.Dashboard],
  [LogoIcon, LogoIcon, BottomMenuIcons.LogoIcon],
  [BellIcon, BellIcon, BottomMenuIcons.BellIcon],
  [SettingsIconSelected, SettingsIcon, BottomMenuIcons.SettingsIcon],
]

export const sideMenuIcons: Icons[] = [
  [FavouriteIcon, FavouriteIcon, FilterMenuIcons.FavouriteIcon],
  [DemandIconSelected, DemandIcon, FilterMenuIcons.DemandIcon],
  [GreenChargingIconSelected, GreenChargingIcon, FilterMenuIcons.GreenChargingIcon],
  [BlueChargingIconSelected, BlueChargingIcon, FilterMenuIcons.BlueChargingIcon],
  [ParkingIconSelected, ParkingIcon, FilterMenuIcons.ParkingIcon],
  [ChargerIconSelected, ChargerIcon, FilterMenuIcons.ChargerIcon],
]

export const serviceDetailIcons = {
  locked: LockIcon,
  unlocked: UnlockIcon,
}

export enum SettingsIcons {
  UserIcon = 'userIcon',
}

export const settingsIcons = {
  [SettingsIcons.UserIcon]: UserIcon,
}

export enum IOSPopupIcons {
  ShareIcon = 'shareIcon',
  PlusIcon = 'plusIcon',
}

export const iOSPopupIcons = {
  [IOSPopupIcons.ShareIcon]: shareIcon,
  [IOSPopupIcons.PlusIcon]: plusIcon,
}
