import React from 'react'
import { MenuBackButton } from '../../MenuItems/MenuBackButton'
import { MenuItem } from '../../MenuItems/MenuItem'
import { FormattedMessage } from 'react-intl'
import { MenuLabel } from '../../MenuItems/MenuLabel'
import { MenuLinkIcon } from '../../MenuItems/MenuLinkIcon'
import { socialMediaData } from '../../../data/socialMediaData'
import { getBrowserVersionData, getOSInfo } from '../../../utils/common'
import { getAppVersion } from '../../../utils/appVersion'
import { useNavigate } from 'react-router-dom'

export function AboutMenu() {
  const navigate = useNavigate()

  return (
    <div>
      <MenuBackButton onClick={() => navigate('/settings')} />

      <MenuItem label={<FormattedMessage id={'about.about'} />} config={{}} />

      <MenuItem label={<FormattedMessage id={'about.version'} />} config={{ isSubItem: true }}>
        <MenuLabel
          label={<FormattedMessage id={'about.versionLabel'} values={getAppVersion()} />}
          config={{}}
          hasFixedWidth={false}
        />
      </MenuItem>

      <MenuItem
        label={<FormattedMessage id={'about.termsAndConditions'} />}
        config={{ isSubItem: true, isNavigable: true, isDisabled: false }}
        onClick={() => {
          window.open('https://www.crowdenergy.io/policy/5d5a4c47-b11c-402e-8525-3bac588f91cf_en.html', '_blank')
        }}
      />

      <MenuItem
        label={<FormattedMessage id={'about.privacy'} />}
        config={{ isSubItem: true, isNavigable: true, isDisabled: false }}
        onClick={() => {
          window.open('https://www.crowdenergy.io/policy/ecc0d8fc-ff92-4610-98cc-d8b7e258f497_en.html', '_blank')
        }}
      />

      <MenuItem label={<FormattedMessage id={'about.followUsOnTwitter'} />} config={{ isSubItem: true }}>
        <MenuLinkIcon icon={socialMediaData.twitter?.iconDeselected!} linkTo={'https://twitter.com/energy_crowd'} />
      </MenuItem>

      <MenuItem label={<FormattedMessage id={'about.likeUsOnFacebook'} />} config={{ isSubItem: true }}>
        <MenuLinkIcon icon={socialMediaData.facebook?.iconDeselected!} linkTo={'https://fb.me/crowdenergy.io'} />
      </MenuItem>

      <MenuItem label={<FormattedMessage id={'about.writeUsOnMessenger'} />} config={{ isSubItem: true }}>
        <MenuLinkIcon icon={socialMediaData.messenger?.iconDeselected!} linkTo={'https://m.me/crowdenergy.io'} />
      </MenuItem>

      <MenuItem label={<FormattedMessage id={'about.browserVersion'} />} config={{ isSubItem: true }}>
        <MenuLabel
          label={<FormattedMessage id={'about.browserVersionLabel'} values={getBrowserVersionData()} />}
          config={{}}
          hasFixedWidth={false}
        />
      </MenuItem>

      <MenuItem label={<FormattedMessage id={'about.osVersion'} />} config={{ isSubItem: true }}>
        <MenuLabel
          label={<FormattedMessage id={'about.osVersionLabel'} values={getOSInfo()} />}
          config={{}}
          hasFixedWidth={false}
        />
      </MenuItem>
      <div className={'menu-item__label menu-item__label--sub-item'}>
        <a href={'#'}>&copy;Crowd Energy</a>
      </div>
      <div className={'menu-item__label menu-item__label--sub-item'}>
        <a href="https://www.maptiler.com/copyright/" target="_blank" rel="noreferrer">
          {' '}
          &copy;MapTiler
        </a>
      </div>
      <div className={'menu-item__label menu-item__label--sub-item'}>
        <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noreferrer">
          {' '}
          &copy;OpenStreetMap contributors
        </a>
      </div>
    </div>
  )
}
