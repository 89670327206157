import React, { useContext } from 'react'
import { Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { getArrayOfValuesFromDictionary, isItemSelected } from '../../../utils/common'
import './LanguageDropdown.css'
import { UserStateContext } from '../../../store/userContext'
import { AllCurrency, Currency } from '../../../types'
import { useMutation } from '@apollo/client'
import { UPDATE_PROFILE } from '../../../mutations'
import { GET_MY_PROFILE } from '../../../queries'
import { Loader } from '@aws-amplify/ui-react'
import type { MenuProps } from 'antd'

export function CurrencyDropdown() {
  const { state } = useContext(UserStateContext)

  const [mutateFunction, { loading, error }] = useMutation(UPDATE_PROFILE, {
    refetchQueries: [GET_MY_PROFILE],
  })

  function changeCurrency(currencyType: Currency) {
    mutateFunction({
      variables: {
        input: {
          preferredCurrency: currencyType,
        },
      },
    })
  }

  if (loading) {
    return (
      <div>
        <Loader fr={undefined} /> updating ...
      </div>
    )
  }
  if (error) {
    return <div>error!</div>
  }

  const items: MenuProps['items'] = getArrayOfValuesFromDictionary(AllCurrency).map((currency) => {
    return {
      key: currency!,
      disabled: isItemSelected(state?.profile?.preferredCurrency, currency),
      onClick: () => changeCurrency(currency!),
      label: <span>{currency}</span>,
    }
  })

  return (
    <Menu>
      <Menu.Item>
        <Dropdown menu={{ items }}>
          <a href="/#" className="ant-dropdown-link language-dropdown__link" onClick={(e) => e.preventDefault()}>
            {state?.profile?.preferredCurrency || 'Currency'} <DownOutlined />
          </a>
        </Dropdown>
      </Menu.Item>
    </Menu>
  )
}
