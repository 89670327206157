export const mapConstants = {
  listNearbyPOIsQueryLimit: 10,
  defaultZoom: 13,
  defaultLatitude: 48.148598,
  defaultLongitude: 17.107748,
}

export const commonUtilsConstants = {
  maximumFractionDigits: 12,
}

export const setServiceDemandConstants = {
  USDLimitForEnablingBalanceCard: 10,
  BTCLimitForEnablingBalanceCard: 0.0005,
  USDLimitForDisplayingPayButton: 10,
  minimumAcceptedRadius: 0.5,
  maximumAcceptedRadius: 10,
  acceptedRadiusStep: 0.5,
}

// TODO: Include this info rather in addServiceMenuConfig
export const addServiceConstants = {
  minimumPricePerHour: 0.0,
  maximumPricePerHour: 10,
  pricePerHourStep: 0.5,
  minimumPricePerKW: 0,
  maximumPricePerKW: 2.5,
  pricePerKWStep: 0.05,
  serviceNameMinLength: 3,
  serviceNameMaxLength: 20,
  maxConnectorsInPanel: 4,
}

export const pricingProps = {
  USD: {
    minimumPricePerHour: 0,
    maximumPricePerHour: 10,
    pricePerHourStep: 0.5,
    minimumPricePerKW: 0,
    maximumPricePerKW: 2.5,
    pricePerKWStep: 0.05,
  },
  EUR: {
    minimumPricePerHour: 0,
    maximumPricePerHour: 10,
    pricePerHourStep: 0.5,
    minimumPricePerKW: 0,
    maximumPricePerKW: 2.5,
    pricePerKWStep: 0.05,
  },
  GBP: {
    minimumPricePerHour: 0,
    maximumPricePerHour: 10,
    pricePerHourStep: 0.5,
    minimumPricePerKW: 0,
    maximumPricePerKW: 2.5,
    pricePerKWStep: 0.05,
  },
  BTC: {
    minimumPricePerHour: 0,
    maximumPricePerHour: 0.0005,
    pricePerHourStep: 0.00001,
    minimumPricePerKW: 0,
    maximumPricePerKW: 0.0001,
    pricePerKWStep: 0.00001,
  },
}
