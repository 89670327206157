import React from 'react'
import { MenuBackButton } from '../../MenuItems/MenuBackButton'
import { useNavigate } from 'react-router-dom'
import { MenuItem } from '../../MenuItems/MenuItem'
import { FormattedMessage } from 'react-intl'
import { LIST_MY_BALANCE_HISTORY } from '../../../queries'
import { ListMyBalanceHistoryResponse } from '../../../graphQLTypes'
import { useQuery } from '@apollo/client'
import QueryResult from '../../QueryResult'
import BalanceHistoryTable from './BalanceHistoryTable'

export default function BalanceHistory() {
  const navigate = useNavigate()
  const onBackHandler = () => navigate('/settings/balance')

  const { loading, error, data } = useQuery<ListMyBalanceHistoryResponse>(LIST_MY_BALANCE_HISTORY, {
    fetchPolicy: 'cache-and-network',
  })

  return (
    <>
      <MenuBackButton onClick={onBackHandler} />

      <MenuItem label={<FormattedMessage id={'balanceHistory.title'} defaultMessage="Balance history" />} />

      <QueryResult loading={loading} error={error} data={data?.listMyBalanceHistory}>
        <BalanceHistoryTable data={data?.listMyBalanceHistory || []} />
      </QueryResult>
    </>
  )
}
