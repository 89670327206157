import React from 'react'
import { SVGIcon } from '../Map/icons'
import './MenuLinkIcon.css'
import Icon from '@ant-design/icons'

export function MenuLinkIcon({ icon, linkTo }: { icon: SVGIcon; linkTo: string }) {
  return (
    <div className={'menu-link-icon'}>
      <a className={'menu-link-icon__link'} href={linkTo} target="_blank" rel="noopener noreferrer">
        <Icon className={'link__icon'} component={icon} />
      </a>
    </div>
  )
}
