import { Authenticator } from '@aws-amplify/ui-react'
import './../../../node_modules/@aws-amplify/ui-react/dist/styles.css'
import React from 'react'
import './Settings.css'
import SettingsWrapper from './SettingsWrapper'
import { UserInfo } from './UserInfo'

export function Settings() {
  return (
    <SettingsWrapper>
      <div className="settings__user-section">
        <Authenticator signUpAttributes={['email']} className={'auth__authentificator'}>
          {() => <UserInfo />}
        </Authenticator>
      </div>
    </SettingsWrapper>
  )
}
