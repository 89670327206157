import { Flex, Spin } from 'antd'
import React from 'react'

export default function Spinner({ message }: Readonly<{ message: string }>) {
  return (
    <Flex align="center">
      <Spin /> <span style={{ marginLeft: '.5rem' }}>{message}</span>
    </Flex>
  )
}
