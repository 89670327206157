import React, { useState } from 'react'
import { OtherPOI, OtherService } from '../../graphQLTypes'
import './POI.css'
import POIServiceActions from './POIServiceActions'
import { POIHeader } from './POIHeader'
import { POIAccessability } from './POIAccessability'
import { POIActivation } from './POIActivation'
import { POIPriceInfo } from './POIPriceInfo'
import { POIServiceName } from './POIServiceName'
import { POIActivationType } from '../../data/activationTypeData'
import { Dayjs } from 'dayjs'

export type POIActivationProperties =
  | {
      type: POIActivationType.ACTIVE_UNTIL_CANCEL
    }
  | {
      type: POIActivationType.ACTIVE_UNTIL_PRICE
      price: number
    }
  | {
      type: POIActivationType.ACTIVE_UNTIL_TIME
      time: Dayjs
    }

export default function POI({ poi }: { poi: OtherPOI | undefined }) {
  const [activationProperties, setActivationProperties] = useState<POIActivationProperties>({
    type: POIActivationType.ACTIVE_UNTIL_CANCEL,
  })

  return (
    <div className="poi__poi">
      <POIHeader poi={poi} />
      <POIAccessability poi={poi} />

      <div className="poi__poiServices">
        {poi!.services.map((service: OtherService, index) => (
          <div key={index}>
            <POIServiceName poi={poi!} service={service} key={'service-name-' + index} />
            <POIPriceInfo poi={poi!} service={service} key={'price-info-' + index} />
            {/* <POIPayment poi={poi!} service={service} key={'payment-' + index} /> */}
            <POIActivation
              poi={poi!}
              service={service}
              key={'activation-' + index}
              activationProperties={activationProperties}
              setActivationProperties={setActivationProperties}
            />
            <div className="poi__actions">
              <POIServiceActions
                poi={poi!}
                service={service}
                activationProperties={activationProperties}
                key={'service-' + index}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
