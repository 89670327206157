import React, { ReactNode } from 'react'
import { SelectableIcon } from '../SelectableIcon'
import { SVGIcon } from '../Map/icons'
import './MenuSelectableIcon.css'
import { isBoolean } from '../../utils/common'
import { MenuLabel } from './MenuLabel'
import { MenuSelectableIconConfig } from '../../types'

const isSelectableIconSelected = (label?: string, isSelected?: Function | boolean, isSelectable?: boolean): boolean =>
  isSelectable === false ? false : isBoolean(isSelected) ? isSelected : isSelected!(label)

const isMenuSelectableIconClickable = (label?: string, isEnabled?: Function | boolean, isSelectable?: boolean) =>
  isSelectable && isSelectableIconEnabled(label, isEnabled)

const isSelectableIconEnabled = (label?: string, isEnabled?: Function | boolean) =>
  isEnabled === undefined || (isBoolean(isEnabled) ? isEnabled : isEnabled(label))

export function MenuSelectableIcon({
  isSelected,
  onClick,
  iconSelected,
  iconDeselected,
  label,
  displayName,
  config,
  iconDisabled,
}: {
  isSelected?: Function | boolean
  onClick?: Function
  label?: string
  displayName?: string | ReactNode
  iconSelected?: SVGIcon
  iconDeselected: SVGIcon
  iconDisabled?: SVGIcon
  config?: MenuSelectableIconConfig
}) {
  const { isSelectable, isVisible, isEnabled, hasFixedWidth } = config || {}
  const onIconClick = isMenuSelectableIconClickable(label, isEnabled, isSelectable) ? () => onClick!() : undefined
  return isVisible ? (
    <div className={`menu-selectable-icon ${hasFixedWidth ? 'menu-selectable-icon__width25' : ''}`}>
      <SelectableIcon
        config={{
          isSelected:
            isSelectableIconEnabled(label, isEnabled) && isSelectableIconSelected(label, isSelected, isSelectable),
          isEnabled: isSelectableIconEnabled(label, isEnabled),
        }}
        onClick={onIconClick}
        iconSelected={iconSelected}
        iconDeselected={iconDeselected}
        iconDisabled={iconDisabled}
        className={'menu-selectable-icon__icon'}
      />
      {displayName && <MenuLabel label={displayName} hasFixedWidth={hasFixedWidth} />}
    </div>
  ) : null
}
