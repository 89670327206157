import React from 'react'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, createHttpLink } from '@apollo/client'
import { privateEndpointConfig, publicEndpointConfig } from '../awsConfig'
import { Theme, ThemeProvider, useAuthenticator } from '@aws-amplify/ui-react'
import { isAuthenticated } from '../utils/auth'
import MapView from './Map/MapView'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import SessionHistory from './SessionHistory/SessionHistory'
import { Settings } from './Settings/Settings'
import POIWrapper from './POI/POIWrapper'
import { ProfileMenu } from './Menu/Profile/ProfileMenu'
import { POIMenu } from './Menu/POI/POIMenu'
import BalanceMenuBuy from './Menu/Balance/BalanceMenuBuy'
import { BalanceMenu } from './Menu/Balance/BalanceMenu'
import { AboutMenu } from './Menu/About/AboutMenu'
import RootMenu from './Menu/RootMenu'
import { AddPOIMenu } from './Menu/POI/AddPOIMenu/AddPOIMenu'
import POIMenuDefault from './Menu/POI/POIMenuDefault'
import { ServiceMenuReplaceDevice } from './Menu/POI/ServiceMenu/ServiceMenuReplaceDevice'
import { ServiceMenuAttachDevice } from './Menu/POI/ServiceMenu/ServiceMenuAttachDevice'
import { ServiceMenuDelete } from './Menu/POI/ServiceMenu/ServiceMenuDelete'
import { ServiceMenu } from './Menu/POI/ServiceMenu/ServiceMenu'
import AddServiceMenu from './Menu/POI/AddServiceMenu/AddServiceMenu'
import { POIMenuSelected } from './Menu/POI/POIMenuSelected'
import SessionWrapper from './Session/SessionWrapper'
import BalanceHistory from './Menu/Balance/BalanceHistory'
import { ServiceMenuUpdate } from './Menu/POI/ServiceMenu/ServiceMenuUpdate'

const publicLink = ApolloLink.from([
  createAuthLink(publicEndpointConfig),
  createSubscriptionHandshakeLink(publicEndpointConfig, createHttpLink({ uri: publicEndpointConfig.url })),
])

const privateLink = ApolloLink.from([
  createAuthLink(privateEndpointConfig),
  createSubscriptionHandshakeLink(privateEndpointConfig, createHttpLink({ uri: privateEndpointConfig.url })),
])

const publicClient = new ApolloClient({ link: publicLink, cache: new InMemoryCache() })
const privateClient = new ApolloClient({ link: privateLink, cache: new InMemoryCache() })

const router = createBrowserRouter([
  {
    path: '/',
    element: <MapView />,
    children: [
      { path: 'sessions/history', element: <SessionHistory /> },
      { path: 'sessions/*', element: <SessionWrapper /> },
      {
        path: 'settings/*',
        element: <Settings />,
        children: [
          { path: 'profile/*', element: <ProfileMenu /> },
          {
            path: 'pois/*',
            element: <POIMenu />,
            children: [
              { path: 'add', element: <AddPOIMenu /> },
              { path: 'poi/:poiId', element: <POIMenuSelected /> },
              { path: 'poi/:poiId/addService', element: <AddServiceMenu /> },
              { path: 'service/:poiId/:serviceId', element: <ServiceMenu /> },
              { path: 'service/:poiId/:serviceId/edit', element: <ServiceMenuUpdate /> },
              { path: 'service/:poiId/:serviceId/delete', element: <ServiceMenuDelete /> },
              { path: 'service/:poiId/:serviceId/attachDevice', element: <ServiceMenuAttachDevice /> },
              { path: 'service/:poiId/:serviceId/replaceDevice', element: <ServiceMenuReplaceDevice /> },
              { path: '*', element: <POIMenuDefault /> },
            ],
          },
          { path: 'balance/history', element: <BalanceHistory /> },
          { path: 'balance/buy', element: <BalanceMenuBuy /> },
          { path: 'balance/*', element: <BalanceMenu /> },
          { path: 'about/*', element: <AboutMenu /> },
          { path: '*', element: <RootMenu /> },
        ],
      },
      { path: 'poi/:poiId', element: <POIWrapper /> },
      { path: '*', element: <div /> },
    ],
  },
])

const theme: Theme = {
  name: 'my-theme',
  tokens: {
    colors: {
      primary: {
        10: '#ffecd6',
        20: '#ffd9ad',
        40: '#facd6c',
        80: '#fcac04',
        90: '#FF9900',
        100: '#ff9400',
      },
    },
  },
}

export function AppWithAuth() {
  const { authStatus } = useAuthenticator((context) => [context.authStatus])

  const client = isAuthenticated(authStatus) ? privateClient : publicClient

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </ApolloProvider>
  )
}
