import React, { useContext, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { UserStateContext } from '../../../store/userContext'
import { MenuBackButton } from '../../MenuItems/MenuBackButton'
import { MenuItem } from '../../MenuItems/MenuItem'
import { FormattedMessage } from 'react-intl'
import { MyService } from '../../../graphQLTypes'
import { RightOutlined } from '@ant-design/icons'
import './POIMenuSelected.scss'

export function POIMenuSelected() {
  const navigate = useNavigate()
  const params = useParams()
  const { state } = useContext(UserStateContext)

  // extract selected POI
  const poi = state.pois?.find((x) => x.poiId === params.poiId)

  // when poi does not exists
  useEffect(() => {
    if (poi == null) {
      navigate('/settings/pois')
    }
  })

  const navigateAddService = () => {
    navigate('/settings/pois/poi/' + encodeURIComponent(poi!.poiId) + '/addService')
  }

  // when service is deleted, this will prevent rendering this poi
  if (poi == null) {
    return <span />
  }

  const onClickService = (service: MyService) => {
    return () =>
      navigate('/settings/pois/service/' + encodeURIComponent(poi.poiId) + '/' + encodeURIComponent(service.serviceId))
  }

  return (
    <div>
      <MenuBackButton onClick={() => navigate('/settings/pois')} />

      <MenuItem label="Service location" config={{ isNavigable: false }} />

      <div className="POIMenuSelected__props">
        <p className="POIMenuSelected__props_description">{poi.description}</p>

        <label className="POIMenuSelected__props_label">Access:</label>
        <p className="POIMenuSelected__props_item">
          <FormattedMessage id={'poi.access.' + poi!.access} />
        </p>

        <label className="POIMenuSelected__props_label">Location:</label>
        <p className="POIMenuSelected__props_item">
          <FormattedMessage id={'poi.locationType.' + poi!.locationType} />
        </p>

        <label className="POIMenuSelected__props_label">Type:</label>
        <p className="POIMenuSelected__props_item">
          <FormattedMessage id={'poi.type.' + poi!.poiType} />
        </p>

        <label className="POIMenuSelected__props_services_label">Services</label>
        <ul className="POIMenuSelected__props_services">
          {poi &&
            poi.services.map((service) => (
              <li key={service.serviceId} onClick={onClickService(service)} className="POIMenuSelected__props_link">
                {service.serviceName}
                <RightOutlined className="menu-item__arrow" />
              </li>
            ))}
        </ul>
      </div>

      <div>
        <MenuItem
          label={<FormattedMessage id={'menu.poi.addService'} defaultMessage="Add new service" />}
          config={{ isNavigable: true }}
          onClick={navigateAddService}
        />
      </div>
    </div>
  )
}
