import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MenuBackButton } from '../../MenuItems/MenuBackButton'
import { FormattedMessage } from 'react-intl'
import { MenuItem } from '../../MenuItems/MenuItem'
import { Segmented, Slider } from 'antd'
import './BalanceMenuBuy.scss'
import { NonCryptoCurrency } from '../../../data/currencyTypes'
import { UserStateContext } from '../../../store/userContext'
import { getCurrency, formatCurrency } from '../../../utils/currency'
import BuyPapalButton from './BuyPapalButton'
import { AcceptedCurrency } from '../../../graphQLTypes'

const paymentTypes = [
  {
    label: <span>PayPal</span>,
    value: 'Paypal',
  },
  /*
  {
    label: <span>Bitcoin</span>,
    value: 'Bitcoin',
  },
  */
]

const paymentCurrencies = Object.values(NonCryptoCurrency).map((item) => {
  const CurrIcon = getCurrency(item)!.deselectedIcon
  return {
    label: (
      <div className="BalanceMenuBuy__currency">
        <CurrIcon></CurrIcon>
        <span>{item}</span>
      </div>
    ),
    value: item,
  }
})

export default function BalanceMenuBuy() {
  const navigate = useNavigate()
  const { state } = useContext(UserStateContext)

  const [paymentType, setPaymentType] = useState('Paypal')
  const [paymentCurrency, setPaymentCurrency] = useState<string>(
    state.profile?.preferredCurrency ?? AcceptedCurrency.EUR
  )
  const [paymentAmount, setPaymentAmount] = useState(5)

  // during payment we will freeze chaning props
  const [frozen, setFrozen] = useState(false)

  const onBackHandler = () => navigate('/settings/balance')
  const onPaymentTypeHandler = (data: string | number) => setPaymentType(data.toString())
  const onPaymentCurrencyHandler = (data: string | number) => setPaymentCurrency(data?.toString())

  return (
    <div>
      <MenuBackButton onClick={onBackHandler} />
      <MenuItem label={<FormattedMessage id={'buyBalanceCard.buyBalanceCard'} />} />

      <div className="BalanceMenuBuy">
        <p className="BalanceMenuBuy__label">Payment type</p>
        <Segmented block options={paymentTypes} onChange={onPaymentTypeHandler} value={paymentType} disabled={frozen} />

        <p className="BalanceMenuBuy__label">Payment currency</p>
        <Segmented
          block
          options={paymentCurrencies}
          onChange={onPaymentCurrencyHandler}
          value={paymentCurrency}
          disabled={frozen}
        />

        <p className="BalanceMenuBuy__label">Amount</p>
        <Slider min={1} max={75} step={1} onChange={setPaymentAmount} disabled={frozen} />
        <p className="BalanceMenuBuy__amount">{formatCurrency(paymentCurrency, paymentAmount)}</p>

        {paymentType === 'Paypal' && (
          <div style={{ margin: '1rem' }}>
            <BuyPapalButton amount={paymentAmount} currency={paymentCurrency} setFrozen={setFrozen} />
          </div>
        )}
      </div>
    </div>
  )
}
