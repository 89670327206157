import React from 'react'
import { Menu, MenuType } from './Menu'
import { ActivableTopMenuIcons, FilterMenuIcons, IconLabel, Icons, sideMenuIcons } from './icons'
import './TopMenu.css'
import { Dictionary } from '../../types'
import { isFilterMenuDisplayed } from '../../utils/common'

export function TopMenu({
  serviceFilters,
  activeTopMenuIcon,
  changeActiveTopMenuIcon,
  icons,
  onClickHandlers,
}: {
  serviceFilters: Dictionary<FilterMenuIcons, [boolean, Function]>
  activeTopMenuIcon?: ActivableTopMenuIcons
  changeActiveTopMenuIcon: Function
  icons: Icons[]
  onClickHandlers?: Dictionary<IconLabel, Function>
}) {
  return (
    <div>
      <div className={'top-menu-container'}>
        <Menu
          icons={icons}
          type={MenuType.Horizontal}
          className={'menu--top'}
          iconClassName={'top-menu__icon'}
          activeMainIconName={activeTopMenuIcon}
          serviceFilters={{}}
          changeActiveMainIcon={changeActiveTopMenuIcon}
          onClickHandlers={onClickHandlers}
        />
      </div>

      {isFilterMenuDisplayed(activeTopMenuIcon) && (
        <Menu
          icons={sideMenuIcons}
          type={MenuType.Vertical}
          serviceFilters={serviceFilters}
          activeMainIconName={activeTopMenuIcon}
          changeActiveMainIcon={changeActiveTopMenuIcon}
        />
      )}
    </div>
  )
}

export default TopMenu
