import React from 'react'
import { Currency } from '../../../../types'
import { getCurrencyData } from '../../../../utils/currency'
import { MenuSelectable } from '../../../MenuItems/MenuSelectable'
import { MenuSelectableIcon } from '../../../MenuItems/MenuSelectableIcon'

export function SelectAcceptedCurrency({
  acceptedCurrency,
  setAcceptedCurrency,
}: {
  acceptedCurrency: Currency
  setAcceptedCurrency: Function
}) {
  const currencyData = getCurrencyData()

  return (
    <MenuSelectable>
      {Object.values(currencyData).map((currency) => (
        <MenuSelectableIcon
          key={currency.label}
          onClick={() => setAcceptedCurrency(currency.label)}
          label={currency.label}
          displayName={currency.longName}
          iconSelected={currency.selectedIcon}
          iconDeselected={currency.deselectedIcon!}
          isSelected={currency.label === acceptedCurrency}
          config={{ isSelectable: true, isVisible: true }}
        />
      ))}
    </MenuSelectable>
  )
}
