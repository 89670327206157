import { CurrencyData } from '../types'
import { ReactComponent as USDIconSelected } from '../icons/selected/icons-crowdenergy-178.svg'
import { ReactComponent as USDIcon } from '../icons/deselected/icons-crowdenergy-178.svg'
import { ReactComponent as EURIconSelected } from '../icons/selected/icons-crowdenergy-179.svg'
import { ReactComponent as EURIcon } from '../icons/deselected/icons-crowdenergy-179.svg'
import { ReactComponent as BitcoinIconSelected } from '../icons/selected/icons-crowdenergy-180.svg'
import { ReactComponent as BitcoinIcon } from '../icons/deselected/icons-crowdenergy-180.svg'
import { ReactComponent as GBPIconSelected } from '../icons/selected/icons-crowdenergy-BBP-223.svg'
import { ReactComponent as GBPIcon } from '../icons/deselected/icons-crowdenergy-BBP-222.svg'

export enum CurrencyIcons {
  USDIcon = 'usdIcon',
  EURIcon = 'eurIcon',
  BitcoinIcon = 'bitcoinIcon',
  GBPIcon = 'gbpIcon',
}

export const currencyIcons = {
  [CurrencyIcons.USDIcon]: {
    selected: USDIconSelected,
    deselected: USDIcon,
  },
  [CurrencyIcons.EURIcon]: {
    selected: EURIconSelected,
    deselected: EURIcon,
  },
  [CurrencyIcons.BitcoinIcon]: {
    selected: BitcoinIconSelected,
    deselected: BitcoinIcon,
  },
  [CurrencyIcons.GBPIcon]: {
    selected: GBPIconSelected,
    deselected: GBPIcon,
  },
}

export const currencyData: CurrencyData = {
  USD: {
    label: 'USD',
    longName: 'USD',
    selectedIcon: currencyIcons[CurrencyIcons.USDIcon].selected,
    deselectedIcon: currencyIcons[CurrencyIcons.USDIcon].deselected,
  },
  EUR: {
    label: 'EUR',
    longName: 'EUR',
    selectedIcon: currencyIcons[CurrencyIcons.EURIcon].selected,
    deselectedIcon: currencyIcons[CurrencyIcons.EURIcon].deselected,
  },
  GBP: {
    label: 'GBP',
    longName: 'GBP',
    selectedIcon: currencyIcons[CurrencyIcons.GBPIcon].selected,
    deselectedIcon: currencyIcons[CurrencyIcons.GBPIcon].deselected,
  },
  BTC: {
    label: 'BTC',
    longName: 'Bitcoin',
    selectedIcon: currencyIcons[CurrencyIcons.BitcoinIcon].selected,
    deselectedIcon: currencyIcons[CurrencyIcons.BitcoinIcon].deselected,
  },
}
