import { Alert, Button, Col, Input, Row } from 'antd'
import React, { useContext, useState } from 'react'

import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { UserStateContext } from '../../../../store/userContext'
import { MenuBackButton } from '../../../MenuItems/MenuBackButton'
import { MenuItem } from '../../../MenuItems/MenuItem'
import { MenuSliderWithLabel } from '../../../MenuItems/MenuSliderWithLabel'
import { FormattedMessage } from 'react-intl'
import MenuAmount from '../../../MenuItems/MenuAmount'
import { addServiceConstants, pricingProps } from '../../../../data/constants'
import { determineIfServiceHasPriceByKW } from '../../../../utils/service'
import { UPDATE_SERVICE } from '../../../../mutations'
import { useMutation } from '@apollo/client'
import { GET_MY_POIS } from '../../../../queries'
import { MyPOI, MyService } from '../../../../graphQLTypes'
import Spinner from '../../../UI/Spinner'

function ServiceMenuEditInternal({ poi, service }: Readonly<{ poi: MyPOI; service: MyService }>) {
  const navigate = useNavigate()
  const pricing = service.serviceSchedule?.pricingProps
  const initServiceName = service.serviceName
  const initPricePerHour = service.serviceSchedule?.pricingProps?.pricePerHour ?? 0
  const initPricePerUnit = service.serviceSchedule?.pricingProps?.pricePerUnit ?? 0

  // editation state
  const [serviceName, setServiceName] = useState(initServiceName)
  const [pricePerHour, setPricePerHour] = useState(initPricePerHour)
  const [pricePerUnit, setPricePerUnit] = useState(initPricePerUnit)

  // to determine if some state changed
  const isModified =
    initServiceName !== serviceName || initPricePerHour !== pricePerHour || initPricePerUnit !== pricePerUnit

  // has service price per unit (not every service has this)
  const showPricePerUnit = determineIfServiceHasPriceByKW(service.serviceType)

  // mutation to change data
  const [mutation, mutationResult] = useMutation(UPDATE_SERVICE, {
    refetchQueries: [GET_MY_POIS],
    awaitRefetchQueries: true,
  })

  const handleBackButton = () => {
    navigate('/settings/pois/service/' + encodeURIComponent(poi.poiId) + '/' + encodeURIComponent(service.serviceId))
  }

  const handleSubmit = () => {
    // what everything to send into mutation
    const data = {
      poiId: poi.poiId,
      serviceId: service.serviceId,
      serviceName: initServiceName !== serviceName ? serviceName : undefined,
      serviceSchedule: {
        pricingProps: {
          pricePerHour: initPricePerHour !== pricePerHour ? pricePerHour : undefined,
          pricePerUnit: initPricePerUnit !== pricePerUnit ? pricePerUnit : undefined,
        },
      },
    }

    mutation({
      variables: {
        input: data,
      },
    }).then(() => {
      handleBackButton()
    })
  }

  return (
    <div>
      <MenuBackButton onClick={handleBackButton} />
      <Row style={{ marginBottom: '2rem' }}>
        <Col span={24}>
          <MenuItem label="Edit service" config={{ isNavigable: false }}>
            <strong>{service.serviceName}</strong>
          </MenuItem>
        </Col>
      </Row>

      <div>Service name:</div>
      <Input
        value={serviceName}
        onChange={(e) => setServiceName(e.target.value)}
        minLength={addServiceConstants.serviceNameMinLength}
        maxLength={addServiceConstants.serviceNameMaxLength}
        style={{ marginBottom: '1rem', marginTop: '.5rem' }}
      />

      {pricing && (
        <MenuSliderWithLabel
          menuSliderProps={{
            value: pricePerHour,
            setValue: setPricePerHour,
          }}
          menuItemLabel={<FormattedMessage id={'addService.pricePerHour'} />}
          label={<MenuAmount amount={pricePerHour} currency={pricing.acceptedCurrency} />}
          config={{
            menuSliderConfig: {
              isVisible: true,
              min: pricingProps[pricing.acceptedCurrency].minimumPricePerHour,
              max: pricingProps[pricing.acceptedCurrency].maximumPricePerHour,
              step: pricingProps[pricing.acceptedCurrency].pricePerHourStep,
            },
          }}
        />
      )}

      {showPricePerUnit && pricing && (
        <MenuSliderWithLabel
          menuSliderProps={{
            value: pricePerUnit,
            setValue: setPricePerUnit,
          }}
          menuItemLabel={<FormattedMessage id={'addService.pricePerKW'} />}
          label={<MenuAmount amount={pricePerUnit} currency={pricing.acceptedCurrency} />}
          config={{
            menuSliderConfig: {
              isVisible: true,
              min: pricingProps[pricing.acceptedCurrency].minimumPricePerKW,
              max: pricingProps[pricing.acceptedCurrency].maximumPricePerKW,
              step: pricingProps[pricing.acceptedCurrency].pricePerKWStep,
            },
          }}
        />
      )}

      {mutationResult.loading && <Spinner message="Updating ..." />}
      {mutationResult.error && (
        <Alert message={'Error: ' + mutationResult.error.message} type="error" showIcon={true} />
      )}
      {!mutationResult.loading && (
        <Button style={{ marginTop: '.5rem' }} onClick={handleSubmit} type="primary" disabled={!isModified}>
          <FormattedMessage id={'ServiceMenuEdit.submit'} defaultMessage="Submit" />
        </Button>
      )}
    </div>
  )
}

/**
 * Just a wrapper to extract current POI and SERVICE from already loaded data in user context.
 */
export function ServiceMenuUpdate() {
  const params = useParams()
  const { state } = useContext(UserStateContext)

  // extract selected POI and service
  const poi = state.pois!.find((x) => x.poiId === params.poiId)
  const service = poi?.services.find((x) => x.serviceId === params.serviceId)
  if (poi == null) {
    return <Navigate to="/settings/pois/" />
  }
  if (service == null) {
    return <Navigate to={'/settings/pois/poi/' + encodeURIComponent(poi.poiId)} />
  }

  return <ServiceMenuEditInternal poi={poi} service={service} />
}
