import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button } from 'antd'
import { MQTTContext } from '../../store/mqttContext'
import { useApolloClient, useMutation } from '@apollo/client'
import { SERVICE_STOP } from '../../mutations'
import { GET_POI, LIST_MY_ACTIVE_SESSIONS } from '../../queries'
import { Loader } from '@aws-amplify/ui-react'
import { FormattedMessage } from 'react-intl'

interface POIServiceActionStopProps {
  poiId: string
  serviceId: string
  sessionId: string
}

export default function POIServiceActionStop({ poiId, serviceId, sessionId }: Readonly<POIServiceActionStopProps>) {
  const mqttCtx = useContext(MQTTContext)
  const apolloClient = useApolloClient()

  // changing over time with loading indicator
  const [progress, setProgress] = useState<string | undefined>()

  // will be displayed in red when something goes wrong
  const [error, setError] = useState<string | undefined>()

  const setErrorMessage = (message: string) => {
    setProgress(undefined)
    setError(message)
  }

  // mutation
  const [serviceStopMutation] = useMutation(SERVICE_STOP, {
    onError: () => {
      setErrorMessage('Sending of stop command ended with error!')
      mqttCtx.setListen(false)
    },
    onCompleted: (data) => {
      setProgress('Waiting for device stop confirmation')
    },
  })

  // onclick handler
  const handleStart = () => {
    mqttCtx.setListen(true)
    setProgress('Sending stop to device')
    serviceStopMutation({
      variables: {
        input: {
          poiId,
          serviceId,
          sessionId,
        },
      },
    })
  }

  // called when MQTT message arrives
  useEffect(() => {
    if (mqttCtx.finishedSessions.includes(sessionId)) {
      mqttCtx.setListen(false)
      setProgress('Success')
      apolloClient.refetchQueries({
        include: [GET_POI, LIST_MY_ACTIVE_SESSIONS],
      })
    }
  }, [apolloClient, sessionId, mqttCtx, mqttCtx.finishedSessions])

  return (
    <div>
      {!progress && (
        <Button type="primary" onClick={handleStart}>
          STOP Service
        </Button>
      )}
      {progress && (
        <div className="service_start__loader">
          <Loader fr={undefined} size="large" /> <span className="service_start__loader-text"> {progress}</span> ...
        </div>
      )}

      {error && <Alert message={<FormattedMessage id={error} defaultMessage={error} />} type="error" />}
    </div>
  )
}
