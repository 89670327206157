import { ConnectorOutletData, ConnectorOutletEVCharger } from '../types'
import {
  ReactComponent as MennekesType2,
  ReactComponent as TeslaSuperchargerV2EU,
} from '../icons/plug-types-EV_charger/icons-crowdenergy-264.svg'
import {
  ReactComponent as MennekesType2Selected,
  ReactComponent as TeslaSuperchargerV2EUSelected,
} from '../icons/plug-types-EV_charger/icons-crowdenergy-270.svg'
import { ReactComponent as J1772Type1 } from '../icons/plug-types-EV_charger/icons-crowdenergy-262.svg'
import { ReactComponent as J1772Type1Selected } from '../icons/plug-types-EV_charger/icons-crowdenergy-268.svg'
import { ReactComponent as TeslaSuperchargerV3EU } from '../icons/plug-types-EV_charger/icons-crowdenergy-337.svg'
import { ReactComponent as TeslaSuperchargerV3EUSelected } from '../icons/plug-types-EV_charger/icons-crowdenergy-338.svg'
import { ReactComponent as TeslaSuperchargerUS } from '../icons/plug-types-EV_charger/icons-crowdenergy-267.svg'
import { ReactComponent as TeslaSuperchargerUSSelected } from '../icons/plug-types-EV_charger/icons-crowdenergy-273.svg'
import { ReactComponent as CCSCombo1US } from '../icons/plug-types-EV_charger/icons-crowdenergy-335.svg'
import { ReactComponent as CCSCombo1USSelected } from '../icons/plug-types-EV_charger/icons-crowdenergy-336.svg'
import { ReactComponent as CCSCombo2EU } from '../icons/plug-types-EV_charger/icons-crowdenergy-252.svg'
import { ReactComponent as CHAdeMO } from '../icons/plug-types-EV_charger/icons-crowdenergy-265.svg'
import { ReactComponent as CHAdeMOSelected } from '../icons/plug-types-EV_charger/icons-crowdenergy-271.svg'
import { ReactComponent as CCSCombo2EUSelected } from '../icons/plug-types-EV_charger/icons-crowdenergy-269.svg'

export const connectorOutletEVChargerIcons = {
  [ConnectorOutletEVCharger.MennekesType2]: {
    deselected: MennekesType2,
    selected: MennekesType2Selected,
  },
  [ConnectorOutletEVCharger.J1772Type1]: {
    deselected: J1772Type1,
    selected: J1772Type1Selected,
  },
  [ConnectorOutletEVCharger.TeslaSuperchargerV2EU]: {
    deselected: TeslaSuperchargerV2EU,
    selected: TeslaSuperchargerV2EUSelected,
  },
  [ConnectorOutletEVCharger.TeslaSuperchargerV3EU]: {
    deselected: TeslaSuperchargerV3EU,
    selected: TeslaSuperchargerV3EUSelected,
  },
  [ConnectorOutletEVCharger.TeslaSuperchargerUS]: {
    deselected: TeslaSuperchargerUS,
    selected: TeslaSuperchargerUSSelected,
  },
  [ConnectorOutletEVCharger.CCSCombo1US]: {
    deselected: CCSCombo1US,
    selected: CCSCombo1USSelected,
  },
  [ConnectorOutletEVCharger.CCSCombo2EU]: {
    deselected: CCSCombo2EU,
    selected: CCSCombo2EUSelected,
  },
  [ConnectorOutletEVCharger.CHAdeMO]: {
    deselected: CHAdeMO,
    selected: CHAdeMOSelected,
  },
}

export const connectorOutletEVChargerData: ConnectorOutletData = {
  [ConnectorOutletEVCharger.MennekesType2]: {
    label: ConnectorOutletEVCharger.MennekesType2,
    displayName: 'Mennekes Type 2',
    iconDeselected: connectorOutletEVChargerIcons[ConnectorOutletEVCharger.MennekesType2].deselected,
    iconSelected: connectorOutletEVChargerIcons[ConnectorOutletEVCharger.MennekesType2].selected,
  },
  [ConnectorOutletEVCharger.J1772Type1]: {
    label: ConnectorOutletEVCharger.J1772Type1,
    displayName: 'J-1772 Type 1',
    iconDeselected: connectorOutletEVChargerIcons[ConnectorOutletEVCharger.J1772Type1].deselected,
    iconSelected: connectorOutletEVChargerIcons[ConnectorOutletEVCharger.J1772Type1].selected,
  },
  [ConnectorOutletEVCharger.TeslaSuperchargerV2EU]: {
    label: ConnectorOutletEVCharger.TeslaSuperchargerV2EU,
    displayName: 'Tesla Supercharger V2 EU',
    iconDeselected: connectorOutletEVChargerIcons[ConnectorOutletEVCharger.TeslaSuperchargerV2EU].deselected,
    iconSelected: connectorOutletEVChargerIcons[ConnectorOutletEVCharger.TeslaSuperchargerV2EU].selected,
  },
  [ConnectorOutletEVCharger.TeslaSuperchargerV3EU]: {
    label: ConnectorOutletEVCharger.TeslaSuperchargerV3EU,
    displayName: 'Tesla Supercharger V3 EU',
    iconDeselected: connectorOutletEVChargerIcons[ConnectorOutletEVCharger.TeslaSuperchargerV3EU].deselected,
    iconSelected: connectorOutletEVChargerIcons[ConnectorOutletEVCharger.TeslaSuperchargerV3EU].selected,
  },
  [ConnectorOutletEVCharger.TeslaSuperchargerUS]: {
    label: ConnectorOutletEVCharger.TeslaSuperchargerUS,
    displayName: 'Tesla Supercharger US',
    iconDeselected: connectorOutletEVChargerIcons[ConnectorOutletEVCharger.TeslaSuperchargerUS].deselected,
    iconSelected: connectorOutletEVChargerIcons[ConnectorOutletEVCharger.TeslaSuperchargerUS].selected,
  },
  [ConnectorOutletEVCharger.CCSCombo1US]: {
    label: ConnectorOutletEVCharger.CCSCombo1US,
    displayName: 'CCS Combo1 US',
    iconDeselected: connectorOutletEVChargerIcons[ConnectorOutletEVCharger.CCSCombo1US].deselected,
    iconSelected: connectorOutletEVChargerIcons[ConnectorOutletEVCharger.CCSCombo1US].selected,
  },
  [ConnectorOutletEVCharger.CCSCombo2EU]: {
    label: ConnectorOutletEVCharger.CCSCombo2EU,
    displayName: 'CCS Combo2 EU',
    iconDeselected: connectorOutletEVChargerIcons[ConnectorOutletEVCharger.CCSCombo2EU].deselected,
    iconSelected: connectorOutletEVChargerIcons[ConnectorOutletEVCharger.CCSCombo2EU].selected,
  },
  [ConnectorOutletEVCharger.CHAdeMO]: {
    label: ConnectorOutletEVCharger.CHAdeMO,
    displayName: 'CHAdeMO',
    iconDeselected: connectorOutletEVChargerIcons[ConnectorOutletEVCharger.CHAdeMO].deselected,
    iconSelected: connectorOutletEVChargerIcons[ConnectorOutletEVCharger.CHAdeMO].selected,
  },
}
