import { getArrayOfValuesFromDictionary, isItemSelected } from '../../../utils/common'
import { MenuSelectableIcon } from '../../MenuItems/MenuSelectableIcon'
import { addServiceConstants } from '../../../data/constants'
import { MenuSelectable } from '../../MenuItems/MenuSelectable'
import React from 'react'
import { MenuSelectableConfig, MenuSelectableIconConfig } from '../../../types'

export function ConnectorsSelectable({
  connectors,
  selectedConnector,
  setSelectedConnector,
  config,
  itemsConfig,
}: {
  connectors: any
  selectedConnector?: string
  setSelectedConnector: Function
  config?: MenuSelectableConfig
  itemsConfig?: MenuSelectableIconConfig
}) {
  const isConnectorSelected = (connector: string): boolean => isItemSelected(selectedConnector, connector)
  return (
    <MenuSelectable config={config}>
      {connectors !== undefined &&
        getArrayOfValuesFromDictionary(connectors).map((connector: any) => {
          return (
            <MenuSelectableIcon
              label={connector.label}
              displayName={connector.displayName}
              iconDeselected={connector.iconDeselected}
              iconSelected={connector.iconSelected}
              isSelected={() => isConnectorSelected(connector.label)}
              onClick={() => setSelectedConnector(connector.label)}
              config={{
                ...itemsConfig,
                hasFixedWidth:
                  getArrayOfValuesFromDictionary(connectors).length > addServiceConstants.maxConnectorsInPanel,
              }}
            />
          )
        })}
    </MenuSelectable>
  )
}
