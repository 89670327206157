import React from 'react'
import { MenuSelectable } from '../../MenuItems/MenuSelectable'
import { MenuSelectableIcon } from '../../MenuItems/MenuSelectableIcon'
import { getServiceTypeData } from '../../../utils/service'
import { MenuSelectableConfig, MenuSelectableIconConfig } from '../../../types'
import { getArrayOfValuesFromDictionary, isItemSelected } from '../../../utils/common'
import { ServiceType } from '../../../graphQLTypes'

export function ServiceTypeSelectable({
  selectedServiceType,
  handleSetSelectedServiceType,
  config,
  iconsConfig,
}: {
  selectedServiceType?: ServiceType
  handleSetSelectedServiceType: Function
  config?: MenuSelectableConfig
  iconsConfig?: MenuSelectableIconConfig
}) {
  const serviceTypeData = getServiceTypeData()

  const isServiceTypeSelected = (serviceType?: ServiceType): boolean => isItemSelected(selectedServiceType, serviceType)

  return (
    <MenuSelectable config={config}>
      {getArrayOfValuesFromDictionary(serviceTypeData).map((serviceType) => {
        return (
          <MenuSelectableIcon
            key={serviceType?.label}
            label={serviceType?.label}
            displayName={serviceType?.displayName}
            iconSelected={serviceType?.iconSelected}
            iconDeselected={serviceType?.iconDeselected!}
            isSelected={isServiceTypeSelected}
            config={iconsConfig}
            onClick={() => handleSetSelectedServiceType(serviceType?.label)}
          />
        )
      })}
    </MenuSelectable>
  )
}
