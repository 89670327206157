import { SocialMedia, SocialMediaData } from '../types'
import { ReactComponent as TwitterIcon } from '../icons/deselected/icons-crowdenergy-146.svg'
import { ReactComponent as FacebookIcon } from '../icons/deselected/icons-crowdenergy-149.svg'
import { ReactComponent as MessengerIcon } from '../icons/deselected/icons-crowdenergy-344.svg'

export const socialMediaIcons = {
  [SocialMedia.Twitter]: {
    deselected: TwitterIcon,
  },
  [SocialMedia.Facebook]: {
    deselected: FacebookIcon,
  },
  [SocialMedia.Messenger]: {
    deselected: MessengerIcon,
  },
}

export const socialMediaData: SocialMediaData = {
  [SocialMedia.Twitter]: {
    label: SocialMedia.Twitter,
    iconDeselected: socialMediaIcons[SocialMedia.Twitter].deselected,
  },
  [SocialMedia.Facebook]: {
    label: SocialMedia.Facebook,
    iconDeselected: socialMediaIcons[SocialMedia.Facebook].deselected,
  },
  [SocialMedia.Messenger]: {
    label: SocialMedia.Messenger,
    iconDeselected: socialMediaIcons[SocialMedia.Messenger].deselected,
  },
}
